import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/app/environments/environment';
import { ChartDetails, ExcelDataModel, fomData } from '../models/chatbotexpand.model';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  getChatBotInsightsData(
    insightsData: any, selectedstudy: string, selectedclient: string, isTotalBase: string  ): Observable<string> {
        const formData = new FormData();
        formData.append('data', JSON.stringify(insightsData));
        formData.append('study', selectedstudy);
        formData.append('client', selectedclient);
        formData.append('isTotalBase', isTotalBase);
        
        return this.http.post(
          `${this.apiBaseUrl}api/ChatBot/getChatBotGptRepsonse`,
          formData,
          { responseType: 'text' }
        );
      }
  apiBaseUrl = environment.baseApiUrl;
  constructor(private http:HttpClient) { }
  private apiUrl="https://localhost:44376/api/ChatBot/GetQuery";
  private variableTabDataSubject = new BehaviorSubject<any>('');
  variableTabData$ = this.variableTabDataSubject.asObservable();
  
  exportToPPT(data: ChartDetails): Observable<Blob> {
    const exportUrl = `${this.apiBaseUrl}api/ChatBot/pptARKExport`;
    return this.http.post(exportUrl, data, { responseType: 'blob' });
  }
  getDiagonistics1(request: any): Observable<any> {
    const url = this.apiBaseUrl + 'api/ChatBot/GetDiagonistics';
    return (this.http
      .post<any>(
        `${this.apiBaseUrl}api/ChatBot/GetDiagonistics`,
        request,
      )
    );
  }
  exportToExcelARK(data: ExcelDataModel): Observable<Blob> {
    const exportUrl = `${this.apiBaseUrl}api/ChatBot/excelExportARK`;
    return this.http.post(exportUrl, data, { responseType: 'blob' }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        return throwError(error);
      })
    );
  }
  getOutputData(
    inputQuery: string,
    selectedclient: string,
    selectedstudy: string,
    aggregatedVariables: any = []
  ): Observable<any> {
    const url = this.apiBaseUrl + 'api/ChatBot/GetQuery';

    // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
    const body = {
      input_Query: inputQuery,
      selectedclient: selectedclient,
      selectedstudy: selectedstudy,
      aggregatedVariables: aggregatedVariables,
    };

  return this.http.post<any>(url, body).pipe(
    catchError((error: HttpErrorResponse) => {
      console.error('Request error:', error);
      return throwError(error);
    })
  );
}
getDiagonistics(): Observable<any> {
  debugger
  const url = this.apiBaseUrl + 'api/ChatBot/GetDiagonistics';

  // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
  const body = {
    request: "inputQuery"
  };

  return this.http.post<any>(url, body).pipe(
    catchError((error: HttpErrorResponse) => {
      console.error('Request error:', error);
      return throwError(error);
    })
  );
}

}
