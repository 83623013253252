
<div class="table-preview">
  <div class="preview-table-container" [ngStyle]="{'--custom-margin': getMarginForScroll()}">
    <div class="rows-left-header-block">
      <div class="empty-header" *ngIf="data['Row'].length>0 || data['Column'].length>0">
        <div class="empty-row-block"></div>
        <ng-container *ngIf="data['Column']!=undefined && data['Column'].length > 0 && data['Column'][0]['Data'][0]['NestedArray'].length>0">
          <div class="empty-col-nesting"></div>
          <ng-container *ngIf="data['Column']!=undefined && data['Column'].length > 0 && data['Column'][0]['Data'][0]['NestedArray'].length>0">
            <ng-container *ngIf="data['Column']!=undefined && data['Column'].length > 0 && data['Column'][0]['Data'][0]['NestedArray'].length>0 && data['Column'][0]['Data'][0]['NestedArray'][0]['NestedArray'].length>0">
              <div class="empty-col-nesting"></div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="table-rows-header-block">
        <ng-container *ngIf="data['Row']!=undefined">
          <!-- <div class="preview-rows" *ngFor="let row of data.Row"> -->
          <div [ngClass]="(isarkTabAccessed=='true')?'preview-rows-ark':'preview-rows'" *ngFor="let row of data.Row">
            <div class="preview-row-header" *ngFor="let row_item of row.Data">
              <div class="preview-row-header-text" [style.height.px]="getWidthofCellOnCount_Column('Row','Row',row_item)" title="{{getHeader(row_item.Child)}}"><div class="row-col-header-title">{{getHeader(row_item.Child)}}</div></div>
              <ng-container *ngIf="data && data['Row']&& data['Row'][0].Data[0].NestedArray && data['Row'][0].Data[0].NestedArray.length > 0">
                <div class="row-nesting-block">

                  <div class="row-nest-header-block">
                    <div class="row-nest-header" [style.height.px]="getWidthofCellOnCount_Row('Row','Row',rownesting)" *ngFor="let rownesting of row_item.NestedArray;let ind=index"
                    [ngClass]="{'total-right-border':getHeader(rownesting.Child)=='Total' && data['Row'][0].Data[0].NestedArray[0].NestedArray.length>0}"
                    >
                      <div class="row-nest-header-txt" title="{{getHeader(rownesting.Child)}}"
                      [ngClass]="{'total-right-align':getHeader(rownesting.Child)=='Total' && data['Row'][0].Data[0].NestedArray[0].NestedArray.length>0}"
                      >{{getHeader(rownesting.Child)}}</div>
                    </div>
                    <!-- <div class="row-nest-header" [style.height.px]="getWidthofCellOnCount_Row('Row','Row',rownesting)" *ngFor="let rownesting of row_item.NestedArray;let ind=index">
                      <div class="row-nest-header-txt" title="{{getHeader(rownesting.Child)}}">{{getHeader(rownesting.Child)}}</div>
                    </div> -->
                  </div>
                </div>
                <ng-container *ngIf="data && data['Row'] && data['Row'][0] && data['Row'][0].Data && data['Row'][0].Data[0]">
                  <ng-container *ngIf="data['Row'][0].Data[0].NestedArray && data['Row'][0].Data[0].NestedArray.length > 0 && data['Row'][0].Data[0].NestedArray[0].NestedArray.length>0">
                    <div class="row-nesting-block">
                      <div class="row-nest-header-block">
                        <ng-container *ngFor="let rownestinglv2 of row_item.NestedArray;let ind=index">
                          <div class="row-nest-header" *ngFor="let rownesting of rownestinglv2.NestedArray;let ind=index">
                            <div class="row-nest-header-txt" title="{{getHeader(rownesting.Child)}}">{{getHeader(rownesting.Child)}}</div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- <div class="column-body"> -->
    <div [ngClass]="(isarkTabAccessed=='true')?'column-body-ark':'column-body'">
      <div class="preview-columns-header">
        <ng-container *ngIf="data['Column']!=undefined">
          <div class="preview-row-header" *ngFor="let col of data.Column">
            <div class="row-block" *ngFor="let col_item of col.Data;let j=index">
              <div class="row-header-text" [style.width.px]="getWidthofCellOnCount_Column('Column','Column',col_item)">
                <ng-container *ngIf="!isPairWise; else elseBlock">
                  <div class="row-header-title" title="{{getHeader(col_item.Child)}}"> {{getHeader(col_item.Child)}}</div>
                </ng-container>
                <ng-template #elseBlock>
                  <div class="row-header-title" title="{{getHeader(col_item.Child)}}"> [{{isPairWise?pairWiseVariables[j]:""}}] {{getHeader(col_item.Child)}} </div>
                </ng-template>
              </div>
              <ng-container *ngIf="data && data['Column']&& data['Column'][0].Data[0].NestedArray && data['Column'][0].Data[0].NestedArray.length > 0">
                <div class="col-nesting-block">
                  <div class="col-nest-header-block">
                    <div class="col-nest-header" [style.width.px]="getWidthofCellOnCount_Row('Column','Column',nesting)" *ngFor="let nesting of col_item.NestedArray">
                      <div class="col-nest-header-txt" title="{{getHeader(nesting.Child)}}"> {{getHeader(nesting.Child)}}</div>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="data && data['Column'] && data['Column'][0] && data['Column'][0].Data && data['Column'][0].Data[0]">
                  <ng-container *ngIf="data['Column'][0].Data[0].NestedArray && data['Column'][0].Data[0].NestedArray.length > 0 && data['Column'][0].Data[0].NestedArray[0].NestedArray.length>0">

                    <div class="col-nesting-block">
                      <div class="col-nest-header-block" *ngFor="let colFirstLevel of col_item.NestedArray">
                        <div class="col-nest-header" *ngFor="let colsecondlevelnest of colFirstLevel.NestedArray">
                          <div class="col-nest-header-txt" title="{{getHeader(colsecondlevelnest.Child)}}"> {{getHeader(colsecondlevelnest.Child)}}</div>
                        </div>
                      </div>
                    </div>

                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="preview-table-body">

        <!--when only Row & Column is selected-->
        <ng-container *ngIf="data['Row'].length > 0 && data['Row'][0]['Data'][0]['NestedArray'].length==0 && data['Column'].length > 0 && data['Column'][0]['Data'][0]['NestedArray'].length==0">
          <ng-container *ngFor="let row of data.Row">
            <div class="row-data-container" *ngFor="let rowItem of row.Data">
              <ng-container *ngFor="let col of data.Column">
                <div class="row-cell-block" *ngFor="let colItem of col.Data">
                  <div class="cell-data" [innerHTML]="getPercentage(rowItem, colItem)"></div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>



        <!--When RowNesting is Selected, ColNesting is Empty, RowNesting Level 1 is selected and RowNesting Level 2 is not selected -->
        <ng-container *ngIf="data['Row'].length > 0  && data['Row'][0]['Data'][0]['NestedArray'].length >0 && data['Row'][0]['Data'][0]['NestedArray'][0].NestedArray.length==0 && data['Column'].length > 0 && data['Column'][0]['Data'][0]['NestedArray'].length==0">
          <ng-container *ngFor="let row of data.Row">
            <ng-container *ngFor="let rowItem of row.Data">
              <div class="row-data-container" *ngFor="let nestedData of rowItem.NestedArray">
                <ng-container *ngFor="let colData of data.Column">
                  <div class="row-cell-block" *ngFor="let colItem of colData.Data">
                    <div class="cell-data" [innerHTML]="getPercentage_RowNest(rowItem,nestedData,'',colItem)"></div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <!--When RowNesting is Selected, ColNesting is Empty, RowNesting Level 1 is selected and RowNesting Level 2 is selected -->
        <ng-container *ngIf="data['Row'].length>0 && data['Row'][0]['Data'][0]['NestedArray'].length>0 &&  data['Row'][0]['Data'][0]['NestedArray'][0].NestedArray.length>0 && data['Column']!=undefined && data['Column'].length > 0 && data['Column'][0]['Data'][0]['NestedArray'].length==0">
          <ng-container *ngFor="let row of data.Row">
            <ng-container *ngFor="let rowItem of row.Data">
              <ng-container *ngFor="let rowNesting of rowItem.NestedArray">
                <div class="row-data-container" *ngFor="let rowNestedItem of rowNesting.NestedArray">
                  <ng-container *ngFor="let col of data.Column">
                    <div class="row-cell-block" *ngFor="let colItem of col.Data">
                      <div class="cell-data" [innerHTML]="getPercentage_RowNest(rowItem,rowNesting,rowNestedItem,colItem)"></div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>

        <!--RowNesting is Empty && ColumnNesting Level1 is Selected and Level2 is empty-->
        <ng-container *ngIf="data['Column']!=undefined && data['Column'].length>0 &&  data['Column'][0]['Data'][0]['NestedArray'].length >0 &&  data['Column'][0]['Data'][0]['NestedArray'][0].NestedArray.length==0 && data['Row']!=undefined && data['Row'].length > 0 && data['Row'][0]['Data'][0]['NestedArray'].length==0">
          <ng-container *ngFor="let row of data.Row">
            <div class="row-data-container" *ngFor="let rowItem of row.Data">
              <ng-container *ngFor="let col of data.Column">
                <ng-container *ngFor="let colItem of col.Data">
                  <div class="row-cell-block" *ngFor="let nestedData of colItem.NestedArray">
                    <div class="cell-data" [innerHTML]="getPercentage_colNest(rowItem, colItem,nestedData,'')"></div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>

        <!--RowNesting is Empty && ColumnNesting Level1 & Level2 both Selected-->
        <ng-container *ngIf="data['Column'].length>0 &&  data['Column'][0]['Data'][0]['NestedArray'].length>0 && data['Column'][0]['Data'][0]['NestedArray'][0].NestedArray.length>0 && data['Row']!=undefined && data['Row'].length > 0 && data['Row'][0]['Data'][0]['NestedArray'].length==0">
          <ng-container *ngFor="let row of data.Row">
            <div class="row-data-container" *ngFor="let rowItem of row.Data">
              <ng-container *ngFor="let col of data.Column">
                <ng-container *ngFor="let colItem of col.Data">
                  <ng-container *ngFor="let lvl1 of colItem.NestedArray">
                    <div class="row-cell-block" *ngFor="let lvl2 of lvl1.NestedArray">
                      <div class="cell-data" [innerHTML]="getPercentage_colNest(rowItem, colItem, lvl1,lvl2)"></div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>




        <!--When RowNesting & ColumnNesting is Selected & RowNesting is Selected-->
        <ng-container *ngIf="data['Row'].length>0 && data['Row'][0]['Data'][0]['NestedArray'].length > 0 && data['Row'][0]['Data'][0]['NestedArray'][0]['NestedArray'].length==0 && data['Column'].length>0 && data['Column'][0]['Data'][0]['NestedArray'].length > 0 && data['Column'][0]['Data'][0]['NestedArray'][0]['NestedArray'].length==0 ">
          <ng-container *ngFor="let row of data.Row">
            <ng-container *ngFor="let rowData of row.Data">
              <div class="row-data-container" *ngFor="let rowNest of rowData.NestedArray">
                <ng-container *ngFor="let col of data.Column">
                  <ng-container *ngFor="let columnData of col.Data">
                    <div class="row-cell-block" *ngFor="let colNestedData of columnData.NestedArray">
                      <div class="cell-data" [innerHTML]="getPercentage_multiLevel(rowData,rowNest,columnData,colNestedData)"></div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>


        <ng-container *ngIf="data['Row'].length>0 && data['Row'][0]['Data'][0]['NestedArray'].length > 0 && data['Row'][0]['Data'][0]['NestedArray'][0]['NestedArray'].length==0 && data['Column'].length>0 && data['Column'][0]['Data'][0]['NestedArray'].length > 0 && data['Column'][0]['Data'][0]['NestedArray'][0]['NestedArray'].length==0">
          <ng-container *ngFor="let row of data.Row">
            <ng-container *ngFor="let rowData of row.Data">
              <div class="row-data-container" *ngFor="let rowNest of rowData.NestedArray">
                <ng-container *ngFor="let col of data.Column">
                  <ng-container *ngFor="let columnData of col.Data">
                    <ng-container *ngFor="let colNestedData of columnData.NestedArray">
                      <div class="row-cell-block" *ngFor="let nestedData of colNestedData.NestedArray">
                        <div class="cell-data" [innerHTML]="getPercentage_multiLevel(rowData,rowNest,columnData,colNestedData)"></div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>



        <ng-container *ngIf="data['Row'].length>0 && data['Row'][0]['Data'][0]['NestedArray'].length > 0 && data['Row'][0]['Data'][0]['NestedArray'][0]['NestedArray'].length > 0 && data['Column'].length > 0 && data['Column'][0]['Data'][0]['NestedArray'].length > 0 && data['Column'][0]['Data'][0]['NestedArray'][0]['NestedArray'].length==0">
          <ng-container *ngFor="let row of data.Row">
            <ng-container *ngFor="let rowData of row.Data">
              <ng-container *ngFor="let rowNest of rowData.NestedArray">
                <div class="row-data-container" *ngFor="let rowNestData of rowNest.NestedArray">
                  <ng-container *ngFor="let col of data.Column">
                    <ng-container *ngFor="let columnData of col.Data">
                      <div class="row-cell-block" *ngFor="let colNestedData of columnData.NestedArray">
                        <div class="cell-data" [innerHTML]="getPercentage_multiLevel_Row_Level1(rowData,rowNest,rowNestData,columnData,colNestedData)"></div>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>


        <!--multi level-->
        <ng-container *ngIf="data['Row'].length>0 && data['Row'][0]['Data'][0]['NestedArray'].length > 0 && data['Row'][0]['Data'][0]['NestedArray'][0]['NestedArray'].length>0 && data['Column'].length>0 && data['Column'][0]['Data'][0]['NestedArray'].length > 0 && data['Column'][0]['Data'][0]['NestedArray'][0]['NestedArray'].length > 0">
          <ng-container *ngFor="let row of data.Row">
            <ng-container *ngFor="let rowData of row.Data">
              <ng-container *ngFor="let rowNest of rowData.NestedArray">
                <div class="row-data-container" *ngFor="let rowNestData of rowNest.NestedArray">
                  <ng-container *ngFor="let column of data.Column">
                    <ng-container *ngFor="let colData of column.Data">
                      <ng-container *ngFor="let colDataVal of colData.NestedArray">
                        <div class="row-cell-block" *ngFor="let nestedColData of colDataVal.NestedArray">
                          <div class="cell-data" [innerHTML]="getPercentage_multiLevel_level2(rowData,rowNest,rowNestData,colData,colDataVal,nestedColData)"></div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>

              </ng-container>

            </ng-container>
          </ng-container>
        </ng-container>



        <ng-container *ngIf="data['Row'].length>0 && data['Row'][0]['Data'][0]['NestedArray'].length > 0 && data['Row'][0]['Data'][0]['NestedArray'][0]['NestedArray'].length==0 && data['Column'].length>0 && data['Column'][0]['Data'][0]['NestedArray'].length > 0 && data['Column'][0]['Data'][0]['NestedArray'][0]['NestedArray'].length > 0">
          <ng-container *ngFor="let row of data.Row">
            <ng-container *ngFor="let rowData of row.Data">
                <div class="row-data-container" *ngFor="let rowNest of rowData.NestedArray">
                  <ng-container *ngFor="let column of data.Column">
                    <ng-container *ngFor="let colData of column.Data">
                      <ng-container *ngFor="let colDataVal of colData.NestedArray">
                        <div class="row-cell-block" *ngFor="let nestedColData of colDataVal.NestedArray">
                          <div class="cell-data" [innerHTML]="getPercentage_multiLevel_rowLevel1_columnLevel2(rowData,rowNest,colData,colDataVal,nestedColData)"></div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
            </ng-container>
          </ng-container>
        </ng-container>

      </div>

    </div>
  </div>
</div>

