<section>
    <div class="mainLayout" [ngClass]="{ hidden: isCreateBtnClick }">
      <div class="left-panel-content" [ngClass]="{ hidden: left_panel_collapse }">
        <app-leftpanel [ReceivedVarData]="variableEntryNullData"
                       (BindImageURL)="brandLogo($event)"
                       [selected_study]="selected_study"
                       [selected_client]="selected_client"
                       (valueChange)="handleValueChange($event)"
                       (sendHelpGuide)="HelpGuideFromLeftPanel($event)"
                       (landingdata)="(landingdata)"
                       (entryListChange)="onEntryListChange($event)"
                       (dimensionListChange)="onDimensionListChange($event)"
                       (dataListChange)="onEntryListChange_DataArray($event)"
                       (btnVisible)="handleBtnChange($event)"
                       (isClearEverything)="clearAllSelection()"
                       (selectedVariableLevel1)="handleDragDrop($event)"
                       (selectedConfig)="handleConfig($event)"
                       (selectedEntry)="handleEntryPoint($event)"
                       (LeftPanelClick)="handleLeftpanel($event)"
                       [isSavedScenarioSelected]="isSavedScenarioSelected"
                       [hidePanel]="hidePanel"
                       [disableVariables]="disabledCustomCalculationOperatorList.includes('customGroup')"
                       [isbackButton]="isbackButton"
                       [isCreateCustomGroup]="isShareMetric"
                       [isCustomGroupClick]="isCustomGroupClick"
                       [isCreateCustomCalculation]="isMeanMetric"
                       [isCustomCalculationClick]="isCustomCalculationClick"
                       [dataArray]="dataArray"
                       [isChangeEntryPoint]="isChangeEntryPoint"
                       (cancelEntryPointChange)="CancelEntryPointChange($event)"
                       [moduleName]="moduleName"></app-leftpanel>
      </div>
      <div class="left-collapse-block" *ngIf="left_panel_collapse">
        <div class="btn-expand-icon" (click)="expandPanel()">
          <div class="toggle-icon expand-icon"></div>
        </div>
      </div>
  
      <div class="mainComponent createCustomGroupComponent"
           [ngClass]="{ 'main-block-width': left_panel_collapse }"
           *ngIf="!displayMainComponent && isCreateCustomGroup && isShareMetric">
        <div class="customCalulations">
          <div class="CustomClassBlock">
            <div class="customText">PERCENTAGE VARIABLE</div>
            <div class="Back" (click)="backButtonClick()">
              <div class="Back-btn-icon BackIcon"></div>
              <div class="Back-Text">Back</div>
            </div>
          </div>
  
          <div class="customInfo">
            <div class="infoIcon InfoImage"></div>
            <div class="customInfoText">
              Drag & Drop <b>&nbsp;Variables&nbsp;</b> in Metrics to create Percentage Variable
            </div>
          </div>
        </div>
  
        <div class="childContent"
             [ngClass]="{ 'preview-expand-size': isPreviewExpand }">
          <div class="RowContent" [ngClass]="{ hidden: isPreviewExpand }">
            <div class="RowMainContent">
              <div class="rowHeader">
                <div class="rowText">VARIABLES</div>
                <div class="rowDelete" (click)="clearSelection('CustomGroup')">
                  <div class="deleteIcon"></div>
                </div>
              </div>
              <div class="row-body-block">
                <div class="rowParent" [style.height.%]="updateHeight()">
                  <div class="rowParentContent">
                    <div class="row-parent-metrics-item"
                         cdkDropList
                         id="customVariable"
                         cdkDropListAutoScroll
                         [cdkDropListData]="listCustomGroupItemsList"
                         [cdkDropListConnectedTo]="drag_drop_container"
                         (cdkDropListDropped)="
                        onDrop('listCustomGroupItemsList', $event)
                      ">
                      <div *ngFor="
                          let item of listCustomGroupItemsList;
                          let ind = index
                        "
                           cdkDrag
                           class="row-metric-item-container RowPanel"
                           (click)="
                          item.has_nextLevel &&
                            plotNextLevel(item, 'CustomGroup', ind)
                        "
                           [ngClass]="{
                          'side-panel-items-active-no-next-lvl':
                            !item.has_nextLevel,
                          'side-panel-items-active':
                            item.has_nextLevel &&
                            droppedItem == item.Level1 + '|Row'
                        }">
                        <div class="row-metric-item-name"
                             [ngStyle]="{
                            width: item.has_nextLevel == false ? '86%' : '76%'
                          }"
                             [attr.data-attr]="item.Level0"
                             title="{{ item.tooltip }}">
                          {{ item.Level1 }}
                        </div>
                        <div class="row-mertic-item-dropdown-icon"
                             *ngIf="item.has_nextLevel">
                          <div class="arrowToggle metric-list-open"></div>
                        </div>
                        <div class="row-metric-item-remove-icon"
                             (click)="
                            removeItem(
                              'listCustomGroupItemsList',
                              item,
                              'CustomGroup'
                            )
                          "
                             title="REMOVE">
                          <div class="arrowToggle close-metric-icon"></div>
                        </div>
                      </div>
                    </div>
                    <div class="side-panel-block RowPanel"
                         *ngIf="sidePanelDisplay">
                      <div class="side-panel-level-1"
                           *ngIf="sidePanelLevel1Display">
                        <div class="side-panel-search">
                          <input type="text"
                                 placeholder="Search"
                                 [(ngModel)]="searchTextLevel1"
                                 autocomplete="off"
                                 name="searchTextLevel1"
                                 (input)="onSearchLevel('level1')" />
                          <div class="search-icon">
                            <div class="search-icon-image"></div>
                          </div>
                        </div>
                        <div class="side-panel-main-container"
                             *ngFor="let item of responseData1; let ind = index">
                          <div *ngIf="item.isCheckbox == true">
                            <div class="side-panel-items"
                                 title="Select All"
                                 (click)="
                                selectAll(
                                  'Level1',
                                  'CustomGroup',
                                  false,
                                  item.customGroupRowOrder
                                )
                              "
                                 [ngClass]="{
                                'side-panel-items-active': isSelectAll
                              }"
                                 *ngIf="searchTextLevel1.trim() === ''">
                              <div class="checkbox-icon"
                                   [ngClass]="{
                                  'checkbox-icon-active': isSelectAll
                                }"></div>
                              <div class="side-panel-item-name">Select All</div>
                            </div>
                            <div *ngFor="let itemList of item.Level2"
                                 [ngClass]="{
                                hidden:
                                  !itemList.MetricOption.toLowerCase().includes(
                                    searchTextLevel1.toLowerCase()
                                  )
                              }">
                              <div class="side-panel-items"
                                   #checkboxIconsLevel1
                                   title="{{ itemList.tooltip }}"
                                   [ngClass]="{
                                  'side-panel-items-active': isSelectAll
                                }"
                                   (click)="
                                  checkboxClick(
                                    'CustomGroup',
                                    false,
                                    item.Level0,
                                    item.Level1 + '|' + itemList.MetricOption,
                                    'false',
                                    item.Level1,
                                    false,
                                    itemList.data_variable_code,
                                    itemList.response_code,
                                    itemList.isDerviedVariable,
                                    itemList.calculation_type,
                                    itemList.sort_id,
                                    itemList.isMultiPunch,
                                    itemList.year_code,
                                    itemList.date,
                                    ind,
                                    itemList.isMultiPunch ? item.Level1 : '',
                                    itemList.is_dimension,
                                    itemList.isCustomGroup,
                                    itemList.kibanaFilterScript,
                                    itemList.customGroupId,
                                    itemList.dataYearCode,
                                    itemList.MultiPunchNumber,
                                    itemList.isCustomCalculation,
                                    itemList.customCalculationId,
                                    item.customCalculationRowOrder,
                                    item.customGroupRowOrder
                                  )
                                ">
                                <div class="checkbox-icon"
                                     [ngClass]="{
                                    'checkbox-icon-active': getCheckboxStatusNew(
                                      item.Level0,
                                      item.Level1 + '|' + itemList.MetricOption,
                                      'CustomGroup',
                                      item.Level1,
                                      item.customCalculationRowOrder,
                                      item.customGroupRowOrder
                                    )
                                  }"></div>
                                <div class="side-panel-item-name">
                                  {{ itemList.MetricOption }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="item.isCheckbox == false">
                            <div class="side-panel-main"
                                 *ngFor="let itemList of item.Level2"
                                 [ngClass]="{
                                hidden:
                                  !itemList.MetricOption.toLowerCase().includes(
                                    searchTextLevel1.toLowerCase()
                                  )
                              }"
                                 (click)="
                                plotNextLevel2(
                                  'CustomGroup',
                                  item.Level0,
                                  item.Level1,
                                  itemList.MetricOption,
                                  ind,
                                  item.is_entryPoint,
                                  item.customGroupRowOrder
                                )
                              ">
                              <div class="side-panel-items"
                                   title="{{ itemList.tooltip }}"
                                   [attr.data-attr]="item"
                                   [ngClass]="{
                                  'side-panel-items-active':
                                    selectedLevel1Item === itemList.MetricOption,
                                  'highlight-selected':
                                    selectedLevel1Item !==
                                      itemList.MetricOption &&
                                    getHighlightStatusNew(
                                      item.Level0,
                                      item.Level1 + '|' + itemList.MetricOption,
                                      'CustomGroup',
                                      item.Level1,
                                      item.customGroupRowOrder
                                    )
                                }">
                                <div class="side-panel-item-name">
                                  {{ itemList.MetricOption }}
                                </div>
                                <div class="side-panel-arrow">
                                  <div class="arrowToggle metric-list-open"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="side-panel-level-2"
                           *ngIf="sidePanelLevel2Display">
                        <div class="side-panel-search">
                          <input type="text"
                                 placeholder="Search"
                                 [(ngModel)]="searchTextLevel2"
                                 autocomplete="off"
                                 name="searchTextLevel2"
                                 (input)="onSearchLevel('level2')" />
                          <div class="search-icon">
                            <div class="search-icon-image"></div>
                          </div>
                        </div>
                        <div class="side-panel-main-container"
                             *ngFor="let item of responseData2; let ind = index">
                          <div *ngIf="item.isCheckbox == true">
                            <div class="side-panel-items"
                                 title="Select All"
                                 (click)="
                                selectAll(
                                  'Level2',
                                  'CustomGroup',
                                  false,
                                  item.customGroupRowOrder
                                )
                              "
                                 [ngClass]="{
                                'side-panel-items-active': isSelectAll
                              }"
                                 *ngIf="searchTextLevel2.trim() === ''">
                              <div class="checkbox-icon"
                                   [ngClass]="{
                                  'checkbox-icon-active': isSelectAll
                                }"></div>
                              <div class="side-panel-item-name">Select All</div>
                            </div>
                            <div *ngFor="let itemList of item.Level3"
                                 [ngClass]="{
                                hidden:
                                  !itemList.MetricOption.toLowerCase().includes(
                                    searchTextLevel2.toLowerCase()
                                  )
                              }">
                              <div class="side-panel-items"
                                   title="{{ itemList.tooltip }}"
                                   [ngClass]="{
                                  'side-panel-items-active': isSelectAll
                                }"
                                   (click)="
                                  checkboxClick(
                                    'CustomGroup',
                                    false,
                                    item.Level0,
                                    item.Level1 +
                                      '|' +
                                      item.Level2 +
                                      '|' +
                                      itemList.MetricOption,
                                    'false',
                                    item.Level1,
                                    false,
                                    itemList.data_variable_code,
                                    itemList.response_code,
                                    itemList.isDerviedVariable,
                                    itemList.calculation_type,
                                    itemList.sort_id,
                                    itemList.isMultiPunch,
                                    itemList.year_code,
                                    itemList.date,
                                    ind,
                                    itemList.isMultiPunch ? item.Level2 : '',
                                    itemList.is_dimension,
                                    itemList.isCustomGroup,
                                    itemList.kibanaFilterScript,
                                    itemList.customGroupId,
                                    itemList.dataYearCode,
                                    itemList.MultiPunchNumber,
                                    itemList.isCustomCalculation,
                                    itemList.customCalculationId,
                                    item.customCalculationRowOrder,
                                    item.customGroupRowOrder
                                  )
                                ">
                                <div class="checkbox-icon"
                                     [ngClass]="{
                                    'checkbox-icon-active': getCheckboxStatusNew(
                                      item.Level0,
                                      item.Level1 +
                                        '|' +
                                        item.Level2 +
                                        '|' +
                                        itemList.MetricOption,
                                      'CustomGroup',
                                      item.Level1,
                                      item.customCalculationRowOrder,
                                      item.customGroupRowOrder
                                    )
                                  }"></div>
                                <div class="side-panel-item-name">
                                  {{ itemList.MetricOption }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="item.isCheckbox == false">
                            <div class="side-panel-main"
                                 *ngFor="let itemList of item.Level3"
                                 [ngClass]="{
                                hidden:
                                  !itemList.MetricOption.toLowerCase().includes(
                                    searchTextLevel2.toLowerCase()
                                  )
                              }"
                                 (click)="
                                plotNextLevel3(
                                  'CustomGroup',
                                  item.Level0,
                                  item.Level1,
                                  item.Level2,
                                  itemList.MetricOption,
                                  ind,
                                  item.is_entryPoint,
                                  item.customGroupRowOrder
                                )
                              ">
                              <div class="side-panel-items"
                                   title="{{ itemList.tooltip }}"
                                   [attr.data-attr]="item"
                                   [ngClass]="{
                                  'side-panel-items-active':
                                    selectedLevel1Item === itemList.MetricOption,
                                  'highlight-selected':
                                    selectedLevel1Item !==
                                      itemList.MetricOption &&
                                    getHighlightStatusNew(
                                      item.Level0,
                                      item.Level2 + '|' + itemList.MetricOption,
                                      'CustomGroup',
                                      item.Level1,
                                      item.customGroupRowOrder
                                    )
                                }">
                                <div class="side-panel-item-name">
                                  {{ itemList.MetricOption }}
                                </div>
                                <div class="side-panel-arrow">
                                  <div class="arrowToggle metric-list-open"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div class="side-panel-level-3"
                           *ngIf="sidePanelLevel3Display">
                        <div class="side-panel-search">
                          <input type="text"
                                 placeholder="Search"
                                 [(ngModel)]="searchTextLevel3"
                                 autocomplete="off"
                                 name="searchTextLevel3"
                                 (input)="onSearchLevel('level3')" />
                          <div class="search-icon">
                            <div class="search-icon-image"></div>
                          </div>
                        </div>
                        <div class="side-panel-main-container"
                             *ngFor="let item of responseData3; let ind = index">
                          <div *ngIf="item.isCheckbox == true">
                            <div class="side-panel-items"
                                 title="Select All"
                                 (click)="
                                selectAll(
                                  'Level3',
                                  'CustomGroup',
                                  false,
                                  item.customGroupRowOrder
                                )
                              "
                                 [ngClass]="{
                                'side-panel-items-active': isSelectAll
                              }"
                                 *ngIf="searchTextLevel3.trim() === ''">
                              <div class="checkbox-icon"
                                   [ngClass]="{
                                  'checkbox-icon-active': isSelectAll
                                }"></div>
                              <div class="side-panel-item-name">Select All</div>
                            </div>
                            <div *ngFor="let itemList of item.Level4"
                                 [ngClass]="{
                                hidden:
                                  !itemList.MetricOption.toLowerCase().includes(
                                    searchTextLevel3.toLowerCase()
                                  )
                              }">
                              <div class="side-panel-items"
                                   title="{{ itemList.tooltip }}"
                                   [ngClass]="{
                                  'side-panel-items-active': isSelectAll
                                }"
                                   (click)="
                                  checkboxClick(
                                    'CustomGroup',
                                    false,
                                    item.Level0,
                                    item.Level1 +
                                      '|' +
                                      item.Level2 +
                                      '|' +
                                      item.Level3 +
                                      '|' +
                                      itemList.MetricOption,
                                    'false',
                                    item.Level1,
                                    false,
                                    itemList.data_variable_code,
                                    itemList.response_code,
                                    itemList.isDerviedVariable,
                                    itemList.calculation_type,
                                    itemList.sort_id,
                                    itemList.isMultiPunch,
                                    itemList.year_code,
                                    itemList.date,
                                    ind,
                                    itemList.isMultiPunch ? item.Level3 : '',
                                    itemList.is_dimension,
                                    itemList.isCustomGroup,
                                    itemList.kibanaFilterScript,
                                    itemList.customGroupId,
                                    itemList.dataYearCode,
                                    itemList.MultiPunchNumber,
                                    itemList.isCustomCalculation,
                                    itemList.customCalculationId,
                                    item.customCalculationRowOrder,
                                    item.customGroupRowOrder
                                  )
                                ">
                                <div class="checkbox-icon"
                                     [ngClass]="{
                                    'checkbox-icon-active': getCheckboxStatusNew(
                                      item.Level0,
                                      item.Level1 +
                                        '|' +
                                        item.Level2 +
                                        '|' +
                                        item.Level3 +
                                        '|' +
                                        itemList.MetricOption,
                                      'CustomGroup',
                                      item.Level1,
                                      item.customCalculationRowOrder,
                                      item.customGroupRowOrder
                                    )
                                  }"></div>
                                <div class="side-panel-item-name">
                                  {{ itemList.MetricOption }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="item.isCheckbox == false">
                            <div class="side-panel-main"
                                 *ngFor="let itemList of item.Level4"
                                 [ngClass]="{
                                hidden:
                                  !itemList.MetricOption.toLowerCase().includes(
                                    searchTextLevel3.toLowerCase()
                                  )
                              }"
                                 (click)="
                                plotNextLevel4(
                                  'CustomGroup',
                                  item.Level0,
                                  item.Level1,
                                  item.Level2,
                                  item.Level3,
                                  itemList.MetricOption,
                                  ind,
                                  item.is_entryPoint,
                                  item.customGroupRowOrder
                                )
                              ">
                              <div class="side-panel-items"
                                   title="{{ itemList.tooltip }}"
                                   [attr.data-attr]="item"
                                   [ngClass]="{
                                  'side-panel-items-active':
                                    selectedLevel1Item === itemList.MetricOption,
                                  'highlight-selected':
                                    selectedLevel1Item !==
                                      itemList.MetricOption &&
                                    getHighlightStatusNew(
                                      item.Level0,
                                      item.Level3 + '|' + itemList.MetricOption,
                                      'CustomGroup',
                                      item.Level1,
                                      item.customGroupRowOrder
                                    )
                                }">
                                <div class="side-panel-item-name">
                                  {{ itemList.MetricOption }}
                                </div>
                                <div class="side-panel-arrow">
                                  <div class="arrowToggle metric-list-open"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div class="side-panel-level-4"
                           *ngIf="sidePanelLevel4Display">
                        <div class="side-panel-search">
                          <input type="text"
                                 placeholder="Search"
                                 [(ngModel)]="searchTextLevel4"
                                 autocomplete="off"
                                 name="searchTextLevel4"
                                 (input)="onSearchLevel('level4')" />
                          <div class="search-icon">
                            <div class="search-icon-image"></div>
                          </div>
                        </div>
                        <div class="side-panel-main-container"
                             *ngFor="let item of responseData4; let ind = index">
                          <div *ngIf="item.isCheckbox == true">
                            <div class="side-panel-items"
                                 title="Select All"
                                 (click)="
                                selectAll(
                                  'Level4',
                                  'CustomGroup',
                                  false,
                                  item.customGroupRowOrder
                                )
                              "
                                 [ngClass]="{
                                'side-panel-items-active': isSelectAll
                              }"
                                 *ngIf="searchTextLevel4.trim() === ''">
                              <div class="checkbox-icon"
                                   [ngClass]="{
                                  'checkbox-icon-active': isSelectAll
                                }"></div>
                              <div class="side-panel-item-name">Select All</div>
                            </div>
                            <div *ngFor="let itemList of item.Level5"
                                 [ngClass]="{
                                hidden:
                                  !itemList.MetricOption.toLowerCase().includes(
                                    searchTextLevel4.toLowerCase()
                                  )
                              }">
                              <div class="side-panel-items"
                                   title="{{ itemList.tooltip }}"
                                   [ngClass]="{
                                  'side-panel-items-active': isSelectAll
                                }"
                                   (click)="
                                  checkboxClick(
                                    'CustomGroup',
                                    false,
                                    item.Level0,
                                    item.Level1 +
                                      '|' +
                                      item.Level2 +
                                      '|' +
                                      item.Level3 +
                                      '|' +
                                      item.Level4 +
                                      '|' +
                                      itemList.MetricOption,
                                    'false',
                                    item.Level1,
                                    false,
                                    itemList.data_variable_code,
                                    itemList.response_code,
                                    itemList.isDerviedVariable,
                                    itemList.calculation_type,
                                    itemList.sort_id,
                                    itemList.isMultiPunch,
                                    itemList.year_code,
                                    itemList.date,
                                    ind,
                                    itemList.isMultiPunch ? item.Level4 : '',
                                    itemList.is_dimension,
                                    itemList.isCustomGroup,
                                    itemList.kibanaFilterScript,
                                    itemList.customGroupId,
                                    itemList.dataYearCode,
                                    itemList.MultiPunchNumber,
                                    itemList.isCustomCalculation,
                                    itemList.customCalculationId,
                                    item.customCalculationRowOrder,
                                    item.customGroupRowOrder
                                  )
                                ">
                                <div class="checkbox-icon"
                                     [ngClass]="{
                                    'checkbox-icon-active': getCheckboxStatusNew(
                                      item.Level0,
                                      item.Level1 +
                                        '|' +
                                        item.Level2 +
                                        '|' +
                                        item.Level3 +
                                        '|' +
                                        item.Level4 +
                                        '|' +
                                        itemList.MetricOption,
                                      'CustomGroup',
                                      item.Level1,
                                      item.customCalculationRowOrder,
                                      item.customGroupRowOrder
                                    )
                                  }"></div>
                                <div class="side-panel-item-name">
                                  {{ itemList.MetricOption }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="item.isCheckbox == false">
                            <div class="side-panel-main"
                                 *ngFor="let itemList of item.Level5"
                                 [ngClass]="{
                                hidden:
                                  !itemList.MetricOption.toLowerCase().includes(
                                    searchTextLevel4.toLowerCase()
                                  )
                              }"
                                 (click)="
                                plotNextLevel5(
                                  'CustomGroup',
                                  item.Level0,
                                  item.Level1,
                                  item.Level2,
                                  item.Level3,
                                  item.Level4,
                                  itemList.MetricOption,
                                  ind,
                                  item.is_entryPoint,
                                  item.customGroupRowOrder
                                )
                              ">
                              <div class="side-panel-items"
                                   title="{{ itemList.tooltip }}"
                                   [attr.data-attr]="item"
                                   [ngClass]="{
                                  'side-panel-items-active':
                                    selectedLevel1Item === itemList.MetricOption,
                                  'highlight-selected':
                                    selectedLevel1Item !==
                                      itemList.MetricOption &&
                                    getHighlightStatusNew(
                                      item.Level0,
                                      item.Level4 + '|' + itemList.MetricOption,
                                      'CustomGroup',
                                      item.Level1,
                                      item.customGroupRowOrder
                                    )
                                }">
                                <div class="side-panel-item-name">
                                  {{ itemList.MetricOption }}
                                </div>
                                <div class="side-panel-arrow">
                                  <div class="arrowToggle metric-list-open"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div class="side-panel-level-5"
                           *ngIf="sidePanelLevel5Display">
                        <div class="side-panel-search">
                          <input type="text"
                                 placeholder="Search"
                                 [(ngModel)]="searchTextLevel5"
                                 autocomplete="off"
                                 name="searchTextLevel5"
                                 (input)="onSearchLevel('level5')" />
                          <div class="search-icon">
                            <div class="search-icon-image"></div>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ColumnContent"
               [ngClass]="{ 'preview-expand-size': isPreviewExpand }">
            <div class="columnChildContent"
                 [ngClass]="{ hidden: isPreviewExpand }">
              <div class="ColumnHeader">
                <div class="columnIcon"></div>
                <div class="columnText">VARIABLE SUMMARY</div>
                <div class="columnDelete" (click)="clearSelection('Column')">
                  <div class="deleteIcon"></div>
                </div>
              </div>
              <div class="createCustomGroupBody">
                <div class="customGroup-btn-container">
                  <div class="customGroup-btn-inner-container">
                    <div class="customGroup-btn config-btn"
                         id="openParaButton"
                         [ngClass]="
                        disabledCustomGroupOperatorList.includes('openParaButton')
                          ? 'disabled'
                          : ''
                      "
                         (click)="bindCustomGroupOperatorClick($event, '(')">
                      (
                    </div>
                    <div class="customGroup-btn config-btn"
                         id="closeParaButton"
                         [ngClass]="
                        disabledCustomGroupOperatorList.includes(
                          'closeParaButton'
                        )
                          ? 'disabled'
                          : ''
                      "
                         (click)="bindCustomGroupOperatorClick($event, ')')">
                      )
                    </div>
                    <div class="customGroup-btn config-btn"
                         id="notButton"
                         [ngClass]="
                        disabledCustomGroupOperatorList.includes('notButton')
                          ? 'disabled'
                          : ''
                      "
                         (click)="bindCustomGroupOperatorClick($event, 'NOT')">
                      NOT
                    </div>
                    <div class="customGroup-btn config-btn"
                         id="andButton"
                         [ngClass]="
                        disabledCustomGroupOperatorList.includes('andButton')
                          ? 'disabled'
                          : ''
                      "
                         (click)="bindCustomGroupOperatorClick($event, 'AND')">
                      AND
                    </div>
                    <div class="customGroup-btn config-btn"
                         id="orButton"
                         [ngClass]="
                        disabledCustomGroupOperatorList.includes('orButton')
                          ? 'disabled'
                          : ''
                      "
                         (click)="bindCustomGroupOperatorClick($event, 'OR')">
                      OR
                    </div>
                  </div>
                </div>
  
                <div class="custom-peer-table-container">
                  <div class="custom-peer-tableElement"
                       *ngFor="let list of customGroupSelectionsObj; let ind = index">
                    <div class="custom-peer-tableElement-span"
                         [ngClass]="{
                        firstOpeningTag: list.displayString == '(' && ind == 0,
                        'active-customgroup-item': ind == customGroupItemIndex
                      }"
                         title="{{ list.displayString }}"
                         (click)="selectedVariableItem(ind,list.operatorValue)">
                      {{ list.displayString }}
                    </div>
                    <div class="closeTag"
                         [ngClass]="{
                        'active-customgroup-item': ind == customGroupItemIndex
                      }"
                         *ngIf="ind != 0 || (ind == 0 && list.displayString != '(')">
                      <div class="close-btn close-metric-icon"
                           title="Remove"
                           (click)="
                          removeCustomGroup(
                            list.variableCode,
                            list.operatorValue,
                            ind,
                            list.rowOrder
                          )
                        "></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="FooterContent" [ngClass]="{ hidden: isPreviewExpand }">
          <div class="config-btn percentage-variable-btn config-footer-btn clear-custom-group-btn"
               *ngIf="isCreateCustomGroup"
               (click)="clearCustomGroupSelection()">
            <div class="btn-icon custom-group-icon"></div>
            <div class="btn-text">Clear</div>
          </div>
          <div class="config-btn percentage-variable-btn config-footer-btn save-custom-group-btn"
               *ngIf="isCreateCustomGroup"
               (click)="saveCustomGroupSelection()">
            <div class="btn-icon custom-group-icon"></div>
            <div class="btn-text">Save</div>
          </div>
  
          <div class="config-btn percentage-variable-btn config-footer-btn"
               *ngIf="isCustomGroups"
               (click)="customGroupSelection()"
               title="{{ buttonHoverNames.Custom_Groups }}">
            <div class="btn-icon custom-group-icon"></div>
            <div class="btn-text">Saved Variables</div>
          </div>
          <div class="config-btn percentage-variable-btn config-footer-btn clear-custom-group-btn"
               *ngIf="isCreateCustomCalculation"
               (click)="clearCustomGroupSelection()">
            <div class="btn-icon custom-group-icon"></div>
            <div class="btn-text">Clear</div>
          </div>
          <div class="config-btn percentage-variable-btn config-footer-btn save-custom-group-btn"
               *ngIf="isCreateCustomCalculation"
               (click)="saveCustomGroupSelection()">
            <div class="btn-icon custom-group-icon"></div>
            <div class="btn-text">Save</div>
          </div>
        </div>
      </div>
      <!-----Custom Calculation starts here---->
      <div class="mainComponent createCustomGroupComponent createCustomCalculationComponent"
           [ngClass]="{ 'main-block-width': left_panel_collapse }"
           *ngIf="!displayMainComponent && isCreateCustomCalculation && isMeanMetric">
  
        <div class="customCalulations">
          <div class="CustomClassBlock">
            <div class="customText">MEAN VARIABLE</div>
            <div class="Back" (click)="backButtonCustomCalcClick()">
              <div class="Back-btn-icon BackIcon"></div>
              <div class="Back-Text">Back</div>
            </div>
          </div>
  
          <div class="customInfo">
            <div class="infoIcon InfoImage"></div>
            <div class="customInfoText">
              Drag & Drop <b>&nbsp;Variables&nbsp;</b> in Metrics to create Mean Variable
            </div>
          </div>
        </div>
  
        <div class="childContent"
             [ngClass]="{ 'preview-expand-size': isPreviewExpand }">
          <div class="RowContent" [ngClass]="{ hidden: isPreviewExpand,'disabled':disabledCustomCalculationOperatorList.includes('customGroup') }" >
            <div class="RowMainContent">
              <div class="rowHeader">
                <div class="rowText">VARIABLES</div>
                <div class="rowDelete" (click)="clearSelection('CustomCalculation')">
                  <div class="deleteIcon"></div>
                </div>
              </div>
              <div class="row-body-block">
                <div class="rowParent" [style.height.%]="updateHeight()">
                  <div class="rowParentContent">
                    <div class="row-parent-metrics-item"
                         cdkDropList
                         id="customVariable"
                         cdkDropListAutoScroll
                         [cdkDropListData]="listCustomCalculationItemsList"
                         [cdkDropListConnectedTo]="drag_drop_container"
                         (cdkDropListDropped)="
                        onDrop('listCustomCalculationItemsList', $event)
                      ">
                      <div *ngFor="
                          let item of listCustomCalculationItemsList;
                          let ind = index
                        "
                           cdkDrag
                           class="row-metric-item-container RowPanel"
                           (click)="
                          item.has_nextLevel &&
                            plotNextLevel(item, 'CustomCalculation',ind)
                        "
                           [ngClass]="{
                          'side-panel-items-active-no-next-lvl':
                            !item.has_nextLevel,
                          'side-panel-items-active':
                            item.has_nextLevel &&
                            droppedItem == item.Level1 + '|Row'
                        }">
                        <div class="row-metric-item-name"
                             [ngStyle]="{
                            width: item.has_nextLevel == false ? '86%' : '76%'
                          }"
                             [attr.data-attr]="item.Level0"
                             title="{{ item.tooltip }}">
                          {{ item.Level1 }}
                        </div>
                        <div class="row-mertic-item-dropdown-icon"
                             *ngIf="item.has_nextLevel">
                          <div class="arrowToggle metric-list-open"></div>
                        </div>
                        <div class="row-metric-item-remove-icon"
                             (click)="
                            removeItem(
                              'listCustomCalculationItemsList',
                              item,
                              'CustomCalculation'
                            )
                          "
                             title="REMOVE">
                          <div class="arrowToggle close-metric-icon"></div>
                        </div>
                      </div>
                    </div>
                    <div class="side-panel-block RowPanel"
                         *ngIf="sidePanelDisplay">
                      <div class="side-panel-level-1"
                           *ngIf="sidePanelLevel1Display">
                        <div class="side-panel-search">
                          <input type="text"
                                 placeholder="Search"
                                 [(ngModel)]="searchTextLevel1"
                                 autocomplete="off"
                                 name="searchTextLevel1"
                                 (input)="onSearchLevel('level1')" />
                          <div class="search-icon">
                            <div class="search-icon-image"></div>
                          </div>
                        </div>
                        <div class="side-panel-main-container"
                             *ngFor="let item of responseData1; let ind = index">
                          <div *ngIf="item.isCheckbox == true">
                   
                            <div *ngFor="let itemList of item.Level2"
                                 [ngClass]="{
                                hidden:
                                  !itemList.MetricOption.toLowerCase().includes(
                                    searchTextLevel1.toLowerCase()
                                  )
                              }">
                              <div class="side-panel-items"
                                   #checkboxIconsLevel1
                                   title="{{ itemList.tooltip }}"
                                   [ngClass]="{
                                  'side-panel-items-active': isSelectAll
                                }"
                                   (click)="
                                  checkboxClick(
                                    'CustomCalculation',
                                    false,
                                    item.Level0,
                                    item.Level1 + '|' + itemList.MetricOption,
                                    'false',
                                    item.Level1,
                                    false,
                                    itemList.data_variable_code,
                                    itemList.response_code,
                                    itemList.isDerviedVariable,
                                    itemList.calculation_type,
                                    itemList.sort_id,
                                    itemList.isMultiPunch,
                                    itemList.year_code,
                                    itemList.date,
                                    ind,
                                    itemList.isMultiPunch ? item.Level1 : '',
                                    itemList.is_dimension,
                                    itemList.isCustomGroup,
                                    itemList.kibanaFilterScript,
                                    itemList.customGroupId,
                                    itemList.dataYearCode,
                                    itemList.MultiPunchNumber,
                                    itemList.isCustomCalculation,
                                    itemList.customCalculationId,
                                    item.customCalculationRowOrder,
                                    item.customGroupRowOrder
                                  )
                                ">
                                <div class="checkbox-icon"
                                     [ngClass]="{
                                    'checkbox-icon-active': getCheckboxStatusNew(
                                      item.Level0,
                                      item.Level1 + '|' + itemList.MetricOption,
                                      'CustomCalculation',
                                      item.Level1,
                                      item.customCalculationRowOrder,
                                      item.customCalculationRowOrder
                                    )
                                  }"></div>
                                <div class="side-panel-item-name">
                                  {{ itemList.MetricOption }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="item.isCheckbox == false">
                            <div class="side-panel-main"
                                 *ngFor="let itemList of item.Level2"
                                 [ngClass]="{
                                hidden:
                                  !itemList.MetricOption.toLowerCase().includes(
                                    searchTextLevel1.toLowerCase()
                                  )
                              }"
                                 (click)="
                                plotNextLevel2(
                                  'CustomCalculation',
                                  item.Level0,
                                  item.Level1,
                                  itemList.MetricOption,
                                  ind,
                                  item.is_entryPoint,
                                  item.customGroupRowOrder,
                                  item.customCalculationRowOrder
                                )
                              ">
                              <div class="side-panel-items"
                                   title="{{ itemList.tooltip }}"
                                   [attr.data-attr]="item"
                                   [ngClass]="{
                                  'side-panel-items-active':
                                    selectedLevel1Item === itemList.MetricOption,
                                  'highlight-selected':
                                    selectedLevel1Item !==
                                      itemList.MetricOption &&
                                    getHighlightStatusNew(
                                      item.Level0,
                                      item.Level1 + '|' + itemList.MetricOption,
                                      'CustomCalculation',
                                      item.Level1,
                                      item.customCalculationRowOrder
                                    )
                                }">
                                <div class="side-panel-item-name">
                                  {{ itemList.MetricOption }}
                                </div>
                                <div class="side-panel-arrow">
                                  <div class="arrowToggle metric-list-open"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="side-panel-level-2"
                           *ngIf="sidePanelLevel2Display">
                        <div class="side-panel-search">
                          <input type="text"
                                 placeholder="Search"
                                 [(ngModel)]="searchTextLevel2"
                                 autocomplete="off"
                                 name="searchTextLevel2"
                                 (input)="onSearchLevel('level2')" />
                          <div class="search-icon">
                            <div class="search-icon-image"></div>
                          </div>
                        </div>
                        <div class="side-panel-main-container"
                             *ngFor="let item of responseData2; let ind = index">
                          <div *ngIf="item.isCheckbox == true">
               
                            <div *ngFor="let itemList of item.Level3"
                                 [ngClass]="{
                                hidden:
                                  !itemList.MetricOption.toLowerCase().includes(
                                    searchTextLevel2.toLowerCase()
                                  )
                              }">
                              <div class="side-panel-items"
                                   title="{{ itemList.tooltip }}"
                                   [ngClass]="{
                                  'side-panel-items-active': isSelectAll
                                }"
                                   (click)="
                                  checkboxClick(
                                    'CustomCalculation',
                                    false,
                                    item.Level0,
                                    item.Level1 +
                                      '|' +
                                      item.Level2 +
                                      '|' +
                                      itemList.MetricOption,
                                    'false',
                                    item.Level1,
                                    false,
                                    itemList.data_variable_code,
                                    itemList.response_code,
                                    itemList.isDerviedVariable,
                                    itemList.calculation_type,
                                    itemList.sort_id,
                                    itemList.isMultiPunch,
                                    itemList.year_code,
                                    itemList.date,
                                    ind,
                                    itemList.isMultiPunch ? item.Level2 : '',
                                    itemList.is_dimension,
                                    itemList.isCustomGroup,
                                    itemList.kibanaFilterScript,
                                    itemList.customGroupId,
                                    itemList.dataYearCode,
                                    itemList.MultiPunchNumber,
                                    itemList.isCustomCalculation,
                                    itemList.customCalculationId,
                                    item.customCalculationRowOrder,
                                    item.customGroupRowOrder
                                  )
                                ">
                                <div class="checkbox-icon"
                                     [ngClass]="{
                                    'checkbox-icon-active': getCheckboxStatusNew(
                                      item.Level0,
                                      item.Level1 +
                                        '|' +
                                        item.Level2 +
                                        '|' +
                                        itemList.MetricOption,
                                      'CustomCalculation',
                                      item.Level1,
                                      item.customCalculationRowOrder
  
                                    )
                                  }"></div>
                                <div class="side-panel-item-name">
                                  {{ itemList.MetricOption }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="item.isCheckbox == false">
                            <div class="side-panel-main"
                                 *ngFor="let itemList of item.Level3"
                                 [ngClass]="{
                                hidden:
                                  !itemList.MetricOption.toLowerCase().includes(
                                    searchTextLevel2.toLowerCase()
                                  )
                              }"
                                 (click)="
                                plotNextLevel3(
                                  'CustomCalculation',
                                  item.Level0,
                                  item.Level1,
                                  item.Level2,
                                  itemList.MetricOption,
                                  ind,
                                  item.is_entryPoint,
                                  item.customGroupRowOrder,
                                  item.customCalculationRowOrder
                                )
                              ">
                              <div class="side-panel-items"
                                   title="{{ itemList.tooltip }}"
                                   [attr.data-attr]="item"
                                   [ngClass]="{
                                  'side-panel-items-active':
                                    selectedLevel1Item === itemList.MetricOption,
                                  'highlight-selected':
                                    selectedLevel1Item !==
                                      itemList.MetricOption &&
                                    getHighlightStatusNew(
                                      item.Level0,
                                      item.Level2 + '|' + itemList.MetricOption,
                                      'CustomCalculation',
                                      item.Level1,
                                      item.customCalculationRowOrder
                                    )
                                }">
                                <div class="side-panel-item-name">
                                  {{ itemList.MetricOption }}
                                </div>
                                <div class="side-panel-arrow">
                                  <div class="arrowToggle metric-list-open"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div class="side-panel-level-3"
                           *ngIf="sidePanelLevel3Display">
                        <div class="side-panel-search">
                          <input type="text"
                                 placeholder="Search"
                                 [(ngModel)]="searchTextLevel3"
                                 autocomplete="off"
                                 name="searchTextLevel3"
                                 (input)="onSearchLevel('level3')" />
                          <div class="search-icon">
                            <div class="search-icon-image"></div>
                          </div>
                        </div>
                        <div class="side-panel-main-container"
                             *ngFor="let item of responseData3; let ind = index">
                          <div *ngIf="item.isCheckbox == true">
                    
                            <div *ngFor="let itemList of item.Level4"
                                 [ngClass]="{
                                hidden:
                                  !itemList.MetricOption.toLowerCase().includes(
                                    searchTextLevel3.toLowerCase()
                                  )
                              }">
                              <div class="side-panel-items"
                                   title="{{ itemList.tooltip }}"
                                   [ngClass]="{
                                  'side-panel-items-active': isSelectAll
                                }"
                                   (click)="
                                  checkboxClick(
                                    'CustomCalculation',
                                    false,
                                    item.Level0,
                                    item.Level1 +
                                      '|' +
                                      item.Level2 +
                                      '|' +
                                      item.Level3 +
                                      '|' +
                                      itemList.MetricOption,
                                    'false',
                                    item.Level1,
                                    false,
                                    itemList.data_variable_code,
                                    itemList.response_code,
                                    itemList.isDerviedVariable,
                                    itemList.calculation_type,
                                    itemList.sort_id,
                                    itemList.isMultiPunch,
                                    itemList.year_code,
                                    itemList.date,
                                    ind,
                                    itemList.isMultiPunch ? item.Level3 : '',
                                    itemList.is_dimension,
                                    itemList.isCustomGroup,
                                    itemList.kibanaFilterScript,
                                    itemList.customGroupId,
                                    itemList.dataYearCode,
                                    itemList.MultiPunchNumber,
                                    itemList.isCustomCalculation,
                                    itemList.customCalculationId,
                                    item.customCalculationRowOrder,
                                    item.customGroupRowOrder
                                  )
                                ">
                                <div class="checkbox-icon"
                                     [ngClass]="{
                                    'checkbox-icon-active': getCheckboxStatusNew(
                                      item.Level0,
                                      item.Level1 +
                                        '|' +
                                        item.Level2 +
                                        '|' +
                                        item.Level3 +
                                        '|' +
                                        itemList.MetricOption,
                                      'CustomCalculation',
                                      item.Level1,
                                      item.customCalculationRowOrder
  
                                    )
                                  }"></div>
                                <div class="side-panel-item-name">
                                  {{ itemList.MetricOption }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="item.isCheckbox == false">
                            <div class="side-panel-main"
                                 *ngFor="let itemList of item.Level4"
                                 [ngClass]="{
                                hidden:
                                  !itemList.MetricOption.toLowerCase().includes(
                                    searchTextLevel3.toLowerCase()
                                  )
                              }"
                                 (click)="
                                plotNextLevel4(
                                  'CustomCalculation',
                                  item.Level0,
                                  item.Level1,
                                  item.Level2,
                                  item.Level3,
                                  itemList.MetricOption,
                                  ind,
                                  item.is_entryPoint,
                                  item.customGroupRowOrder,
                                  item.customCalculationRowOrder
                                )
                              ">
                              <div class="side-panel-items"
                                   title="{{ itemList.tooltip }}"
                                   [attr.data-attr]="item"
                                   [ngClass]="{
                                  'side-panel-items-active':
                                    selectedLevel1Item === itemList.MetricOption,
                                  'highlight-selected':
                                    selectedLevel1Item !==
                                      itemList.MetricOption &&
                                    getHighlightStatusNew(
                                      item.Level0,
                                      item.Level3 + '|' + itemList.MetricOption,
                                      'CustomCalculation',
                                      item.Level1,
                                      item.customCalculationRowOrder
                                    )
                                }">
                                <div class="side-panel-item-name">
                                  {{ itemList.MetricOption }}
                                </div>
                                <div class="side-panel-arrow">
                                  <div class="arrowToggle metric-list-open"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div class="side-panel-level-4"
                           *ngIf="sidePanelLevel4Display">
                        <div class="side-panel-search">
                          <input type="text"
                                 placeholder="Search"
                                 [(ngModel)]="searchTextLevel4"
                                 autocomplete="off"
                                 name="searchTextLevel4"
                                 (input)="onSearchLevel('level4')" />
                          <div class="search-icon">
                            <div class="search-icon-image"></div>
                          </div>
                        </div>
                        <div class="side-panel-main-container"
                             *ngFor="let item of responseData4; let ind = index">
                          <div *ngIf="item.isCheckbox == true">
                        
                            <div *ngFor="let itemList of item.Level5"
                                 [ngClass]="{
                                hidden:
                                  !itemList.MetricOption.toLowerCase().includes(
                                    searchTextLevel4.toLowerCase()
                                  )
                              }">
                              <div class="side-panel-items"
                                   title="{{ itemList.tooltip }}"
                                   [ngClass]="{
                                  'side-panel-items-active': isSelectAll
                                }"
                                   (click)="
                                  checkboxClick(
                                    'CustomCalculation',
                                    false,
                                    item.Level0,
                                    item.Level1 +
                                      '|' +
                                      item.Level2 +
                                      '|' +
                                      item.Level3 +
                                      '|' +
                                      item.Level4 +
                                      '|' +
                                      itemList.MetricOption,
                                    'false',
                                    item.Level1,
                                    false,
                                    itemList.data_variable_code,
                                    itemList.response_code,
                                    itemList.isDerviedVariable,
                                    itemList.calculation_type,
                                    itemList.sort_id,
                                    itemList.isMultiPunch,
                                    itemList.year_code,
                                    itemList.date,
                                    ind,
                                    itemList.isMultiPunch ? item.Level4 : '',
                                    itemList.is_dimension,
                                    itemList.isCustomGroup,
                                    itemList.kibanaFilterScript,
                                    itemList.customGroupId,
                                    itemList.dataYearCode,
                                    itemList.MultiPunchNumber,
                                    itemList.isCustomCalculation,
                                    itemList.customCalculationId,
                                    item.customCalculationRowOrder,
                                    item.customGroupRowOrder
                                  )
                                ">
                                <div class="checkbox-icon"
                                     [ngClass]="{
                                    'checkbox-icon-active': getCheckboxStatusNew(
                                      item.Level0,
                                      item.Level1 +
                                        '|' +
                                        item.Level2 +
                                        '|' +
                                        item.Level3 +
                                        '|' +
                                        item.Level4 +
                                        '|' +
                                        itemList.MetricOption,
                                      'CustomCalculation',
                                      item.Level1,
                                      item.customCalculationRowOrder
  
                                    )
                                  }"></div>
                                <div class="side-panel-item-name">
                                  {{ itemList.MetricOption }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="item.isCheckbox == false">
                            <div class="side-panel-main"
                                 *ngFor="let itemList of item.Level5"
                                 [ngClass]="{
                                hidden:
                                  !itemList.MetricOption.toLowerCase().includes(
                                    searchTextLevel4.toLowerCase()
                                  )
                              }"
                                 (click)="
                                plotNextLevel5(
                                  'CustomCalculation',
                                  item.Level0,
                                  item.Level1,
                                  item.Level2,
                                  item.Level3,
                                  item.Level4,
                                  itemList.MetricOption,
                                  ind,
                                  item.is_entryPoint,
                                  item.customGroupRowOrder,
                                  item.customCalculationRowOrder
                                )
                              ">
                              <div class="side-panel-items"
                                   title="{{ itemList.tooltip }}"
                                   [attr.data-attr]="item"
                                   [ngClass]="{
                                  'side-panel-items-active':
                                    selectedLevel1Item === itemList.MetricOption,
                                  'highlight-selected':
                                    selectedLevel1Item !==
                                      itemList.MetricOption &&
                                    getHighlightStatusNew(
                                      item.Level0,
                                      item.Level4 + '|' + itemList.MetricOption,
                                      'CustomCalculation',
                                      item.Level1,
                                      item.customCalculationRowOrder
                                    )
                                }">
                                <div class="side-panel-item-name">
                                  {{ itemList.MetricOption }}
                                </div>
                                <div class="side-panel-arrow">
                                  <div class="arrowToggle metric-list-open"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
  
                      <div class="side-panel-level-5"
                           *ngIf="sidePanelLevel5Display">
                        <div class="side-panel-search">
                          <input type="text"
                                 placeholder="Search"
                                 [(ngModel)]="searchTextLevel5"
                                 autocomplete="off"
                                 name="searchTextLevel5"
                                 (input)="onSearchLevel('level5')" />
                          <div class="search-icon">
                            <div class="search-icon-image"></div>
                          </div>
                        </div>
            
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ColumnContent"
               [ngClass]="{ 'preview-expand-size': isPreviewExpand }">
            <div class="columnChildContent"
                 [ngClass]="{ hidden: isPreviewExpand }">
              <div class="ColumnHeader">
                <div class="columnIcon"></div>
                <div class="columnText">VARIABLE SUMMARY</div>
                <div class="columnDelete" (click)="clearSelection('Column')">
                  <div class="deleteIcon"></div>
                </div>
              </div>
              <div class="createCustomGroupBody">
                <div class="customGroup-btn-container">
                  <div class="customGroup-btn-inner-container">
                    <div class="customGroup-btn config-btn"
                         id="openParaButton"
                         [ngClass]="
                        disabledCustomCalculationOperatorList.includes('openParaButton')
                          ? 'disabled'
                          : ''
                      "
                         (click)="bindCustomCalculationOperatorClick($event, '(')">
                      (
                    </div>
                    <div class="customGroup-btn config-btn"
                         id="closeParaButton"
                         [ngClass]="
                        disabledCustomCalculationOperatorList.includes(
                          'closeParaButton'
                        )
                          ? 'disabled'
                          : ''
                      "
                         (click)="bindCustomCalculationOperatorClick($event, ')')">
                      )
                    </div>
                    <div class="customGroup-btn config-btn"
                         id="addButton"
                         [ngClass]="
                        disabledCustomCalculationOperatorList.includes('addButton')
                          ? 'disabled'
                          : ''
                      "
                         (click)="bindCustomCalculationOperatorClick($event, '+')">
                      +
                    </div>
                    <div class="customGroup-btn config-btn"
                         id="subtractButton"
                         [ngClass]="
                        disabledCustomCalculationOperatorList.includes('subtractButton')
                          ? 'disabled'
                          : ''
                      "
                         (click)="bindCustomCalculationOperatorClick($event, '-')">
                      -
                    </div>
                    <div class="customGroup-btn config-btn"
                         id="multiplyButton"
                         [ngClass]="
                        disabledCustomCalculationOperatorList.includes('multiplyButton')
                          ? 'disabled'
                          : ''
                      "
                         (click)="bindCustomCalculationOperatorClick($event, '*')">
                      *
                    </div>
                    <div class="customGroup-btn config-btn"
                         id="divideButton"
                         [ngClass]="
                        disabledCustomCalculationOperatorList.includes('divideButton')
                          ? 'disabled'
                          : ''
                      "
                         (click)="bindCustomCalculationOperatorClick($event, '/')">
                      /
                    </div>
                    <div class="customGroup-btn config-btn"
                         id="numeralButton"
                         [ngClass]="
                        disabledCustomCalculationOperatorList.includes('numeralButton')
                          ? 'disabled'
                          : ''
                      "
                         (click)="bindCustomCalculationOperatorClick($event, 'NUMERAL')">
                      NUMERAL
                    </div>
  
  
  
                  </div>
                </div>
  
                <div class="custom-peer-table-container">
                  <div class="custom-peer-tableElement"
                       *ngFor="let list of customCalculationSelectionsObj; let ind = index">
                    <div class="custom-peer-tableElement-span"
                         [ngClass]="{
                        firstOpeningTag: list.displayString == '(' && ind == 0,
                        'active-customgroup-item': ind == customCalculationItemIndex
                      }"
                         title="{{ list.displayString }}"
                         (click)="selectedVariableItem(ind,list.operatorValue)">
                      {{ list.displayString }}
                    </div>
                    <div class="closeTag"
                         [ngClass]="{
                        'active-customgroup-item': ind == customCalculationItemIndex
                      }"
                         *ngIf="ind != 0 || (ind == 0 && list.displayString != '(')">
                      <div class="close-btn close-metric-icon"
                           title="Remove"
                           (click)="
                          removeCustomCalculation(
                            list.variableCode,
                            list.operatorValue,
                            ind,
                            list.rowOrder
                          )
                        "></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="FooterContent" [ngClass]="{ hidden: isPreviewExpand }">
  
          <div class="config-btn config-footer-btn clear-custom-group-btn"
               *ngIf="isCreateCustomCalculation"
               (click)="clearCustomCalculationSelection()">
            <div class="btn-icon custom-group-icon"></div>
            <div class="btn-text">Clear Mean Variable</div>
          </div>
          <div class="config-btn config-footer-btn save-custom-group-btn"
               *ngIf="isCreateCustomCalculation"
               (click)="saveCustomCalculationSelection()">
            <div class="btn-icon custom-group-icon"></div>
            <div class="btn-text">Save Mean Variable</div>
          </div>
  
          <div class="config-btn config-footer-btn custom-calc-btn"
               *ngIf="isCustomCalculation"
               (click)="customCalculationSelection()">
            <div class="btn-icon custom-calc-icon"></div>
            <div class="btn-text">Mean Variable</div>
          </div>
  
        </div>
      </div>
      <!-----custom Calculation ends here---->
    </div>
  
  
  </section>

  <app-shared-popup *ngIf="isShareCustomGroupPopupClick"
                    [customGroupShareUserList]="customGroupShareUserList"
                    [showLoaderPopUp]="showLoaderPopUp"
                    [selectedCustomGroupObj]="selectedCustomGroupObj"
                    [selectedEmailsToShare]="selectedEmailsToShare"
                    [isShareCustomGroupPopupClick]="isShareCustomGroupPopupClick"
                    (isCustomGroupShareVisible)="handleCustomGroupSharePopUp($event)"
                    (bindCustomGroupFlags)="bindCustomGroupFlags($event)"></app-shared-popup>
  
  <app-shared-popup *ngIf="isSaveCustomGroupSelectionClick"
  [isDuplicateCustomGroup]="isDuplicateCustomGroup"
  [isSaveCustomGroupSelectionClick]="isSaveCustomGroupSelectionClick"
  [showLoaderPopUp]="showLoaderPopUp"
  (isCustomGroupSavePopUpVisible)="handleCustomGroupSavePopUp($event)"
  (SaveCustomSelectionClick)="CustomGroupSaveSelection($event)"></app-shared-popup>
  