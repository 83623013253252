import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import * as Highcharts from 'highcharts';
import { HttpClient } from '@angular/common/http';
import { LineOrTrendedService } from '../Trend Chart/lineChart.service';
import { CrosstabService } from 'src/app/common/services/crosstab.service';
import { COMMON_CONSTANTS } from '../../constants/common-constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-lineChart',
  templateUrl: './lineChart.component.html',
  styleUrls: ['./lineChart.component.css'],
})
export class LineOrTrendChart1Component {
  LowBaseSample = COMMON_CONSTANTS.LowBase_Sample;
  InsufficientBaseSample = COMMON_CONSTANTS.InsufficientBase_Sample;
  @Input() insufficientBase: string = '';
  @Input() LowBase: string = '';
  DefaultConfidenceLevel: string = '95%';
  Sig_LowerLimt = COMMON_CONSTANTS.SigLowerLimit_95;
  Sig_UpperLimt = COMMON_CONSTANTS.SigUpperLimit_95;

  LineChartData: any[] = [];
  ChartProperties: any;
  categoriesList: any;
  colorCodeList: any[] = [];
  seriesData: any[] = [];
  chart: any;
  custom: any[] = [];
  event: any;
  changeValue: any[] = [];
  significanceValue: any[] = [];
  sampleSizeValues: any[] = [];
  actualDataValue: any[] = [];
  chartCategories: any;
  samplesizeConstantValue = 1000;
  chartMinAxis: any;
  chartMaxAxis: any;
  showLowSample: any;
  //ColorCodes: string[] = ['#802AB7', '#00B6FF', '#2C70A3', '#802A71', '#EFB467', '#47B7AE', '#F9C099', '#2A80B7', '#B78000', '#2A80FF', '#00C7E8', '#1B4C96', '#571F8A', '#077A99', '#00C7E8'];
  NewColorCodes!: string[];
  properties = [
    {
      "chartStyle": "area",
      "showLegend": true,
      "gridlines": false,
      "legendPosition": "bottom",
      "stacking": 0,
      "darkTheme": false,
      "axisLabelOrientation": "center",
      "chartTitlePosition": "center",
      "showDataLabel": true,
      "datalabelFormat": "percentage",
      "dataLabelPositionPPT": "center",
      "datalabelStyle": "sigchan",
      "datalabelOrientation": 0,
      "axisImage": false,
      "lineWidth": 2,
      "gridLineDashStyle": "Solid",
      "markerLineWidth": 1,
      "markerStyle": "circle",
      "title": "LineChart"
    }
  ];

  subscription!: Subscription; // To hold the subscription
  
  constructor(public http: HttpClient, public LineTrendService: LineOrTrendedService, private dataSharingService: CrosstabService) { }

  ngOnInit(): void {
    this.LowBaseSample = this.LowBase == "" ? 0 : Number(this.LowBase);
    this.InsufficientBaseSample = this.insufficientBase == "" ? 0 : Number(this.insufficientBase);

    this.dataSharingService.LineChartDataSubject$.subscribe(({ data, listOfCategory, Significance, ConfidenceLevel, numberType, chartType }) => {
      this.LineChartData = data.Data;
      if (Significance == "None") {
        this.properties[0].datalabelStyle = '';
      }
      else {
        this.properties[0].datalabelStyle = 'sigchan';
        this.DefaultConfidenceLevel = ConfidenceLevel;
      }
      if (numberType == "mean") {
        this.properties[0].datalabelFormat = '';
      }
      this.ChartProperties = this.properties[0];

      // Call the service to get the color palette
      this.subscription  = this.dataSharingService.colorPalette$.subscribe((result:any) => {
        this.NewColorCodes = result; // Assign the color palette from the service
      });


      // this.colorCodeList = this.ColorCodes;
      this.colorCodeList = this.NewColorCodes;
      this.categoriesList = listOfCategory;
      this.plotLineChart('line-chartContainer', listOfCategory);
    });

    //this.categoriesList = ["Oct'2023", "Nov'2023", "Dec'2023", "Jan'2024", "Feb'2024"]
    //this.http.get<any>('assets/Data/areaChartData.json').subscribe( jsonData  => {
    //  this.LineChartData = jsonData[0].data;
    //  this.ChartProperties = this.properties[0];
    //  this.colorCodeList = this.ColorCodes;
    //  this.plotLineChart('chartContainer');
    //}, error => {
    //  console.error('Error fetching data:', error);
    //});   
  }

  //   data = [{
  //   CategoryId: 1,
  //   RowOption: "Asia",
  //   RowId: 35,
  //   color: "#98fb98",
  //   share: 0,
  //   xaxisshare: 0
  // },
  // {
  //   CategoryId: 2,
  //   RowOption: "Asia",
  //   RowId: 35,
  //   color: "#98fb98",
  //   share: 290,
  //   xaxisshare: 15
  // },
  // {
  //   CategoryId: 3,
  //   RowOption: "Asia",
  //   RowId: 35,
  //   color: "#98fb98",
  //   share: 480,
  //   xaxisshare: 30
  // },
  // {
  //   CategoryId: 4,
  //   RowOption: "Asia",
  //   RowId: 35,
  //   color: "#98fb98",
  //   share: 620,
  //   xaxisshare: 45
  // },
  // {
  //   CategoryId: 5,
  //   RowOption: "Asia",
  //   RowId: 35,
  //   color: "#98fb98",
  //   share: 620,
  //   xaxisshare: 60
  // }, {
  //   CategoryId: 1,
  //   RowOption: "Africa",
  //   RowId: 36,
  //   color: "#f8e473",
  //   share: 0,
  //   xaxisshare: 0
  // },
  // {
  //   CategoryId: 2,
  //   RowOption: "Africa",
  //   RowId: 36,
  //   color: "#f8e473",
  //   share: 250,
  //   xaxisshare: 15
  // },
  // {
  //   CategoryId: 5,
  //   RowOption: "Africa",
  //   RowId: 36,
  //   color: "#f8e473",
  //   share: 570,
  //   xaxisshare: 60
  // },
  // {
  //   CategoryId: 1,
  //   RowOption: "Europe",
  //   RowId: 37,
  //   color: "#fe7f9c",
  //   share: 0,
  //   xaxisshare: 0

  // }, {
  //   CategoryId: 2,
  //   RowOption: "Europe",
  //   RowId: 37,
  //   color: "#fe7f9c",
  //   share: 220,
  //   xaxisshare: 15

  // }, {
  //   CategoryId: 4,
  //   RowOption: "Europe",
  //   RowId: 37,
  //   color: "#fe7f9c",
  //   share: 430,
  //   xaxisshare: 45

  // }, {
  //   CategoryId: 5,
  //   RowOption: "Europe",
  //   RowId: 37,
  //   color: "#fe7f9c",
  //   share: 505,
  //   xaxisshare: 60

  //     };

  customization() {
    return this.LineTrendService.customizationService()
  }

  commonfunc(event: any, customization: string, options: any) {

    this.event = event;
    this.ChartProperties = this.LineTrendService.commonFunc(customization, event, this.ChartProperties)
    this.plotLineChart('line-chartContainer', null);
  }

  plotyaxis() {

    if (this.ChartProperties.stacking == 1) {
      return {
        title: {
          text: '',
          style: {
            color: this.ChartProperties.darkTheme ? "white" : "Black"
          }
        },
        visible: false,
        gridLineWidth: this.ChartProperties.gridlines,
        labels: {
          style: {
            color: this.ChartProperties.darkTheme ? "white" : "Black"
          }
        }
      }
    } else {
      return {
        title: {
          text: '',
          style: {
            color: this.ChartProperties.darkTheme ? "white" : "Black"
          }
        },
        min: 0,
        max: this.chartMaxAxis > 100 ? 100 : this.chartMaxAxis,
        gridLineWidth: this.ChartProperties.gridlines,
        visible: false,
        labels: {
          style: {
            color: this.ChartProperties.darkTheme ? "white" : "Black"
          }
        }
      }
    }
  }

  plotLineChart(containerId: any, listOfCategory: any) {
    localStorage.setItem("properties", JSON.stringify(this.ChartProperties));
    this.prepareSeriesDataForChart(listOfCategory);
    let properties = this.ChartProperties;
    let samplesizeConstantValue = this.samplesizeConstantValue;
    let lowSampleValue = this.showLowSample;
    let getTooltipValue = this.getTooltipValue;
    const updatedCategoryNames = this.LineTrendService.updatedCategories.map(category => category.image);
    let updatedCategories = this.LineTrendService.updatedCategories;
    let ispercentage = false;
    let InsufficientBase = this.InsufficientBaseSample;
    let lowSample = this.LowBaseSample;
    if (this.ChartProperties.datalabelFormat === "percentage") {
      ispercentage = true;
    }

    if (this.ChartProperties.stacking == 1 || this.ChartProperties.stacking == 2) {
      this.seriesData.reverse();
    }
    this.chart = new Highcharts.Chart(containerId, {
      chart: {
        type: 'line',
        marginLeft: 50,   // Increase left margin
        marginRight: 50,
        backgroundColor: this.ChartProperties.darkTheme ? "Black" : "white",
        style: {
          color: this.ChartProperties.darkTheme ? "white" : "Black",
        },

      },
      title: {
        text: this.ChartProperties.chartTitle,
        style: {
          color: this.ChartProperties.darkTheme ? "white" : "Black",
        },
      },
      credits: {
        enabled: false
      },
      //yAxis: {
      //  visible: false // Correct syntax to hide the y-axis
      //},
      yAxis: this.plotyaxis(),
      xAxis: {
        title: {
          text: '',
          style: {
            fontSize: '12px',
            color: '#E8E8E8'
          }
        },
        // min: 0,
        gridLineWidth: this.ChartProperties.gridlines,
        categories: properties.axisImage ? updatedCategoryNames : this.categoriesList,
        labels: {
          rotation: this.LineTrendService.axisLabelOrientation(this.ChartProperties.axisLabelOrientation),
          style: {
            color: '#000000',
            fontSize: '0.7rem',
            fontWeight: 400
          },
          //useHTML: true,
          //formatter: function (this: any) {
          //  //if (properties.axisImage) {
          //  //  var index = this.axis.categories.indexOf(String(this.value));
          //  //  var category = updatedCategories[index];

          //  //  if (category && category.image) {
          //  //    return '<span style="display: inline-block; width: 20px; height: 20px; background: url(' + category.image + ') no-repeat center center; background-size: cover;"></span>';
          //  //  } else {
          //  //    return '<span>No Image</span>';
          //  //  }
          //  //} else {
          //    return this.value;
          //  //}
          //},
        }
      },

      tooltip: {
        enabled: true,
        borderWidth: 0,
        useHTML: true,
        shape: 'callout',
        shadow: false,
        backgroundColor: 'rgba(0,0,0,0)',
        formatter: function (this: any) { //for formatting tooltip
          const dotColor = this.series.color;

          return '<div style="background-color: #f5f5f5; padding: 5px; align-items: center; border: 1px solid ' + dotColor + ';">' +
            this.series.userOptions.name + "</br>" +
            this.series.userOptions.data1[this.point.x]["Category Name"] + ' : ' +
            getTooltipValue(this.y, this.series.userOptions.data1[this.point.x]["Rounded Value"], this.series.userOptions.data1[this.point.x]["Sample Size"], ispercentage, InsufficientBase, lowSample) +
            '</div>';

        }
      },
      legend: this.LineTrendService.customizeLegend(properties.showLegend, properties.legendPosition, this.ChartProperties.darkTheme, this.ChartProperties.stacking),
      plotOptions: {
        line: {
          stacking: this.ChartProperties.stacking == 0 ? "" : (this.ChartProperties.stacking == 1 ? "normal" : "percent"),
          lineWidth: properties.lineWidth,
          dashStyle: properties.gridLineDashStyle,
          fillOpacity: 0.4,
          marker: {
            enabled: true,
            symbol: properties.markerStyle,
            radius: 4,
            lineWidth: properties.markerLineWidth,
          },
          dataLabels: {
            enabled: properties.showDataLabel,
            align: properties.dataLabelPositionPPT,
            rotation: properties.datalabelOrientation,
            backgroundColor: 'transparent',
            formatter: function (this: any) {
              let idx = 0;

              if (properties.datalabelStyle == "sigchan") {
                this.Sig_LowerLimt =
                  this.DefaultConfidenceLevel == '95%'
                    ? COMMON_CONSTANTS.SigLowerLimit_95
                    : this.DefaultConfidenceLevel == '90%'
                      ? COMMON_CONSTANTS.SigLowerLimit_90
                      : this.DefaultConfidenceLevel == '98%'
                        ? COMMON_CONSTANTS.SigLowerLimit_98
                        : COMMON_CONSTANTS.SigLowerLimit_95;
                this.Sig_UpperLimt =
                  this.DefaultConfidenceLevel == '95%'
                    ? COMMON_CONSTANTS.SigUpperLimit_95
                    : this.DefaultConfidenceLevel == '90%'
                      ? COMMON_CONSTANTS.SigUpperLimit_90
                      : this.DefaultConfidenceLevel == '98%'
                        ? COMMON_CONSTANTS.SigUpperLimit_98
                        : COMMON_CONSTANTS.SigUpperLimit_95;

                let text = this.y == 0.000001 ? 'NA' : this.y + (ispercentage ? '%' : '');
                let changevspy = (isNaN(this.series.userOptions.data1[this.point.x]["Change value vs PY"]) ? "(NA)" : ('(' + this.series.userOptions.data1[this.point.x]["Change value vs PY"] + (ispercentage ? '%' : '') + ')'));
                let sig = this.series.userOptions.data1[this.point.x]["Significance"];

                if (text == 'NA') {
                  return (
                    '<span >' + text + '</span>'
                  );
                }
                else if (changevspy == '(NA)') {
                  return (
                    '<span>' + text + '<span>' + changevspy + '</span></span>'
                  );
                }
                if (sig < this.Sig_LowerLimt) {
                  return (
                    '<span>' + text + '<span  style="color: red;">' + changevspy + '</span></span>'
                  )
                } else if (sig > this.Sig_UpperLimt) {
                  return (
                    '<span>' + text + '<span  style="color: green;">' + changevspy + '</span></span>'
                  )
                }
                else {
                  return (
                    '<span>' + text + '<span>' + changevspy + '</span></span>'
                  );
                }
              }

              return (this.series.userOptions.data1[this.point.x]['Sample Size'] != 0 && this.series.userOptions.data1[this.point.x]['Sample Size'] < InsufficientBase) ? 'I/S' : this.y == 0.000001 ? "NA" : (this.series.userOptions.data1[this.point.x]['Sample Size'] != 0 && (this.series.userOptions.data1[this.point.x]['Sample Size'] < lowSample && this.series.userOptions.data1[this.point.x]['Sample Size'] > InsufficientBase)) ? (this.y + (ispercentage ? '%' : '') + ('*')) : (this.y + (ispercentage ? '%' : ''));
              //return this.y != 0 ? this.y + (ispercentage && '%') : 'NA';
            }
          }
        }

      },
      series: this.seriesData,
    } as any);

    var chart = this.chart;
    var legendWidth = chart.legend.legendWidth;
    // Adjust margins based on the legend width

    if (this.ChartProperties.legendPosition == "left" && this.ChartProperties.showLegend) {
      this.chart.update({
        chart: {
          marginLeft: legendWidth + 40, // Add some extra space
          marginRight: 30
        }
      }, false);
    }

    if (this.ChartProperties.legendPosition == "right" && this.ChartProperties.showLegend) {
      this.chart.update({
        chart: {
          marginLeft: legendWidth + 5, // Add some extra space
          marginRight: legendWidth + 25
        }
      }, false);
    }

  }

  getTooltipValue(value: any, actualValue: any, sampleSize: any, ispercentage: boolean, InsufficientBaseSample: any, LowBaseSample: any) {
    if (sampleSize != 0 && (sampleSize < InsufficientBaseSample)) {
      return actualValue = 'I/S';
    }
    else if (sampleSize != 0 && (sampleSize < LowBaseSample && sampleSize > InsufficientBaseSample)) {
      return actualValue = ispercentage ? (actualValue + '% *') : (actualValue + ' *');
    }
    else if (actualValue == null || actualValue == "NA") {
      return 'NA';
    }
    else {
      return actualValue = ispercentage ? (actualValue + '%') : (actualValue);
    }
  }
  prepareSeriesDataForChart(listOfCategory: any) {
    this.seriesData = [];
    let metrics = _.uniqBy(this.LineChartData, "Series Name").map(item => {
      return item["Series Name"];
    });

    let uniqueDataValue = _.uniqBy(this.LineChartData, "Rounded Value").map(item => {
      return item["Rounded Value"] == "NA" ? 0 : item["Rounded Value"];
    });

    if (this.ChartProperties.datalabelStyle === "lowSample") {
      this.showLowSample = true;
    } else {
      this.showLowSample = false;
    }

    let minValue = Math.min(...uniqueDataValue);
    let maxValue = Math.max(...uniqueDataValue);
    this.chartMinAxis = 0;
    this.chartMaxAxis = maxValue + maxValue * 0.05

    let idx = 0;
    let dataObject: any = {};
    let consumableData: any = [];
    let consumableData1: any = [];

    metrics.forEach((metric) => {
      //listOfCategory.forEach((catName: any) => {
      dataObject = {};
      consumableData = [];
      consumableData1 = [];
      let metricData = this.LineChartData.filter(res => res["Series Name"] == metric);

      let sortedMetricData = metricData.sort((a, b) => {
        // Get the index of category name in the list of categories
        let indexA = listOfCategory.indexOf(a["Category Name"]);
        let indexB = listOfCategory.indexOf(b["Category Name"]);

        // Compare the indices
        return indexA - indexB;
      });
      for (var i = 0; i < listOfCategory.length; i++) {
        let FilteredData = sortedMetricData.filter(x => x['Category Name'] == listOfCategory[i])[0];

        // FilteredData.forEach(item => {
        dataObject.name = metric;

        consumableData.push({
          y: FilteredData["Rounded Value"] == "NA" ? 0.000001 : (FilteredData["Sample Size"] != 0 && FilteredData["Sample Size"] < this.InsufficientBaseSample) ? 0.000001 : FilteredData["Rounded Value"]
        })
        consumableData1.push(FilteredData);
        // })
      }
      dataObject.color = this.colorCodeList[idx++];
      dataObject.data = consumableData;
      dataObject.data1 = consumableData1;
      dataObject.pointPlacement = "on";
      this.seriesData.push(dataObject);
      //})
    })
  }

  getDataValue(sampleSize: any, value: any) {

    if (sampleSize >= this.samplesizeConstantValue)
      return value;
    return 0;
  }

  userselectedproperties() {

    let linechartData = {
      data: this.LineChartData,
      Properties: [this.ChartProperties]
    }
    return JSON.stringify(linechartData).toString();
  }

  ngOnDestroy(): void {
    // Unsubscribe when the component is destroyed to prevent memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
