import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/app/environments/environment';
import { fomData } from '../models/chatbotexpand.model';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  apiBaseUrl = environment.baseApiUrl;
  constructor(private http:HttpClient) { }
  private apiUrl="https://localhost:44376/api/ChatBot/GetQuery";
  private variableTabDataSubject = new BehaviorSubject<any>('');
  variableTabData$ = this.variableTabDataSubject.asObservable();
  

getOutputData(
  inputQuery: string, 
  fomDatas: fomData,
  
): Observable<any> {
  const url = this.apiBaseUrl + 'api/ChatBot/GetQuery';

  // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
  const body = {
     input_Query: inputQuery,
    fomDatas: fomDatas
  };

  return this.http.post<any>(url, body).pipe(
    catchError((error: HttpErrorResponse) => {
      console.error('Request error:', error);
      return throwError(error);
    })
  );
}

}
