import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { LoaderService } from 'src/app/common/services/loader.service';
import { SharedPopupService } from 'src/app/common/services/shared-popup.service';
import {
  IsTriggered,
  UploadSubmitObject,
  DefaultPlayButton,
} from 'src/app/common/models/upload.model';
import { CrosstabService } from 'src/app/common/services/crosstab.service';
import { Subscription } from 'rxjs';
import { UploadService } from 'src/app/common/services/upload.service';
import { AlertMessageModel } from 'src/app/common/models/common.model';
import { AlertMessageService } from 'src/app/common/services/alert-message.service';
import { forEach } from 'lodash';
@Component({
  selector: 'app-restatement',
  templateUrl: './restatement.component.html',
  styleUrls: ['./restatement.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestatementComponent
  implements OnChanges, OnInit, OnDestroy, AfterViewInit
{
  @Input() UploadInputObject = [];
  @Input() statusdataResobj = [];
  @Input() RestatementNotAllowed = false;
  isfinalLoadTrigger: boolean = false;

  isErrorpopupshown: boolean = false;

  FileFormat: any;
  UploadObject: any;
  UserClientStudyData: any;
  Is_Submit: boolean = true;
  totalfiles: any;
  file: any;
  IsUploadFileStatus: any = [];
  intervalId: any;
  expectItemtoRemoveOrRevert : any
  confirmationType : String = ""
  confirmationMessage : string = ""
  isShowDialog : boolean = false;
  StatusUpdate = {
    section1: true,
    section2: false,
  };
  // public SubmitObject: UploadSubmitObject = {
  //   Client: '',
  //   study: '',
  //   filetype: '',
  // };
  isTriggered: IsTriggered = {
    section1: false,
    section2: false,
    finalDataLoad: false,
  };
  indexCount: number = 0;
  isFilenotAvailable: boolean = true;
  isRevertBackupButtonClicked : boolean = false;
  isButtonDisableWhenClickOnTrigger = true;
  items = [1, 2, 3, 4, 5, 6, 7, 8];
  dropdownSelection = {
    client_name: '',
    study: '',
    dataSource: '',

    fileType: '',
    JobType: '',
    FolderName: '',
  };

  isRestatementSelected : boolean = true;
  isBackUpAreaSelected : boolean = false;
  $unSubscribeSubmitData1!: Subscription;
  $unSubcribeSubmitClickEvent!: Subscription;
  @Input() userManagementShown: boolean = false;
  @Input() excelExport: boolean = false;
  @Input() helpGuide: boolean = false;
  @Input() pptExport: boolean = false;
  @Input() repository: boolean = false;
  @Input() exportDownloads: boolean = false;
  @ViewChild('dataSanityRawDataConfig_Restatement')
  dataSanityRawDataConfig_Restatement: ElementRef | undefined;
  @ViewChild('ChooseFile') sanityInputFile: ElementRef | undefined;

  //@Input() landingdata: any;
  selectedmoduleCell: number | null = null;
  statusdata: any;
  backUpStatusData: any;
  inputFileName = {
    sanityInput: 'Choose File from system',
    finalLoadInput: 'Choose File from system',
  };
  BindFileName = {
    sanityInput: '',
    finalLoadInput: '',
  };

  defaultPlayButton: DefaultPlayButton = {
    section1: true,
    section2: true,
  };

  intervalTime = 5000;

  constructor(
    public SharedService: SharedPopupService,
    private Loader: LoaderService,
    private crossTabService: CrosstabService,
    private uploadService: UploadService,
    private renderer: Renderer2,
    private _alertService: AlertMessageService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['statusdataResobj'] == undefined ||
      JSON.stringify(changes['statusdataResobj'].previousValue) !=
        JSON.stringify(changes['statusdataResobj'].currentValue)
    ) {
      clearInterval(this.intervalId);
      if (!this.RestatementNotAllowed) {
        this.getInitialData();
        if (this.UploadObject != undefined && this.UploadObject.length > 0) {
          this.StatusRequest();
        }
      }
    }
  }

  async StatusRequest() {
    let selectedsubmitdata = JSON.parse(JSON.stringify(this.UploadObject[0]));
    this.intervalId = setInterval(async () => {
      const response = await this.starthttprequest(selectedsubmitdata);
      if (this.UploadObject.length > 0) {
        if (this.isProcessRunning()) {
        } else {
          clearInterval(this.intervalId);
          this.Loader.hideLoader();
        }
      }
    }, this.intervalTime);
  }

  isProcessRunning() {
    //let sections: any = ['section1', 'section2'];
    let returnData: boolean = true;
    // forEach(sections, (s: any) => {
    //   let status = this.getSectionStatus(s, false).toLowerCase();
    //   if (status === 'in-progress') {
    //     returnData = true;
    //     return;
    //   }
    // });

    return returnData;
  }

  getInitialData() {
    this.UploadObject = this.UploadInputObject;
    this.statusdata = this.statusdataResobj;

    this.selectedmoduleCell = 1;
    this.BindFileName.sanityInput = this.inputFileName.sanityInput;
    this.BindFileName.finalLoadInput = this.inputFileName.finalLoadInput;
  }

  ngOnInit() {
    this.isRestatementSelected = true;
    if (!this.RestatementNotAllowed) {
      this.$unSubcribeSubmitClickEvent =
        this.uploadService.$submitClickEvent.subscribe((v: any) => {
          this.Is_Submit = v;
        });
    } else {
      this.clearSubscription();
    }
  }
  ngAfterViewInit() {
    if (this.Is_Submit) {
      this.renderer.setStyle(
        this.dataSanityRawDataConfig_Restatement?.nativeElement,
        'opacity',
        '100%'
      );
    }
  }

  getSectionActive(section: any) {
    if (this.statusdata != undefined) {
      if (this.statusdata.length > 0) {
        switch (section) {
          case 'section1':
            if (this.statusdata.length > 0) {
              if (this.statusdata[0].DEV_flag) {
                this.StatusUpdate.section1 = true;
                if (
                  this.totalfiles == undefined ||
                  this.totalfiles.length == 0
                ) {
                  this.BindFileName.sanityInput =
                    this.inputFileName.sanityInput;
                }
                return true;
              } else {
                this.StatusUpdate.section1 = false;
                // this.renderer.setStyle(
                //   this.dataSanityRawDataConfig?.nativeElement,
                //   'opacity',
                //   '0.5'
                // );
                return false;
              }
            } else {
              return true;
            }

          case 'section2':
            if (this.statusdata.length > 0) {
              if (this.statusdata[0].UAT_flag) {
                this.StatusUpdate.section2 = true;
                if (
                  this.totalfiles == undefined ||
                  this.totalfiles.length == 0
                ) {
                  this.BindFileName.finalLoadInput =
                    this.inputFileName.finalLoadInput;
                }
                return true;
              } else {
                this.StatusUpdate.section2 = false;
                // this.renderer.setStyle(
                //   this.dataSanityRawDataConfig?.nativeElement,
                //   'opacity',
                //   '0.5'
                // );
                return false;
              }
            } else {
              return true;
            }

          // Add more cases as needed
          default:
            if (this.statusdata[0].DEV_flag) {
              return true;
            } else {
              return false;
            }
        }
      } else {
        return this.enableSection(section);
      }
    }
    // else{
    //   if(section === "section1"){
    //     this.StatusUpdate.section1= true;
    //     return true;
    //   }
    // }
    return false;
  }
  enableSection(section: any) {
    switch (section) {
      case 'section1':
        this.StatusUpdate.section1 = true;
        return true;
      case 'section2':
        return false;
      // Add more cases as needed
      default:
        return false;
    }
  }

  DownloadUsageLogReport() {
    this.Loader.showLoader();
    let clientName = this.UploadObject[0].client_name;
    let studyName = this.UploadObject[0].study;
    let fileType = this.UploadObject[0].fileType;
    let dataSource = this.UploadObject[0].dataSource;
    let dataProcess = this.UploadObject[0].dataProces;
    this.uploadService
      .ExcelExportForUsageLog(
        clientName,
        studyName,

        dataSource,
        dataProcess,
        fileType
      )
      .subscribe(
        (excelBlob: Blob) => {
          let fileName =
            clientName +
            '_' +
            studyName +
            '_' +
            dataSource +
            '_' +
            dataProcess +
            '_' +
            fileType +
            '_Usage Log.xlsx';
          // Trigger file download
          this.downloadFile(excelBlob, fileName);
          this.Loader.hideLoader();
        },
        (error) => {
          console.error('Error exporting to Excel', error);
          this.errorPopup('Something went wrong, please try again');
          this.Loader.hideLoader();
        }
      );
    // this.uploadService.ExcelExportForUsageLog(clientName, studyName, fileType).subscribe((exportFile) => {
    //   this.Loader.hideLoader();
    //   const blob = new Blob([exportFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //   const Url = window.URL.createObjectURL(blob);
    //   const anchor = document.createElement('a');
    //   anchor.download = clientName+"_"+studyName+'_Usage Log.xlsx';
    //   anchor.href = Url;
    //   anchor.click();
    // }, (fileDownloadError:any) => {
    //   this.Loader.hideLoader();
    //   this.errorPopup(fileDownloadError);
    //   this.isErrorpopupshown = true;
    // });
  }

  private downloadFile(data: Blob, filename: string) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    // Trigger download
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  onToggleClick(iconName:string){
    if(iconName.toLowerCase() == "restatement"){
      this.isRestatementSelected = true;
      this.isBackUpAreaSelected = false;
    }else if(iconName.toLowerCase() == "backup"){
      this.isRestatementSelected = false;
      this.isBackUpAreaSelected = true;
      
    }
  }
  getSectionStatus(section: string, IsIconChange: boolean): any {
    if (this.statusdata != undefined) {
      switch (section) {
        case 'section1':
          return this.statusdata.length > 0 &&
            this.statusdata[0].is_status != null
            ? IsIconChange
              ? this.IsIconChange(this.statusdata[0].is_status)
              : this.statusdata[0].is_status
            : this.IsIconChange('not started');

        case 'section2':
          return this.statusdata.length > 0 &&
            this.statusdata[0].UAT_block_status != null
            ? IsIconChange
              ? this.IsIconChange(this.statusdata[0].UAT_block_status)
              : this.statusdata[0].UAT_block_status
            : this.IsIconChange('not started');

        // Add more cases as needed
        case 'finalDataLoad':
          return this.statusdata.length > 0 &&
            this.statusdata[0].UAT_flag != null
            ? this.statusdata[0].UAT_flag
              ? 'completed'
              : 'failed'
            : 'not started';
        default:
          return 'Start';
      }
    }
    return '';
  }
  getStatusColor(section: string): string {
    // Map status to corresponding CSS class
    let status = 'failed';
    try {
      status = this.getSectionStatus(section, false).toLowerCase();
    } catch (err) {}
    switch (status) {
      case 'in-progress':
        return '#FF971E';
      case 'failed':
        this.clearFileInput(section);
        return '#DB4F45';
      case 'completed':
        this.clearFileInput(section);
        return '#3BC47C';
      default:
        return '';
    }
  }
  getStatusIcon(section: string): string {
    let status: any;

    if (this.getSectionActive(section)) {
      status = this.getSectionStatus(section, true).toLowerCase();
    } else {
      status = this.getSectionStatus(section, false).toLowerCase();
    }
    switch (status) {
      case 'in-progress':
        return 'schedule';
      case 'failed':
        return 'cancel';
      case 'completed':
        return 'check_circle';
      default:
        return 'play_circle';
    }
  }
  IsIconChange(sectionStatus: string) {
    if (sectionStatus == 'in-progress') {
      return sectionStatus;
    } else if (sectionStatus == 'failed') {
      return sectionStatus;
    } else if (sectionStatus == 'completed') {
      return sectionStatus;
    } else {
      return 'start';
    }
  }
  getUploadDisableStatus(section: string) {
    let status = this.getSectionStatus(section, false).toLowerCase();

    if (section == 'section1' && status == 'completed') {
      this.isTriggered[section] = false;
      return false;
    }

    if (
      section == 'section2' &&
      (status == 'completed' || status == 'failed' || status == 'start')
    ) {
      this.isTriggered[section] = false;
      return false;
    }

    switch (status) {
      case 'in-progress':
        this.isTriggered[section] = true;
        return true;
      case 'failed':
        this.isTriggered[section] = false;
        return false;
      case 'completed':
        this.isTriggered[section] = false;
        return true;
      default:
        this.isTriggered[section] = false;
        return false;
    }
  }

  clearFileInput(section: string): void {
    if (this.sanityInputFile) {
      this.sanityInputFile.nativeElement.value = '';
    }
  }

  starthttprequest(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.$unSubscribeSubmitData1 = this.uploadService
        .datauploadstatusandgriddata(data)
        .subscribe(
          (res: any) => {
            if (res.HasValidData) {
              let jsonData = res;
              let finalData = null
              this.Loader.hideLoader();
              
              if (jsonData.Data.length > 0) {
                finalData = JSON.parse(jsonData.Data);
                this.statusdata = finalData[0];
                this.statusdata.sort((a: any, b: any) => b.sort_id - a.sort_id);
                this.backUpStatusData = finalData[1];
                this.backUpStatusData.sort((a: any, b: any) => a.sort_id - b.sort_id);
              } else {
                this.statusdata = [];
                this.backUpStatusData = [];
              }
              
              resolve(res); // Resolve the promise with the response
            } else {
              this.Loader.hideLoader();
              if (!this.isErrorpopupshown) {
                this.errorPopup(res.ExceptionMessage);
                this.isErrorpopupshown = true;
              }
              reject(new Error(res.ExceptionMessage)); // Reject the promise with the error message
            }
          },
          (error) => {
            this.Loader.hideLoader();
            reject(error); // Reject the promise with the error
          }
        );
    });
  }

  showMessagePopup(message:string="Are you show", headerTxt:string = "Information") : void {
    const alertObj: AlertMessageModel = {
      header: headerTxt,
      message: message,
      show: true,
    };
    this._alertService.showAlert(alertObj);
  }

  errorPopup(message: string, headerTxt: string = 'Error'): void {
    const alertObj: AlertMessageModel = {
      header: headerTxt,
      message: message,
      show: true,
    };
    this._alertService.showAlert(alertObj);
  }

  checkFileName(event: any, section: string) {
    let selectedSubmitData = JSON.parse(JSON.stringify(this.UploadObject[0]));
    let clientName: string = selectedSubmitData.client_name.toLowerCase();
    clientName = clientName.replace(/\s+/g, '-');
    switch (section) {
      case 'section1':
        return event.target.files[0].name == `Restatement.zip`;
      case 'section2':
        return event.target.files[0].name == `Config_restatementUAT.xlsx`;
    }

    return false;
  }
  onFileInput(event: any, section: string) {
    if (section != 'section1' && section != 'section4') {
      if (
        event.target.files[0] &&
        event.target.files[0].type === 'application/x-zip-compressed'
      ) {
        this.errorPopup('ZIP files are not allowed.');
        event.target.value = ''; // Clear the file input
      }
    }

    if (!this.checkFileName(event, section)) {
      let validFileName: string = '';
      switch (section) {
        case 'section1':
          validFileName = `Restatement.zip`;
          break;
        case 'section2':
          validFileName = `Config_restatementUAT.xlsx`;
          break;
      }

      this.errorPopup(
        'Please follow FileName Nomenclature (' + validFileName + ')'
      );
      event.target.value = '';
      return;
    }

    this.UpdateFileName(event, section);
    this.GetFileList(event.target.files);
    this.defaultPlayButton[section] = true;
    if (this.totalfiles.length > 0) {
      if (section == 'section2') {
        this.isfinalLoadTrigger = false;
      }
      this.isFilenotAvailable = false;
      this.isButtonDisableWhenClickOnTrigger = false;
    }
  }

  GetFileList(files: any) {
    this.totalfiles = [];
    for (let index = 0; index < files.length; index++) {
      this.file = files[index];
      this.totalfiles.push(this.file);
    }
  }

  UpdateFileName(event: any, section: string) {
    switch (section) {
      case 'section1':
        this.BindFileName.sanityInput = event.target.files[0].name;
        break;
      case 'section2':
        this.BindFileName.finalLoadInput = event.target.files[0].name;
        break;
      default:
        this.BindFileName.finalLoadInput = event.target.files[0].name;
        break;
    }
  }


  DeleteOrRevert(){
    this.dropdownSelection.client_name = this.UploadObject[0].client_name;
    this.dropdownSelection.study = this.UploadObject[0].study;
    this.dropdownSelection.fileType = this.UploadObject[0].fileType;
    this.dropdownSelection.dataSource = this.UploadObject[0].dataSource;
    let emailData: any = this.uploadService.getStoredEmailListData();
    this.isRevertBackupButtonClicked = true;
    this.uploadService.deleteOrRevertData(this.expectItemtoRemoveOrRevert, this.dropdownSelection, emailData).subscribe(
      (res: any) => {
        this.isRevertBackupButtonClicked = false;
        if( this.confirmationType.toLowerCase() == "delete"){
          this.errorPopup("Success", "Success");
        }
      },
      (error: any) => {
        this.isRevertBackupButtonClicked = false;
        this.errorPopup("Error : "+error,"Error")// Reject the promise with the error
      }
    );;
  }

  onConfirmation(operation: number) {
    if (operation === 1) {
      // If the action is confirmed, execute the corresponding action based on the confirmation type
      switch (this.confirmationType.toLowerCase()) {
        case "delete":
          this.DeleteOrRevert();
          // Execute the insertUsers function for submitting users
          break;
        case "revert":
          this.DeleteOrRevert();
          // Execute the deleteUsers function for deleting users
          break;
        default:
          // Hide the dialog if no specific action is associated with the confirmation type
          this.isShowDialog = false;
          break;
      }
      // Hide the dialog after executing the action
      this.isShowDialog = false;
    } else {
      // User canceled the action, hide the dialog
      this.isShowDialog = false;
    }
  }

  showConfirmationDialog(message: string) {
    // Set the confirmation message for the dialog
    this.confirmationMessage = message;
    // Set the flag to show the confirmation dialog
    this.isShowDialog = true;
  }

  OnDeleteClick(item:any){
    if(this.isRevertBackupButtonClicked == true){
      this.errorPopup("Execution in-progress, Please wait for sometime.","Processing");
      return;
    }
    this.expectItemtoRemoveOrRevert = item;
    this.confirmationType = "Delete";
    this.dropdownSelection.JobType = "remove_backup";
    // Prepare the confirmation message
    let message = "Backup data will be deleted permanently and cannot be recovered , Confirm?";
    // Show the confirmation dialog with the prepared message
    this.showConfirmationDialog(message);
  }

  OnRevertClick(item:any){
    if(this.isRevertBackupButtonClicked == true){
      this.errorPopup("Execution in-progress, Please wait for sometime.","Processing");
      return;
    }
    this.expectItemtoRemoveOrRevert = item;
    this.confirmationType = "Revert";
    this.dropdownSelection.JobType = "revert_backup"
    // Prepare the confirmation message
    let message = "Backup data will be reverted to the previous state , Confirm?";
    this.showConfirmationDialog(message);
  }

  onTrigger(section: string, folderName: string) {
    this.isErrorpopupshown = false;
    this.isTriggered[section] = true;
    this.defaultPlayButton[section] = false;

    this.dropdownSelection.client_name = this.UploadObject[0].client_name;
    this.dropdownSelection.study = this.UploadObject[0].study;
    //  this.dropdownSelection.dataProces = '';
    this.dropdownSelection.fileType = this.UploadObject[0].fileType;
    this.dropdownSelection.dataSource = this.UploadObject[0].dataSource;

    if (this.totalfiles !== undefined && this.totalfiles.length > 0) {
      this.dropdownSelection.JobType = this.getJobNameBasedOnSection(section);
      this.dropdownSelection.FolderName = folderName;
      this.Loader.showLoader();
      this.isButtonDisableWhenClickOnTrigger = true;

      if (section == 'section2') {
        this.isfinalLoadTrigger = true;
      } else {
        this.isFilenotAvailable = true;
      }

      let emailData: any = this.uploadService.getStoredEmailListData();

      this.uploadService
        .fileUpload(
          this.dropdownSelection,
          this.totalfiles,
          folderName,
          emailData
        )
        .subscribe(
          async (result: any) => {
            // Validate the response
            if (result.HasValidData) {
              if (this.UploadObject.length > 0) {
                this.totalfiles = [];
                let selectedsubmitdata = JSON.parse(
                  JSON.stringify(this.UploadObject[0])
                );
                clearInterval(this.intervalId);
                this.intervalId = setInterval(async () => {
                  try {
                    //this.Loader.showLoader();
                    const response = await this.starthttprequest(
                      selectedsubmitdata
                    );
                    //this.Loader.hideLoader();
                    // Process the response
                    if (response.HasValidData) {
                      if (response.Data && response.Data.length > 0) {
                        // this.totalfiles.push(...response.Data); // Example processing, adjust as needed
                        // this.isFilenotAvailable = false;
                        if (this.totalfiles.length == 0) {
                          if (section == 'section2') {
                            this.isfinalLoadTrigger = true;
                          } else {
                            this.isFilenotAvailable = true;
                          }
                          this.isButtonDisableWhenClickOnTrigger = false;
                        }
                      } else {
                        if (this.totalfiles.length == 0) {
                          if (section == 'section2') {
                            this.isfinalLoadTrigger = true;
                          } else {
                            this.isFilenotAvailable = true;
                          }
                          this.isButtonDisableWhenClickOnTrigger = false;
                        }
                      }
                    }
                    // Example stopping condition
                    // if (this.totalfiles.length >= 10) { // Adjust this condition as needed
                    //     clearInterval(intervalId);
                    // }
                  } catch (error) {
                    console.error('Error in interval:', error);
                    clearInterval(this.intervalId); // Stop interval on error
                  }
                  if (this.isProcessRunning()) {
                  } else {
                    clearInterval(this.intervalId);
                    this.Loader.hideLoader();
                  }
                }, this.intervalTime);
              } else {
                this.isFilenotAvailable = true;
                this.isButtonDisableWhenClickOnTrigger = false;
                this.isfinalLoadTrigger = true;
              }
            } else {
              this.isButtonDisableWhenClickOnTrigger = false;
            }
            // Hide the loader
            // this.Loader.hideLoader();
          },
          (error) => {
            this.isTriggered[section] = false;
            this.Loader.hideLoader();
            this.errorPopup('Error : ' + error.message);
          }
        );
    } else {
      this.errorPopup('Please upload files');
    }
  }
  getJobNameBasedOnSection(section: string) {
    switch (section) {
      case 'section1':
        return 'restatement';
      case 'section2':
        return 'restatement_uat';
      default:
        return 'restatement_uat';
    }
  }

  ngOnDestroy() {
    this.clearSubscription();
    clearInterval(this.intervalId);
  }

  clearSubscription() {
    if (this.$unSubscribeSubmitData1) {
      this.$unSubscribeSubmitData1.unsubscribe();
      this.$unSubcribeSubmitClickEvent.unsubscribe();
    }
  }

  getMarginForScroll() {
    let tblRef: any = document.getElementById(
      'grid_table_container_restatement'
    );

    if (tblRef != null) {
      let theadRef = tblRef.getElementsByTagName('thead');
      if (theadRef != null && theadRef.length > 0) {
        return `${theadRef[0].clientHeight}px 0 0 0px`;
      }
    }

    return 0;
  }

  OnDownloadFile(section: string, folderName: string) {
    this.Loader.showLoader();
    let multifolder: any;

    multifolder = folderName;

    this.dropdownSelection.client_name = this.UploadObject[0].client_name;
    this.dropdownSelection.study = this.UploadObject[0].study;
    //  this.dropdownSelection.dataProces = '';
    this.dropdownSelection.fileType = this.UploadObject[0].fileType;
    this.dropdownSelection.dataSource = this.UploadObject[0].dataSource;

    this.uploadService
      .downloadFile(this.dropdownSelection, folderName, multifolder)
      .subscribe((result: any) => {
        //validate the response
        this.downloadPPTFile(result, multifolder);

        // hide the loader
        this.Loader.hideLoader();
      });
  }

  private downloadPPTFile(data: any, filename: string) {
    const filesJson = JSON.parse(data.Data);

    Object.keys(filesJson).forEach((fileName) => {
      const base64Content = filesJson[fileName].Base64Content;
      // Decode base64 content
      const binaryContent = atob(base64Content);
      // Convert binary content to ArrayBuffer
      const arrayBuffer = new ArrayBuffer(binaryContent.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryContent.length; i++) {
        uint8Array[i] = binaryContent.charCodeAt(i);
      }
      // Create blob from ArrayBuffer
      const blob = new Blob([arrayBuffer], {
        type: filesJson[fileName].FileExtension,
      });

      // Create link element for download
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = filesJson[fileName].FileName;

      // Trigger download
      document.body.appendChild(a);
      a.click();
      // Cleanup
      document.body.removeChild(a);
      URL.revokeObjectURL(a.href);
    });
  }
}
