import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {  AlertMessageModel, results } from '../../models/common.model';
import { ExportDownloadServices } from '../../services/exports-downloads.service';
import { LoaderService } from '../../services/loader.service';
import { ExcelExportReport, ExcelExportReportInputs, ExcelExportReportList } from '../../models/excel-export-report.model';
import { AlertMessageService } from '../../services/alert-message.service';

@Component({
  selector: 'app-export-downloads-popup',
  templateUrl: './export-downloads-popup.component.html',
  styleUrls: ['./export-downloads-popup.component.css']
})
export class ExportDownloadsPopupComponent implements OnInit {
  headerTitle:string="Downloads";
  largeExportsHeader:string="Large Exports";
  downloadReports:string="Downloaded Reports";
  buttonText:string="Ok"
  @Input() showExportsDownloads:boolean=false;
  @Input() ReportInput!:ExcelExportReportInputs;
  isLargeExportShow:boolean=true;
  isDownloadExportShow:boolean=false;
  exportDownloadsItems:ExcelExportReport[]=[];
  customReportsDownloadsItems:ExcelExportReport[]=[];
  tableStatus:string="Loading....."
  downloadTableStatus:string="Loading....."
  selectionSummary:string="";
  showSummary:boolean=false;
  @Output() closePopup: EventEmitter<boolean> = new EventEmitter();
  constructor(private _loaderService: LoaderService,
    private _exportDownloadService:ExportDownloadServices,
    private _alertService:AlertMessageService) { 
    // assign the client and study details from router extra state
  }
  /**
 * Fetches all reports and displays them.
 */
ngOnInit(): void {
  // Call getAllReports() when the component initializes to fetch all reports
  this.getAllReports();
}
/**
 * Closes the popup by emitting an event.
 */
close(): void {
  // Emit an event to notify the parent component to close the popup
  this.closePopup.emit();
}

  // Function to fetch all reports
  getAllReports(){ 
    this._loaderService.showLoader(); // Show loader indicating data loading
    // Subscribe to the getExcelReports() service method
    this._exportDownloadService.getExcelReports(this.ReportInput).subscribe((result: results) => {
      // Check if the result has valid data
      if (result.HasValidData) {
        // Parse the data from result and assign to exportDownloadsItems
        let listReports:ExcelExportReportList=JSON.parse(result.Data);
        if(listReports!=null){
          this.exportDownloadsItems=listReports.largeExportReportList;
          this.customReportsDownloadsItems=listReports.customReportsList;
          if(this.exportDownloadsItems==null || this.exportDownloadsItems.length==0){
            // Set table status message when there is no data available
            this.tableStatus="No Data Available"
          }
          if(this.customReportsDownloadsItems==null || this.customReportsDownloadsItems.length==0){
            // Set table status message when there is no data available
            this.downloadTableStatus="No Data Available"
          }
          
        }
      }
      // Hide loader in all cases, even if there's an error or no data
      this._loaderService.hideLoader();
    });
  }
// Function triggered when refresh button is clicked
  OnRefreshClick(){ 
    this.getAllReports(); // Call the function to fetch all reports
}

  // Function triggered when a report is completed
  OnCompletedClick(report: ExcelExportReport){ 
    this._loaderService.showLoader(); // Show loader indicating processing

    // Call the UpdateDownloadedStatus() service method to update the download status of the report
    this._exportDownloadService.UpdateDownloadedStatus(report).subscribe(
      // If the update is successful, receive the Excel file as a Blob
      (excelBlob: Blob) => {
        // Get the current date and time
        let datetime = this._exportDownloadService.getCurrentDateTime();
        let fileName="";
        if(report.report_type=="Crosstab")
         fileName =`KantarXTab_${report.client_name}_${report.study_name}_${datetime}.xlsx`;
        else if(report.report_type=="CustomReport")
          fileName =`${report.selection_summary}_${datetime}.zip`;
        else
        fileName =`${report.selection_summary}.xlsx`;
        // Trigger the download of the Excel file with a filename based on the report ID and current datetime
        this._exportDownloadService.downloadFile(excelBlob, fileName);
        if(report.report_type=="CustomReport"){
        // Find the index of the completed report in the exportDownloadsItems array
        let index = this.customReportsDownloadsItems.findIndex((x: ExcelExportReport) => x.report_id == report.report_id);
        
        // If the index is found, remove the completed report from the exportDownloadsItems array
        if (index > -1)
          this.customReportsDownloadsItems.splice(index, 1);
      }
      else{
        // Find the index of the completed report in the exportDownloadsItems array
        let index = this.exportDownloadsItems.findIndex((x: ExcelExportReport) => x.report_id == report.report_id);
        
        // If the index is found, remove the completed report from the exportDownloadsItems array
        if (index > -1)
          this.exportDownloadsItems.splice(index, 1);
      }
        
        // Hide the loader after processing is complete
        this._loaderService.hideLoader();
      },
      // If there's an error during the download process, handle it
      (error) => {
        // Log and display an error message if there is an issue with the download
        console.error('Error exporting to Excel', error);
        this.AlertPopup('Something went wrong, please try again');
        // Hide the loader after handling the error
        this._loaderService.hideLoader();
      }
    );
  }

/**
 * Displays an alert message with the specified message and header text.
 * @param message - The text of the alert message.
 * @param headerTxt - Optional. The header text of the alert message. Default is "Error".
 */
AlertPopup(message: string, headerTxt: string = "Error"): void {
  // Create an alert object with the specified header and message, and show it using the alert service
  const alertObj: AlertMessageModel = { header: headerTxt, message: message, show: true };
  this._alertService.showAlert(alertObj);
}
mesage(){
  this._exportDownloadService.getmessage().subscribe((result: results) => {
    // Check if the result has valid data
    if (result.HasValidData) {
      // Parse the data from result and assign to exportDownloadsItems
      
    }
  });
}
OnClickInfoIcon(summary:string){
  this.showSummary=true;
this.selectionSummary=summary;
}
OnBackClick(){
  this.showSummary=false;
this.selectionSummary="";
}
onToggleLargeExport(){
this.isLargeExportShow=!this.isLargeExportShow;
if(this.isLargeExportShow){
  this.isDownloadExportShow=false;
}
}
onToggleDownloadExport(){
  this.isDownloadExportShow=!this.isDownloadExportShow;
  if(this.isDownloadExportShow){
    this.isLargeExportShow=false;
  }
  }
 
}
