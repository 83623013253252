import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';

import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StackchartServiceService } from './verticalStackChart.service';
import { CrosstabService } from 'src/app/common/services/crosstab.service';
import { COMMON_CONSTANTS } from '../../constants/common-constants';
import Stock from 'highcharts/modules/stock';

Stock(Highcharts);

@Component({
  selector: 'app-stackChart',
  templateUrl: './verticalStackChart.component.html',
  styleUrls: ['./verticalStackChart.component.css'],
})

export class Stackedbarchart1Component implements OnInit {
  LowBaseSample = COMMON_CONSTANTS.LowBase_Sample;
  InsufficientBaseSample = COMMON_CONSTANTS.InsufficientBase_Sample;
  @Input() insufficientBase: string = '';
  @Input() LowBase: string = '';
  DefaultConfidenceLevel: string = '95%';
  Sig_LowerLimt = COMMON_CONSTANTS.SigLowerLimit_95;
  Sig_UpperLimt = COMMON_CONSTANTS.SigUpperLimit_95;
  seriesData: any[] = [];
  StackChartData: any[] = [];
  sampleSizeValues: any;
  chart: any;
  custom: any[] = [];
  ChartProperties: any;
  event: any;
  changeValue: any;
  significanceValue: any;
  significanceValue2: any;
  data: any;
  chartCategories: any;

  properties = [
    {
      "showLegend": true,
      "gridlines": false,
      "shadow": false,
      "legendPosition": "bottom",
      "stacking": 0,
      "darkTheme": false,
      "axisLabelOrientation": "horizontal",
      "chartTitlePosition": "center",
      "showDataLabel": true,
      "dataLabelPosition": "center",
      "dataLabelPositionPPT": "center",
      "datalabelOrientation": 0,
      "showRemaining": false,
      "plotBands": false,
      "axisImage": false,
      "lowSample": false,
      "gradientBarColor": false,
      "datalabelStyle": "significance2",
      "showNegative": false,
      "dataLabelColor": "black",
      "allowOverlap": true,
      "datalabelFormat": "percentage",
      "showPattern": false,
      "toolTipStyle": "default"
    }
  ]

  ngOnInit(): void {

    this.LowBaseSample = this.LowBase == "" ? 0 : Number(this.LowBase);
    this.InsufficientBaseSample = this.insufficientBase == "" ? 0 : Number(this.insufficientBase);


    //this.getData().subscribe(
    //  (jsonData: any) => {
    //    this.data = jsonData[0];
    //    this.StackChartData = jsonData[0]['data'];
    //    this.ChartProperties = this.properties[0];
    //    this.renderChart2('chart-container', this.data['data'], this.ChartProperties);
    //  },
    //  (error: any) => {
    //    console.error('Error fetching data:', error);
    //  }
    //);

    this.dataSharingService.StackChartDataSubject$.subscribe(({ data, listOfCategory, Significance, ConfidenceLevel, numberType, chartType }) => {
      this.ChartProperties = this.properties[0];
      if (Significance == "None") {
        this.ChartProperties.datalabelStyle = '';
      }
      else {
        this.ChartProperties.datalabelStyle = 'change';
        this.DefaultConfidenceLevel = ConfidenceLevel;
      }
      if (numberType == "mean") {
        this.ChartProperties.datalabelFormat = '';
      }
      this.data = data;
      this.StackChartData = data.Data;
      this.renderChart2('stack-chart-container', data.Data, this.ChartProperties, chartType, listOfCategory);

    });
  }

  constructor(private http: HttpClient, private stackService: StackchartServiceService, private dataSharingService: CrosstabService) { }

  getData(): Observable<any> {
    return this.http.get<any>('assets/Data/StackData.json');
  }

  seriesObject: any = {};
  accessSeriesValues(islowSample: any, listOfCategory: any) {
    const seriesObjects: any = {};
    const categories: string[] = [];
    const signi: any[] = [];
    const signi2: any[] = [];
    const changes: any[] = [];
    const sampleSizeValues: any[] = [];
    let IB = this.InsufficientBaseSample;
    for (const catName of listOfCategory) {
      const data = this.StackChartData.filter(a => a["Category Name"] == catName);
      const uniqueKeys = ["Category Name", "Series Name"];
      const uniqueData = this.getUnique(data, uniqueKeys);

      for (const series of uniqueData) {
        const seriesName = series["Series Name"] || series.name;
        let numericValue = (series["Rounded Value"] == "NA" ? 0.000001:(parseFloat(series["Rounded Value"]) == null) ? 0.000001 : parseFloat(series["Sample size"]) < IB ? 0.000001 : parseFloat(series["Rounded Value"]));
        let label = (series.Label);
        const change = parseFloat(series["Change value vs PY"]);
        const significance = parseFloat(series["Significance"]);
        const category = series["Category Name"];
        const sampleSize = series["Sample size"];
        const significance2 = series["Significance1"];
        const color = series["color"];
        // Check if chartProperties.showNegative is false and series has negative values
        if (!this.ChartProperties.showNegative && numericValue < 0) {
          continue; // Skip this series
        }

        let finalvalue = numericValue;

        if (!seriesObjects[seriesName]) {
          seriesObjects[seriesName] = {
            name: seriesName,
            data: [],
            label: [],
            changeValue: [],
            significanceValue: [],
            visible: true,
            pointWidth: 40,
            samplesize: [],
            // color: color
          };
        }

        seriesObjects[seriesName].data.push(finalvalue);
        seriesObjects[seriesName].label.push(label);
        seriesObjects[seriesName].changeValue.push(change);
        seriesObjects[seriesName].significanceValue.push(significance);
        seriesObjects[seriesName].samplesize.push(sampleSize);
        // seriesObjects[seriesName].color.push(color);
        if (!categories.includes(category)) {
          categories.push(category);
        }

        signi2.push(significance2);
        sampleSizeValues.push(sampleSize);
        changes.push(change);
      }
    }
    this.changeValue = [];
    this.significanceValue = [];
    this.seriesData = Object.values(seriesObjects).map((item: any) => {
      return {
        name: item.name,
        data: item.data,
        label: item.label,
        color: item.color,
        changeValue: item.changeValue,
        visible: item.visible,
        significanceValue: item.significanceValue,
        pointWidth: item.pointWidth,
        showInlegend: item.showInLegend,
        samplesize: item.samplesize,
      };
    });

    // Reverse seriesData
    this.seriesData.reverse();

    // Iterate through reversed seriesData to construct changeValue array
    for (const item of this.seriesData) {
      this.changeValue.push(...item.changeValue);
      this.significanceValue.push(...item.significanceValue);
    }

    this.sampleSizeValues = sampleSizeValues;
    this.significanceValue2 = signi2;
    this.chartCategories = listOfCategory;
  }

  customization() {
    return this.stackService.customizationStack();
  }
getSigLowerLimit(){
  return this.DefaultConfidenceLevel == '95%'
                    ? COMMON_CONSTANTS.SigLowerLimit_95
                    : this.DefaultConfidenceLevel == '90%'
                      ? COMMON_CONSTANTS.SigLowerLimit_90
                      : this.DefaultConfidenceLevel == '98%'
                        ? COMMON_CONSTANTS.SigLowerLimit_98
                        : COMMON_CONSTANTS.SigLowerLimit_95;
}
getSigUpperLimit(){
  return this.DefaultConfidenceLevel == '95%'
  ? COMMON_CONSTANTS.SigUpperLimit_95
  : this.DefaultConfidenceLevel == '90%'
    ? COMMON_CONSTANTS.SigUpperLimit_90
    : this.DefaultConfidenceLevel == '98%'
      ? COMMON_CONSTANTS.SigUpperLimit_98
      : COMMON_CONSTANTS.SigUpperLimit_95;
}
  renderChart2(container2: any, seriesdata: any, properties: any, chartType: string, listOfCategory: any) {
    localStorage.setItem("properties", JSON.stringify(properties));
    this.accessSeriesValues(this.ChartProperties.datalabelStyle == 'lowSample', listOfCategory);
    let change = this.changeValue;
    let signi = this.significanceValue;
    let sampleSizeValues = this.sampleSizeValues;
    let signi2 = this.significanceValue2;
    let maxDataValue = 0;
    const sumArray = new Array<number>();
    let i = 0;

    if (this.ChartProperties.gradientBarColor) {
      this.seriesData.forEach((series, index) => {
        series.color = this.stackService.customColors1[index % this.stackService.customColors1.length]; // Assign colors from customColors1
      });
    }
    //color: this.properties.pattern ? this.getPattern() : this.properties.gradientColor ? this.barService.linearGradients[i++] : this.barService.color1[i++],
    else if (this.ChartProperties.pattern) {
      this.seriesData.forEach((series, index) => {
        series.color = this.stackService.PatterncustomColors[index % this.stackService.PatterncustomColors.length]; // Assign colors in a cyclical manner
      });
    }
    else {
      let indexData = this.seriesData.length - 1;
      this.seriesData.forEach((series, index) => {
        series.color = this.stackService.customColors[indexData];
        indexData--;// Assign colors from customColors2
      });
    }

    // Initialize sumArray with zeros
    this.seriesData[0].data.forEach(() => sumArray.push(0));
    // Sum up values of each series at each index
    this.seriesData.forEach(series => {
      series.data.forEach((value: any, index: any) => {
        sumArray[index] += value;
      });
    });
    maxDataValue = Math.max(...sumArray);
    maxDataValue = maxDataValue + Math.ceil(maxDataValue * 0.01);

    const yAxisMaxValue = this.ChartProperties.stacking ? 100 : maxDataValue;

    const updatedCategories = this.stackService.updatedCategories;

    const updatedCategoryNames = updatedCategories.map(category => category.image);
    let plotBandsConfig: { color: string; from: number; to: number; }[] = [];
    const numPlotBands = this.chartCategories.length;

    if (this.ChartProperties.plotBands) {
      for (let i = 0; i < numPlotBands; i += 2) {
        const from = i + 0.5;
        const to = from + 1;
        plotBandsConfig.push({ color: this.ChartProperties.darkTheme ? this.stackService.plotBandsdark : this.stackService.plotBandsColor, from, to });
      }
    }
    let InsufficientBase = this.InsufficientBaseSample;
    let lowSample = this.LowBaseSample;

    let sigLowerValue=this.getSigLowerLimit();
    let sigUpperValue=this.getSigUpperLimit();
    this.chart = Highcharts.chart(container2, {
      chart: {
        type: chartType,
        backgroundColor: this.ChartProperties.darkTheme ? this.stackService.darkThemebg : this.stackService.lightThemebg,
        style: {
          color: this.ChartProperties.darkTheme ? this.stackService.darkThemeTextColor : this.stackService.lightThemeTextColor,
        }
      },
      exporting: {
        enabled: false
      },

      legend: this.stackService.customizeLegend(this.ChartProperties.showLegend, this.ChartProperties.legendPosition, this.ChartProperties),

      title: {
        text: '',
        align: this.ChartProperties.chartTitlePosition,
        style: {
          color: this.ChartProperties.darkTheme ? this.stackService.darkThemeTextColor : this.stackService.lightThemeTextColor,
          marginleft: '4%',
          fontSize: '24px',
          fontFamily: this.stackService.textfont
        }
      },

      accessibility: {
        point: {
          valueDescriptionFormat: '{index}. {point.name}, fat: {point.x}g, sugar: {point.y}g, {point.change}g.'
        }
      },
      xAxis: {

        visible: true,
        lineWidth: 1,
        categories: this.chartCategories,
        min: 0,
        max: this.chartCategories.length > 5 ? 4 : this.chartCategories.length - 1,
        scrollbar: {
          enabled: this.chartCategories.length > 5 ? true : false,
          height: 6, // Adjust the height of the scrollbar as needed
          barBackgroundColor: '#802ab7',
          barBorderRadius: 5,
          barBorderWidth: 0,
          barBorderColor: '#c9c9c9',
          buttonBackgroundColor: '#c9c9c9',
          buttonBorderWidth: 0,
          buttonArrowColor: '#c9c9c9',
          buttonBorderRadius: 0,
          rifleColor: 'none',
          trackBackgroundColor: '#c9c9c9',
          trackBorderWidth: 0,
          trackBorderColor: 'black',
          trackBorderRadius: 5
        },
        labels: {
          rotation: this.stackService.xAxisLabelOrientation(this.ChartProperties.axisLabelOrientation, this.ChartProperties),
          //useHTML: true,
          //style: {
          //  color: this.ChartProperties.darkTheme ? this.stackService.darkThemeTextColor : this.stackService.lightThemeTextColor,
          //},
          //formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
          //  if (properties.axisImage) {
          //    var index = this.axis.categories.indexOf(String(this.value));
          //    var category = updatedCategories[index];
          //    if (category && category.image) {
          //      return '<span style="display: inline-block; width: 20px; height: 20px; background: url(' + category.image + ') no-repeat center center; background-size: cover;"></span>';
          //    } else {
          //      return '<span>No Image</span>';
          //    }
          //  } else {
          //    return String(this.value);
          //  }
          //},
        },
        plotBands: plotBandsConfig,
      },
      yAxis: {
        visible: false,
        gridLineWidth: this.ChartProperties.gridlines,
        labels: {
          style: {
            color: this.ChartProperties.darkTheme ? this.stackService.darkThemeTextColor : this.stackService.lightThemeTextColor,
          },
        },
        plotLines: [{
          value: 0,
          width: 1
        }],
        max: yAxisMaxValue,
        title: {
          text: '',
          fontFamily: this.stackService.textfont
        },
        stackLabels: {
          enabled: false,
        },
      },
      credits: {
        enabled: false
      },

      tooltip: {
        enabled: true,
        borderWidth: 0,
        useHTML: true,
        shape: properties.toolTipStyle == "style1" ? 'square' : 'callout',
        shadow: false,
        backgroundColor: 'rgba(0,0,0,0)',

        formatter: function (this: any): string | false {
          if (this.series.name === 'Marker1') return false;

          const dotColor = this.series.color; // Get the series color
          let content =
            `<div style="background-color: #f5f5f5; padding: 5px; align-items: center; border: 1px solid ${dotColor}; ">` +
            ` ${this.series.name}` +
            `<div style="display: block; margin-top: 5px;"><strong>Value:</strong>` +
            `${this.series.userOptions.samplesize[this.point.x] < InsufficientBase ? 'I/S' :
              this.y == 0.000001 ? 'NA' :
                (this.series.userOptions.samplesize[this.point.x] < lowSample && this.series.userOptions.samplesize[this.point.x] > InsufficientBase) ?
                  (this.y + (properties.datalabelFormat == "percentage" ? '%' : '') + ('*')) :
                  (this.y + (properties.datalabelFormat == "percentage" ? '%' : ''))}` +
            `</div>`;

            if (properties.datalabelStyle == "change") {
              this.Sig_LowerLimt = sigLowerValue;
              this.Sig_UpperLimt = sigUpperValue;
              let changevspy = (isNaN(this.series.userOptions.changeValue[this.point.x])?"NA":('' + this.series.userOptions.changeValue[this.point.x] + (properties.datalabelFormat == "percentage" ? '%' : '') + ''));
                let sig = this.series.userOptions.significanceValue[this.point.x];
                if (changevspy == 'NA') {
                    content+=`<div style="display:flex;"><strong>Change:</strong><div>${changevspy} </div></div>`;
                }
                else if (sig < this.Sig_LowerLimt) {
                  content+=`<div style="display:flex;"><strong style="width: 35px;">Change:</strong><div style="width: 25px;">${changevspy} </div><div style="width: 10px;"><img style="width: 100%;height: 80%;" src="../../../../assets/images/common/arrows/red-down-arrow.png" alt="down-arrow"></div></div>`;
                } else if (sig > this.Sig_UpperLimt) {
                  content+=`<div style="display:flex;"><strong style="width: 35px;">Change:</strong><div style="width: 25px;">${changevspy} </div><div style="width: 10px;"><img style="width: 100%;height: 80%;" src="../../../../assets/images/common/arrows/green-up-arrow.png" alt="up-arrow"></div></div>`;
                }
                else {
                  content+=`<div style="display:flex;"><strong>Change:</strong><div>${changevspy} </div></div>`;
                }
            }
          return content + '</div>';
        },

      },

      plotOptions: {
        column: {
          stacking: this.ChartProperties.stacking ? 'percent' : 'normal',
          shadow: this.ChartProperties.shadow ? {
            color: 'rgba(0, 0, 0, 0.5)',
            offsetX: 1,
            offsetY: 1,
            opacity: 0.5,
            width: 6,
          } : false,
          dataLabels:
          {
            allowOverlap: false,//this.ChartProperties.allowOverlap,
            enabled: this.ChartProperties.showDataLabel,
            color: 'black',
            inside: true,
            align: this.ChartProperties.dataLabelPosition,
            rotation: this.ChartProperties.datalabelOrientation,
            useHTML: true,
            style: {
              backgroundColor: 'transparent',
              shadow: false,
              borderWidth: 0,
              textOutline: 'none',
              borderRadius: 0,
            },
            formatter: function (this: any) {
              let strColor="white";
              
              if (properties.datalabelStyle == "change") {
                this.Sig_LowerLimt = sigLowerValue;
                  // this.DefaultConfidenceLevel == '95%'
                  //   ? COMMON_CONSTANTS.SigLowerLimit_95
                  //   : this.DefaultConfidenceLevel == '90%'
                  //     ? COMMON_CONSTANTS.SigLowerLimit_90
                  //     : this.DefaultConfidenceLevel == '98%'
                  //       ? COMMON_CONSTANTS.SigLowerLimit_98
                  //       : COMMON_CONSTANTS.SigLowerLimit_95;
                this.Sig_UpperLimt =sigUpperValue;
                  // this.DefaultConfidenceLevel == '95%'
                  //   ? COMMON_CONSTANTS.SigUpperLimit_95
                  //   : this.DefaultConfidenceLevel == '90%'
                  //     ? COMMON_CONSTANTS.SigUpperLimit_90
                  //     : this.DefaultConfidenceLevel == '98%'
                  //       ? COMMON_CONSTANTS.SigUpperLimit_98
                  //       : COMMON_CONSTANTS.SigUpperLimit_95;

                let text = this.y == 0.000001 ? 'NA' : this.y + (properties.datalabelFormat == "percentage" ? '%' : '');
                let changevspy = (isNaN(this.series.userOptions.changeValue[this.point.x])?"NA":('' + this.series.userOptions.changeValue[this.point.x] + (properties.datalabelFormat == "percentage" ? '%' : '') + ''));
                let sig = this.series.userOptions.significanceValue[this.point.x];

                if (text == 'NA') {
                  return (
                    '<div><div style="font-size: 0.6rem;text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:'+strColor+'">' + text + '</div></div>'
                  );
                }
                if (changevspy == 'NA') {
                  return (
                    '<div><div style="font-size: 0.6rem;text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:'+strColor+'">' + text + '</div><div style="margin-top: 3px;text-align: center;background-color:white">' + changevspy + '</div><div>'
                  );
                }
                else if (sig < this.Sig_LowerLimt) {
                  return (
                    '<div style="width: 30px;"><div style="font-size: 0.6rem;text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:'+strColor+'">' + text + '</div><div  style="margin-top: 3px;text-align: center;color: red;background-color:white;width: 100%;display: flex;"><div style="width: 75%;">' + changevspy + '</div><div style="width: 25%;"><img style="width: 100%;height: 80%;" src="../../../../assets/images/common/arrows/red-down-arrow.png" alt="down-arrow"></div></div></div>'
                  )
                } else if (sig > this.Sig_UpperLimt) {
                  return (
                    '<div style="width: 30px;"><div style="font-size: 0.6rem;text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:'+strColor+'">' + text + '</div><div style="margin-top: 3px;text-align: center;color: green;background-color:white;width: 100%;display: flex;"><div style="width: 75%;">' + changevspy + '</div><div style="width: 25%;"><img style="width: 100%;height: 80%;" src="../../../../assets/images/common/arrows/green-up-arrow.png" alt="up-arrow"></div></div></div>'
                  )
                }
                else {
                  return (
                    '<div><div style="font-size: 0.6rem;text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:'+strColor+'">' + text + '</div><div style="margin-top: 3px;text-align: center;background-color:white;width: 100%">' + changevspy + '</div></div>'
                  );
                }


                //let text = this.y != 0 ? this.y + (properties.datalabelFormat == "percentage" ? '%' : '') + '(' + change[c++] + ')' : 'NA';
                //return (
                //  '<span >' + text + '</span>'
                //);

              }
              if(this.series.userOptions.samplesize[this.point.x] == "0" && this.y == 0.000001){
                return ("<div style='text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:white'>NA</div>");
              }
              else{
                if(this.series.userOptions.samplesize[this.point.x] < InsufficientBase){
                  return ("<div style='text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:white'>I/S</div>");
                }
                else{
                  if(this.y == 0.000001)
                    return ("<div style='text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:white'>NA</div>")
                  else{
                    if(this.series.userOptions.samplesize[this.point.x] < lowSample && this.series.userOptions.samplesize[this.point.x] > InsufficientBase)
                      return ("<div style='text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:white'>"+(this.y + (properties.datalabelFormat == "percentage" ? '%' : '') + ('*'))+"</div>")
                    else
                      return ("<div style='text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:white'>"+(this.y + (properties.datalabelFormat == "percentage" ? '%' : ''))+"</div>")
                  }
                }
              }
              // return this.series.userOptions.samplesize[this.point.x] == "0" && this.y == 0.000001 ? "<div style='white'>NA</div>" : this.series.userOptions.samplesize[this.point.x] < InsufficientBase ? "<div style='white'>I/S</div>" : this.y == 0.000001 ? "<div style='white'>NA</div>" : (this.series.userOptions.samplesize[this.point.x] < lowSample && this.series.userOptions.samplesize[this.point.x] > InsufficientBase) ? "<div style='white'>"+(this.y + (properties.datalabelFormat == "percentage" ? '%' : '') + ('*'))+"</div>" : "<div style='white'>"+(this.y + (properties.datalabelFormat == "percentage" ? '%' : ''))+"</div>";
            },
          },
          pointWidth: 30,
        },
        bar: {
          stacking: this.ChartProperties.stacking ? 'percent' : 'normal',
          shadow: this.ChartProperties.shadow ? {
            color: 'rgba(0, 0, 0, 0.5)',
            offsetX: 1,
            offsetY: 1,
            opacity: 0.5,
            width: 6,
          } : false,
          dataLabels:
          {
            allowOverlap: false,//this.ChartProperties.allowOverlap,
            enabled: this.ChartProperties.showDataLabel,
            color: 'black',
            align: this.ChartProperties.dataLabelPosition,
            rotation: this.ChartProperties.datalabelOrientation,
            useHTML: true,
            style: {
              backgroundColor: 'transparent',
              shadow: false, // Remove shadow
              borderWidth: 0,
              textOutline: 'none',
              borderRadius: 0,
            },
            formatter: function (this: any) {
              let strColor="white";
              if (properties.datalabelStyle == "change") {
                this.Sig_LowerLimt =sigLowerValue;
                  // this.DefaultConfidenceLevel == '95%'
                  //   ? COMMON_CONSTANTS.SigLowerLimit_95
                  //   : this.DefaultConfidenceLevel == '90%'
                  //     ? COMMON_CONSTANTS.SigLowerLimit_90
                  //     : this.DefaultConfidenceLevel == '98%'
                  //       ? COMMON_CONSTANTS.SigLowerLimit_98
                  //       : COMMON_CONSTANTS.SigLowerLimit_95;
                this.Sig_UpperLimt =sigUpperValue;
                  // this.DefaultConfidenceLevel == '95%'
                  //   ? COMMON_CONSTANTS.SigUpperLimit_95
                  //   : this.DefaultConfidenceLevel == '90%'
                  //     ? COMMON_CONSTANTS.SigUpperLimit_90
                  //     : this.DefaultConfidenceLevel == '98%'
                  //       ? COMMON_CONSTANTS.SigUpperLimit_98
                  //       : COMMON_CONSTANTS.SigUpperLimit_95;

                let text = this.y == 0.000001 ? 'NA' : this.y + (properties.datalabelFormat == "percentage" ? '%' : '');
                let changevspy = (isNaN(this.series.userOptions.changeValue[this.point.x]) ? "NA" : ('' + this.series.userOptions.changeValue[this.point.x] + (properties.datalabelFormat == "percentage" ? '%' : '') + ''));
                let sig = this.series.userOptions.significanceValue[this.point.x];

                if (text == 'NA') {
                  return (
                    '<div><div style="text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:'+strColor+'">' + text + '</div></div>'
                  );
                }
                if (changevspy == 'NA') {
                  return (
                    '<div><div style="text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:'+strColor+'">' + text + '</div><div style="margin-top: 3px;text-align: center;background-color:white">' + changevspy + '</div><div>'
                  );
                }
                if (sig < this.Sig_LowerLimt) {
                  return (
                    '<div style="width: 30px;"><div style="text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:'+strColor+'">' + text + '</div><div  style="margin-top: 3px;text-align: center;color: red;background-color:white;width: 100%;display: flex;"><div style="width: 75%;">' + changevspy + '</div><div style="width: 25%;"><img style="width: 100%;height: 80%;" src="../../../../assets/images/common/arrows/red-down-arrow.png" alt="down-arrow"></div></div></div>'
                  )
                } else if (sig > this.Sig_UpperLimt) {
                  return (
                     '<div style="width: 30px;"><div style="text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:'+strColor+'">' + text + '</div><div style="margin-top: 3px;text-align: center;color: green;background-color:white;width: 100%;display: flex;"><div style="width: 75%;">' + changevspy + '</div><div style="width: 25%;"><img style="width: 100%;height: 80%;" src="../../../../assets/images/common/arrows/green-up-arrow.png" alt="up-arrow"></div></div></div>'
                  )
                }
                else {
                  return (
                    '<div><div style="text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:'+strColor+'">' + text + '</div><div style="margin-top: 3px;text-align: center;background-color:white;width: 100%">' + changevspy + '</div></div>'
                  );
                }


                //let text = this.y != 0 ? this.y + (properties.datalabelFormat == "percentage" ? '%' : '') + '(' + change[c++] + ')' : 'NA';
                //return (
                //  '<span >' + text + '</span>'
                //);

              }
              if(this.series.userOptions.samplesize[this.point.x] == "0" && this.y == 0.000001){
                return ("<div style='text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:white'>NA</div>");
              }
              else{
                if(this.series.userOptions.samplesize[this.point.x] < InsufficientBase){
                  return ("<div style='text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:white'>I/S</div>");
                }
                else{
                  if(this.y == 0.000001)
                    return ("<div style='text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:white'>NA</div>")
                  else{
                    if(this.series.userOptions.samplesize[this.point.x] < lowSample && this.series.userOptions.samplesize[this.point.x] > InsufficientBase)
                      return ("<div style='text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:white'>"+(this.y + (properties.datalabelFormat == "percentage" ? '%' : '') + ('*'))+"</div>")
                    else
                      return ("<div style='text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;text-align: center;color:white'>"+(this.y + (properties.datalabelFormat == "percentage" ? '%' : ''))+"</div>")
                  }
                }
              }
              //return this.series.userOptions.samplesize[this.point.x] == "0" && this.y == 0.000001 ? "NA" : this.series.userOptions.samplesize[this.point.x] < InsufficientBase ? 'I/S' : this.y == 0.000001 ? 'NA' : (this.series.userOptions.samplesize[this.point.x] < lowSample && this.series.userOptions.samplesize[this.point.x] > InsufficientBase) ? (this.y + (properties.datalabelFormat == "percentage" ? '%' : '') + ('*')) : (this.y + (properties.datalabelFormat == "percentage" ? '%' : ''));
            },

          },
          
          pointWidth: 30,
        },
        series: {
          groupPadding: 0.01,
        pointPadding: 0.25,
      }

      },
      series: this.seriesData
    } as any);

  }

  commonfunc(event: any, customization: string, options: any) {
    this.event = event;
    this.stackService.commonFunc(customization, event, this.ChartProperties)
    this.renderChart2('stack-chart-container', this.data['data'], this.ChartProperties, "", "");
  }

  patternselect(ispattern: boolean) {
    this.ChartProperties.pattern = ispattern
    this.accessSeriesValues(this.ChartProperties.datalabelStyle == 'lowSample', "");
    this.renderChart2('stack-chart-container', this.data['data'], this.ChartProperties, "", "");
  }

  userselectedproperties() {
    this.data["properties"][0].showPattern = this.ChartProperties.pattern;
    this.data["properties"][0] = this.ChartProperties;
    return JSON.stringify(this.data).toString();
  }

  getUnique(arr: any[], keys: string[]): any[] {
    const map = new Map<string, any>();
    arr.forEach(item => {
      const compositeKey = keys.map(key => item[key]).join('|');
      if (!map.has(compositeKey)) {
        map.set(compositeKey, item);
      }
    });
    return Array.from(map.values());
  }

}
