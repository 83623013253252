export const environment = {
  baseApiUrl: 'https://appservicekapdashboardapidemo.azurewebsites.net/',
  redirectUrl: 'https://demo-dataexplorer.kantar.com/landing',
  kantarClientID: '2040ad25-7617-46ae-ae28-4010945c7142',
  kantarApiClientID: 'ac98ae22-c167-4df0-8983-1bd55ce9d5c1',
  kantarAuthorityID: 'https://login.microsoftonline.com/1e355c04-e0a4-42ed-8e2d-7351591f0ef1',
  UserGuideUrl: 'https://elearning.easygenerator.com/bcd568f0-29e3-43fb-a042-238bfbb04e6e/',
  UserGuideSnapshotUrl: 'https://elearning.easygenerator.com/4168a9e6-5f42-4a18-80cd-5aeab0add565/',
  logoutURL: 'https://demo-dataexplorer.kantar.com/signout-oidc',
  sessionTimeOut: '20',
  env: 'demo',
  showUploadModule: 'true',
};
