import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BarServiceService {
  properties: any;
  seriesData1: any[] = [];
  colorIndex = 0;
  color1: string[] = ["#47B7AE",
    "#2C70A3",
    "#286B66",
    "#6F3A91",
    "#CC753A",
    "#EFB467",
    "#F9C099",
    "#F4E4B3",
    "#CD978B",
    "#EAC4D5"]
  linearGradients: any = this.color1.map((color, index) => {
    const darkerShade = this.getDarkerShade(color);
    return {
      darkerShade: darkerShade,
      linearGradient: { x1: 0.4, x2: 0.5, y1: 0, y2: 0 },
      stops: [
        [0, color],
        [1, darkerShade]
      ]
    };
  });
  colors: string[] = ['red', 'blue', 'orange', '#BDE6BD', 'purple', 'brown', 'pink', 'cyan', 'blue']; // Add more colors as needed
  darkThemeBackgroundColor = '#333';
  defaultThemeBackgroundColor = 'white';
  darkThemeTextColor = 'white';
  defaultThemeTextColor = 'black';
  defaultCategories = ['Brand 1', 'Brand 1', 'Brand 2', 'Brand 3', 'Brand 4', 'Brand 5', 'Brand 6', 'Brand 7', 'Brand 8', 'Brand 9'];
  defaultCategoriesCluster = ['Brand 1', 'Brand 2', 'Brand 3', 'Brand 4', 'Brand 5', 'Brand 6', 'Brand 7', 'Brand 8', 'Brand 9'];
  transparentBackground = 'transparent';
  upwardTriangle = '&#9650';
  downwardTriangle = '\u25BC';
  highSignificance = '#BDE6BD';
  lowSignificance = 'red';
  updatedCategories = [
    { name: 'Brand 1', image: 'https://img.freepik.com/premium-vector/highly-detailed-vietnam-map-with-borders-isolated-background_599062-8131.jpg' },
    { name: 'Brand 1', image: 'https://img.freepik.com/premium-vector/highly-detailed-vietnam-map-with-borders-isolated-background_599062-8131.jpg' },
    { name: 'Brand 2', image: 'https://d2gg9evh47fn9z.cloudfront.net/800px_COLOURBOX7783047.jpg' },
    { name: 'Brand 3', image: 'https://th.bing.com/th/id/OIP.gxQmRFM-PSHbekk72mukxwHaH_?rs=1&pid=ImgDetMain' },
    { name: 'Brand 4', image: 'https://th.bing.com/th/id/R.4abe7f8a1272b46cb5d1e67d4341b6ec?rik=lW0lyg9V5L%2b7UQ&riu=http%3a%2f%2fwww.mapsof.net%2fuploads%2fstatic-maps%2feuropecontour.png&ehk=aDTomnr7%2bgplizpCBegO2ztJrXsGolh7sWqvGkqOQAI%3d&risl=&pid=ImgRaw&r=0' },
    { name: 'Brand 5', image: 'https://media.istockphoto.com/vectors/black-silhouette-country-borders-map-of-india-on-white-background-of-vector-id955286158?k=6&m=955286158&s=170667a&w=0&h=zeBvYe1ttcA8HTslBhAfLRfzS0q2D3o7LQ9Jtp8ift4=' },
    { name: 'Brand 6', image: 'https://th.bing.com/th/id/OIP.xB_qm1E9-36RTaYXsankpgAAAA?rs=1&pid=ImgDetMain' },
    { name: 'Brand 7', image: 'https://th.bing.com/th/id/OIP.Goh-tNxcyXD550sCNWLfWwHaFu?rs=1&pid=ImgDetMain' },
    { name: 'Brand 8', image: 'https://media.istockphoto.com/vectors/black-map-of-africa-vector-id538983577?k=6&m=538983577&s=170667a&w=0&h=kNULQNQDiGAGXAHDgQktQZlmDx96bqip3Eb-5Z-z6sA=' },
    { name: 'Brand 9', image: 'https://media.istockphoto.com/vectors/vector-map-of-japan-with-high-details-vector-id522130269?k=6&m=522130269&s=612x612&w=0&h=YaRe4yUW_zZo81B4zWEUqqxHP23eJaeQubWgJA6LGzc=' },
  ];
  patterns= ['url(#pattern1)', 'url(#pattern2)', 'url(#pattern3)', 'url(#pattern4)', 'url(#pattern5)', 'url(#pattern6)', 'url(#pattern7)', 'url(#pattern8)', 'url(#pattern9)']; // Add more colors as needed


  plotBandsColor = 'white';

  plotBandsColorDark = '#333';
  plotBandsColor2 = '#DCDCDC';
  plotBandsColorDark2 = '#4f4f4f';


  plot = [

    { color: this.plotBandsColor, from: 0.7, to: 1.3 },
    { color: this.plotBandsColor2, from: 1.7, to: 2.3 },
    { color: this.plotBandsColor, from: 2.7, to: 3.3 },
    { color: this.plotBandsColor2, from: 3.7, to: 4.3 },
    { color: this.plotBandsColor, from: 4.7, to: 5.3 },
    { color: this.plotBandsColor2, from: 5.7, to: 6.3 },
    { color: this.plotBandsColor, from: 6.7, to: 7.3 },
    { color: this.plotBandsColor2, from: 7.7, to: 8.3 },
    { color: this.plotBandsColor, from: 8.7, to: 9.3 },

  ];
  plotDark = [

    { color: this.plotBandsColorDark, from: 0.7, to: 1.3 },
    { color: this.plotBandsColorDark2, from: 1.7, to: 2.3 },
    { color: this.plotBandsColorDark, from: 2.7, to: 3.3 },
    { color: this.plotBandsColorDark2, from: 3.7, to: 4.3 },
    { color: this.plotBandsColorDark, from: 4.7, to: 5.3 },
    { color: this.plotBandsColorDark2, from: 5.7, to: 6.3 },
    { color: this.plotBandsColorDark, from: 6.7, to: 7.3 },
    { color: this.plotBandsColorDark2, from: 7.7, to: 8.3 },
    { color: this.plotBandsColorDark, from: 8.7, to: 9.3 },

  ];
  plot2 = [
    { color: this.plotBandsColor, from: -0.5, to: 0.3 },
    { color: this.plotBandsColor2, from: 0.6, to: 1.4 },
    { color: this.plotBandsColor, from: 1.6, to: 2.4 },
    { color: this.plotBandsColor2, from: 2.6, to: 3.4 },
    { color: this.plotBandsColor, from: 3.6, to: 4.4 },

  ];
  plot2Dark = [
    { color: this.plotBandsColorDark, from: -0.5, to: 0.3 },
    { color: this.plotBandsColorDark2, from: 0.6, to: 1.4 },
    { color: this.plotBandsColorDark, from: 1.6, to: 2.4 },
    { color: this.plotBandsColorDark2, from: 2.6, to: 3.4 },
    { color: this.plotBandsColorDark, from: 3.6, to: 4.4 },

  ];
  shadowColor = 'rgba(0, 0, 0, 0.5)';
  getDarkerShade(color: any) {
    const darkenFactor = 0.7; // Adjust this factor for a darker shade
    const rgb = color.substring(1); // Remove the '#' from the color
    const r = parseInt(rgb.substring(0, 2), 16);
    const g = parseInt(rgb.substring(2, 4), 16);
    const b = parseInt(rgb.substring(4, 6), 16);
    const darkerR = Math.round(r * darkenFactor);
    const darkerG = Math.round(g * darkenFactor);
    const darkerB = Math.round(b * darkenFactor);
    return `#${darkerR.toString(16).padStart(2, '0')}${darkerG.toString(16).padStart(2, '0')}${darkerB.toString(16).padStart(2, '0')}`;
  }

  commonFunc(customization: any, event: any, property: any) {
    this.properties = property;
    switch (customization) {

      case 'GridLines':
        this.properties.gridlines = event.target.checked ;
      
        break;
      case 'Shadow':
        this.properties.shadow = event.target.checked;
        break;
      case 'Dark Theme':
        this.properties.darkTheme = event.target.checked;
        this.plotBandsColor = this.properties.darkTheme ? '#333' : 'white';
        break;
      case 'Gradient Bar Color': this.properties.gradientBarColor = event.target.checked;

        break;
      case 'ToolTip Style : ': this.properties.toolTipStyle = event.target.value;
     
      break
      case 'Marker : ':
        this.properties.marker = event.target.value;
        break;
      
      case 'Marker Style* : ':
        this.properties.markerStyle = event.target.value;
        break;
      case 'Axis Image': this.properties.axisImage = event.target.checked;
        break;

      case 'Axis Label Orientation : ': this.properties.axisLabelOrientation = event.target.value;
        break;
      case 'Legend : ':
        this.properties.showLegend = event.target.value == 'enable' ? true : false;

        break;

      case 'Data Label : ': this.properties.showDataLabel = event.target.value == 'enable' ? true : false;
        break;
      case 'Legend Position : ':
        this.properties.legendPosition = event.target.value

        break;

      case 'Chart Title Position : ': this.properties.chartTitlePosition = event.target.value;
        break;


     
    

      case 'Data Label Property : ': this.properties.datalabelStyle = event.target.value;
        break;
      case 'Data Label Position# : ': this.properties.dataLabelPosition = event.target.value;
        break;
    
      case 'Data Label Position* : ':

        this.properties.dataLabelPositionPPT = event.target.value;
        break;
      case 'Data Label Format : ':
        this.properties.datalabelFormat = event.target.value;
        break;
      case 'Data Label Color : ': this.properties.dataLabelColor = event.target.value;
        break;


      default:

        break;
    }

    return this.properties
  }

  customizeLegend(chart: any, showlegend: any, legendPos: any, properties: any): Highcharts.LegendOptions {
   
    let alignLegend: Highcharts.VerticalAlignValue = 'bottom'; // Default value
    let alignx: Highcharts.AlignValue | undefined = 'left';
    let layout1: Highcharts.OptionsLayoutValue | undefined = 'horizontal';

    if (showlegend) {
      switch (legendPos) {
        case "top":
          alignLegend = 'top';
          alignx = 'left';
          layout1 = 'horizontal';
          break;
        case "bottom":
          alignLegend = 'bottom';
          alignx = 'center';
          layout1 = 'horizontal';
          break;
        case "right":
          alignLegend = 'middle';
          alignx = 'right';
          layout1 = 'vertical';
          break;
        case "left":
          alignLegend = 'middle';
          alignx = 'left';
          layout1 = 'vertical';
          break;
        default:
          // No need to change anything for the default case
          break;
      }
    }

    return {
      enabled: properties.showLegend,
      align: alignx,
      verticalAlign: alignLegend,
      layout: layout1,
      itemStyle: {
        color: properties.darkTheme ? "white" : "black",
      },
      itemHiddenStyle: {
        color: "grey",
      },


    };
  }
  axisLabelOrientation(axisLabelOrientation: any) {
    let rotationValue = 0;
    switch (axisLabelOrientation) {
      case 'vertical':
        rotationValue = -90; // or 90 depending on your preference
        break;
     
      case 'horizontal':
        rotationValue = 0;
        break;
      case 'angled':
        rotationValue = 60;
        break;
    
      default:
        rotationValue = 0;
        break;
    }
    return rotationValue
  }
  customizationService() {
    let custom: any = [

      { customization: 'Dark Theme', func: 'commonfunc', inputType: 'checkbox', value: 'Dark' },
      { customization: 'GridLines', func: 'commonfunc', inputType: 'checkbox', value: 'gridLines' },

      { customization: 'Shadow', func: 'commonfunc', inputType: 'checkbox', value: 'Shadow' },
      { customization: 'Gradient Bar Color', func: 'commonfunc', inputType: 'checkbox' },
      
      { customization: 'Axis Image', func: 'commonfunc', inputType: 'checkbox' },
      {
        customization: 'Axis Label Orientation : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'horizontal', label: 'Horizontal' },
          { value: 'vertical', label: 'Vertical' },

          { value: 'angled', label: 'Angled' },

          
        ]
      },
      {
        customization: 'ToolTip Style : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'default', label: 'Default' },

          { value: 'style1', label: 'Style1' },
        ]
      },

      {
        customization: 'Marker : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'disable', label: 'Disable' },
          { value: 'enable', label: 'Enable' },

        ]
      },
      {
        customization: 'Marker Style* : ', func: 'commonfunc', inputType: 'dropdown', options: [
         
          { value: 'circle', label: 'Circle' },
          { value: 'square', label: 'Square' },
          { value: 'diamond', label: 'Diamond' },
          { value: 'triangle', label: 'Triangle' }
        ]
      },
      {
        customization: 'Chart Title Position : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'left', label: 'Left' },
          { value: 'right', label: 'Right' },
          
          { value: 'center', label: 'Center' }
        ]
      },
      {
        customization: 'Legend : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'disable', label: 'Disable' },
          { value: 'enable', label: 'Enable' },

        ]
      },
      {
        customization: 'Legend Position : ', func: 'commonfunc', inputType: 'dropdown', options: [

          { value: 'left', label: 'Left' },
          { value: 'bottom', label: 'Bottom' },
          { value: 'right', label: 'Right' },  // Add this option for 'Right'
        ]
      },
      {
        customization: 'Data Label : ', func: 'commonfunc', inputType: 'dropdown', options: [
         
          { value: 'disable', label: 'Disable' },
          { value: 'enable', label: 'Enable' },

        ]
      },
      {
        customization: 'Data Label Format : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'number', label: 'Number' },
          { value: 'percentage', label: 'Percentage' },
        ]
      },


      {
        customization: 'Data Label Property : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'datalabel', label: 'Default ' },
          { value: 'change', label: 'Change Value ' },
          { value: 'significance', label: 'Significance ' },
          { value: 'significance2', label: 'Significance Level2' },

          { value: 'significance + changeVs', label: 'Significance and Change Value' },

          { value: 'ls', label: 'Low Sample ' },

        ]
      },
     

      { "customization": "Data Label Color : ", "func": "commonfunc", "inputType": "color", "value": "#000000" },
      {
        customization: 'Data Label Color : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'default', label: 'Default' },
          { value: 'black', label: 'Black' },
          { value: 'red', label: 'Red' },
          { value: 'green', label: 'Green' },
          { value: 'blue', label: 'Blue' },
        ]
      },
    
      {
        customization: 'Data Label Position# : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'center', label: 'Center' },
          { value: 'right', label: 'Left' },
          { value: 'left', label: 'Right' },

        ]
      },
      {
        customization: 'Data Label Position* : ', func: 'commonfunc', inputType: 'dropdown', options: [

          { value: 'Inside Base', label: 'Inside Base' },
          { value: 'Center', label: 'Center' },
          { value: 'Inside End', label: 'Inside End' },
          { value: 'Outside End', label: 'Outside End' }
        ]
      },

    ];
    return custom;
  }
  customizationService2() {
    let custom: any = [

      { customization: 'Dark Theme', func: 'commonfunc', inputType: 'checkbox', value: 'Dark' },
      { customization: 'GridLines', func: 'commonfunc', inputType: 'checkbox', value: 'gridLines' },

      { customization: 'Shadow', func: 'commonfunc', inputType: 'checkbox', value: 'Shadow' },
      { customization: 'Gradient Bar Color', func: 'commonfunc', inputType: 'checkbox' },

      { customization: 'Axis Image', func: 'commonfunc', inputType: 'checkbox' },
      {
        customization: 'Axis Label Orientation : ', func: 'commonfunc', inputType: 'dropdown', options: [

          { value: 'vertical', label: 'Vertical' },

          { value: 'angled', label: 'Angled' },

          { value: 'horizontal', label: 'Horizontal' }
        ]
      },
      {
        customization: 'ToolTip Style : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'default', label: 'Default' },

          { value: 'style1', label: 'Style1' },
        ]
      },
      {
        customization: 'Chart Title Position : ', func: 'commonfunc', inputType: 'dropdown', options: [

          { value: 'right', label: 'Right' },
          { value: 'left', label: 'Left' },
          { value: 'center', label: 'Center' }
        ]
      },
      {
        customization: 'Legend : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'disable', label: 'Disable' },
          { value: 'enable', label: 'Enable' },

        ]
      },
      {
        customization: 'Legend Position : ', func: 'commonfunc', inputType: 'dropdown', options: [

          { value: 'left', label: 'Left' },
          { value: 'bottom', label: 'Bottom' },
          { value: 'right', label: 'Right' },  // Add this option for 'Right'
        ]
      },
      {
        customization: 'Data Label : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'enable', label: 'Enable' },
          { value: 'disable', label: 'Disable' },

        ]
      },
      {
        customization: 'Data Label Format : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'number', label: 'Number' },
          { value: 'percentage', label: 'Percentage' },
        ]
      },


      {
        customization: 'Data Label Property : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'datalabel', label: 'Default ' },
          { value: 'change', label: 'Change Value ' },
          { value: 'significance', label: 'Significance ' },
          {value:'significance2',label:'Significance Level2'},

          { value: 'significance + changeVs', label: 'Significance and Change Value' },

          { value: 'ls', label: 'Low Sample ' },

        ]
      },
      {
        customization: 'Data Label Color : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'default', label: 'Default' },
          { value: 'black', label: 'Black' },
          { value: 'red', label: 'Red' },
          { value: 'green', label: 'Green' },
          { value: 'blue', label: 'Blue' },
        ]
      },

    
      {
        customization: 'Data Label Position : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'center', label: 'Center' },
          { value: 'right', label: 'Left' },
          { value: 'left', label: 'Right' },

        ]
      },
      {
        customization: 'Data Label Position* : ', func: 'commonfunc', inputType: 'dropdown', options: [

          { value: 'Inside Base', label: 'Inside Base' },
          { value: 'Center', label: 'Center' },
          { value: 'Inside End', label: 'Inside End' },
          { value: 'Outside End', label: 'Outside End' }
        ]
      },

    ];
    return custom;
  }
  constructor() { }
}
