import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { environment } from 'src/app/environments/environment';
// import { ExcelExportReportInputs } from '../../models/excel-export-report.model';
import { ExcelExportReportInputs } from '../../models/excel-export-report.model';
@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.css']
})
export class HeaderHomeComponent {
  toggleSetting: boolean = false;
  @Input() exportReportInput!: ExcelExportReportInputs;
  @Input() settingIconShown: boolean = true;
  @Input() additionalCheck: any;
  isHelpGuide: boolean = true;
  isDocumentRepository: boolean = true;
  exportDownloads : boolean = true;
  guideUrl = environment.UserGuideSnapshotUrl;
  @Input() moduleName!: string;
  showExportsDownloadsPopup: boolean = false;
  constructor(private router: Router, private _authenticateService: AuthenticationService) { }

  ngOnInit() {
    if (this.moduleName != undefined || this.moduleName != null) {
      this.settingOptions();
    }
  }

  ngOnChanges() {
    if (this.moduleName != undefined || this.moduleName != null) {
      this.settingOptions();
    }
  }

  OnExportsDownloads() {
    this.showExportsDownloadsPopup = true;
  }

  onCloseExportDownloads() {
    this.showExportsDownloadsPopup = false;
  }

  settingOptions() {
    switch (this.moduleName) {
      case 'crosstab':
        this.isHelpGuide = false;
        this.isDocumentRepository = false;
        break;
      case 'snapshot':
        if (this.additionalCheck != undefined) {
          this.isHelpGuide = (this.additionalCheck?.find((x: any) => x.key == 'Help Guide'))?.length > 0;
        }
        this.isDocumentRepository = true;
        break;
      case 'customsnapshot':
        if (this.additionalCheck != undefined) {
        this.additionalCheck?.find((x: any) => x.key == 'Help Guide')?.value;
        }
        this.isDocumentRepository = true;
        break;
    }
  }

  redirectToLandingPage() {
    this.router.navigate(['/landing']);
  }
  onUserManagementClick() {
    this.router.navigate(['/user-management']);
  }
  onUserGuideClick() {
    const guideUrl = this.guideUrl;
    window.open(guideUrl, '_blank');
  }
  LogOut() {
    this._authenticateService.logout();
  }
  OnSettingClick() {
    this.toggleSetting = !this.toggleSetting;
  }
  closeSummary() {
    this.toggleSetting = false;
  }
}
