import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.css']
})
export class FilterPopupComponent {
  @Input() input: any;
  @Output() selectedOptionsFilter=new EventEmitter();
  searchQuery: string = "";
  selectedOption(item: any) {
    item.isSelected = !item.isSelected;
    let selectedObj = [];
    for (let i = 0; i < this.input.length; i++) {
      if (this.input[i].isSelected == true) {
        selectedObj.push({key:this.input[i].moduleName,value:this.input[i].data});
      }
    }
    this.selectedOptionsFilter.emit({
      header:this.input[0].header,
      searchTxt:selectedObj
  });
  }
}
