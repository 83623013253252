<div class="container">
  <div class="sidebar">
    <div class="icon"><img src="assets/imageChatbot/Bot.svg" /></div>
    <div class="icon" style="display: none;">
      <img src="assets/imageChatbot/Menu_My Stories.svg" />
    </div>
    <div class="icon" style="display: none;">
      <img src="assets/imageChatbot/Menu_Saved Stories.svg" />
    </div>
    <div class="icon" style="display: none;">
      <img src="assets/imageChatbot/Menu_Shared with Me.svg" />
    </div>
    <div class="icon" style="display: none;">
      <img src="assets/imageChatbot/Menu_Shared by You.svg" />
    </div>
  </div>
  <div class="main-content">
    <div class="question">
      <div class="question-icon">
        <img src="assets/imageChatbot/icon_question.svg" />
      </div>
      <h2>{{ inputValueShow }}</h2>
      <div class="question-icon" style="display:none;">
        <img src="assets/imageChatbot/icon_edit.svg" />
      </div>
      <div class="question-icon" *ngIf="isDiagnostic" id="Diagnostic" style="margin-left: auto;">
        <img src="assets/imageChatbot/diagnostic.jpg" (click)="fetchDiagnostic()" />
      </div>
    </div>
    <div class="question-break-line"></div>
    <div class="response">
      <button class="insight-icon-response">
        <img src="assets/imageChatbot/Bot.svg" />
      </button>
      <!-- <p>Brand X generated a profit of 10 million in India this year. Additionally, kindly review the forecasted data for the next six months.</p> -->
    </div>
    <!-- <div>
      <h6 style="text-align: center;">{{selectedData}}</h6>
    </div> -->
    <!--summaryPanel-->
    <div class="infoRibbon summaryPanel" #summaryText (click)="selectionSummaryBlock()" *ngIf="isCreateBtnClick">


      <div class="InfoRibbonText" *ngIf="!isSelectionSummary">
        <div class="InforRibbonTextValues" #inforRibbon [title]="summaryTitle" [innerHTML]="selectionSummary"></div>
      </div>
      <div class="InfoRibbonTextCompleteSummary" *ngIf="isSelectionSummary">
        <div class="InforRibbonEntireSummary" [innerHTML]="selectionSummary"></div>
      </div>

        <div class="InfoCollapse">
          <div class="arrowToggle info-collapse-icon"></div>
        </div>
      </div>
      <div class="chart">
        <!-- Your chart component here -->
        <div class="outputData-config" [ngClass]="{ outputGap: is_indexing }">
          <div class="sigIndexContainer" *ngIf="is_indexing">
            <div class="sig-data-block" [ngClass]="{ not_active: isSig == false }" (click)="toggleSigBlock('Sig')">
              <div>Sig</div>
            </div>
            <div class="index-data-block" [ngClass]="{ itemDisable: isTranspose, active: isIndex }"
              (click)="toggleSigBlock('Index')">
              <div>Index</div>
            </div>
          </div>
          <div class="significanceContainer" *ngIf="!isIndex">
            <div class="significanceMainContainer" *ngIf="is_sigtesting && is_Time_Period_Present">
              <div class="significanceLabel">Significance</div>
              <div class="significanceDropDown significance">
                <div class="significance-block" (click)="togglePanel('significance')">
                  <ng-container *ngIf="SignificanceVsReference">
                    <div class="sigNameContainer" title="{{ DefaultSampleSize }} : {{
                      replacePipe(SignificanceVsReference)
                    }}">
                      <div class="weighted-text-block">
                        {{ DefaultSampleSize }} :
                        {{ replacePipe(SignificanceVsReference) }}
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!SignificanceVsReference">
                    <div class="sigNameContainer" title="{{ DefaultSampleSize }}">
                      <div class="weighted-text-block">
                        {{ DefaultSampleSize }}
                      </div>
                    </div>
                  </ng-container>
                  <div class="arrow-weighted-block">
                    <div class="arrowToggle arrowToggleIcon" [ngClass]="{ arrowCollapsedIcon: sampleSizeDropdown }">
                    </div>
                  </div>
                </div>
                <div class="sampleSize-sub-items" *ngIf="sampleSizeDropdown">
                  <div *ngFor="let list of SampleSizeList" class="sub-items-block"
                    (click)="SampleSizeDropDownClick(list.key)" [ngClass]="{
                    disabled:
                      (list.key === 'Pairwise' && isDisablePairWise) ||
                      (list.key === 'Reference' && isDisableReference)
                  }">
                    <div class="sample_size_block">
                      <div class="sub-items-names">{{ list.key }}</div>
                    </div>
                    <div class="side-panel-arrow-sig" *ngIf="list.key === 'Reference'">
                      <div class="arrowToggle metric-list-open"></div>
                    </div>
                  </div>
                </div>
                <div class="Reference-sub-items-significance" *ngIf="isReference">
                  <div class="reference-panel-search">
                    <input type="text" placeholder="Search" style="width: 100%" [(ngModel)]="searchTextLevel1"
                      autocomplete="off" name="searchTextLevel1" />
                    <div class="search-icon">
                      <div class="search-icon-image"></div>
                    </div>
                  </div>
                  <div *ngFor="let list of IndexData" class="reference-sub-items-block" [ngClass]="{
                    hide: !list.Child.toLowerCase().includes(
                      searchTextLevel1.toLowerCase()
                    )
                  }" (click)="referenceDropDownClickIndexSignificance(list.Child)">
                    <div class="reference-icon-container">
                      <div class="radio-icon" [ngClass]="{
                        'radio-icon-active': isSelectedSignificance(list.Child)
                      }"></div>
                    </div>
                    <div class="reference-option-container" title="{{ replacePipe(list.Child) }}">
                      <div class="sub-items-names">
                        {{ replacePipe(list.Child) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="confidenceLevelContainer" *ngIf="is_confidenceLevel.length > 0 && !isIndex">
            <div class="confidenceLevelMainContainer" *ngIf="is_Time_Period_Present">
              <div class="confidanceLabel">Confidence Level</div>
              <div class="confidanceDropDown confidance">
                <div class="confidence-block" [ngClass]="{ itemDisable: DefaultSampleSize == 'None' }"
                  (click)="togglePanel('confidence')">
                  <div class="confidence-text-block">
                    {{ DefaultConfidenceLevel }}
                  </div>
                  <div class="arrow-weighted-block arrowBlock">
                    <div class="arrowToggle arrowToggleIcon"
                      [ngClass]="{ arrowCollapsedIcon: confidenceLevelDropDown }"></div>
                  </div>
                </div>
                <div class="confidence-sub-items" *ngIf="confidenceLevelDropDown">
                  <div *ngFor="let list of is_confidenceLevel" class="sub-items-block"
                    (click)="confidenceDropDownClick(list.key)">
                    <div class="sub-items-names">{{ list.key }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="ReferenceLevelContainer" *ngIf="isIndex">
            <div class="ReferenceLevelMainContainer">
              <div class="ReferenceLabel">Reference</div>
              <div class="ReferenceDropDown reference">
                <div class="Reference-block" (click)="togglePanel('reference')">
                  <div class="Reference-text-block-main">
                    <div class="Reference-text-block" title="{{ DefaultReferenceLevel }}">
                      {{ DefaultReferenceLevel }}
                    </div>
                  </div>
                  <div class="arrow-weighted-block referenceArrowBlock">
                    <div class="arrowToggle arrowToggleIcon" [ngClass]="{ arrowCollapsedIcon: referenceLevelDropDown }">
                    </div>
                  </div>
                </div>
                <div class="Reference-sub-items" *ngIf="referenceLevelDropDown">
                  <div class="reference-panel-search">
                    <input type="text" placeholder="Search" style="width: 100%" [(ngModel)]="searchTextLevel1"
                      autocomplete="off" name="searchTextLevel1" />
                    <div class="search-icon">
                      <div class="search-icon-image"></div>
                    </div>
                  </div>
                  <div *ngFor="let list of IndexData" class="reference-sub-items-block" [ngClass]="{
              hide: !list.Child.toLowerCase().includes(
                searchTextLevel1.toLowerCase()
              )
            }" (click)="referenceDropDownClick(list.Child)">
                    <div class="reference-icon-container">
                      <div class="radio-icon" [ngClass]="{
                  'radio-icon-active': isSelected(list.Child)
                }"></div>
                    </div>
                    <div class="reference-option-container" title="{{ replacePipe(list.Child) }}">
                      <div class="sub-items-names">
                        {{ replacePipe(list.Child) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="DataContainer" *ngIf="SampleSizeContainer">
            <div class="dataMainContainer">
              <div class="dataDropDownContainer dataDropdown">
                <div class="data-block-main" (click)="togglePanel('datadropdown')">
                  <div class="sigNameContainer" title="{{ DefaultDataDropDown }}">
                    <div class="weighted-text-block">
                      {{ DefaultDataDropDown }}
                    </div>
                  </div>

                  <div class="arrow-weighted-block">
                    <div class="arrowToggle arrowToggleIcon" [ngClass]="{ arrowCollapsedIcon: dataDropDown }"></div>
                  </div>
                </div>
                <div class="data-sub-items" *ngIf="dataDropDown">
                  <div *ngFor="let list of dataDropDownList" class="sub-items-block" (click)="toggleDataBlock(list)">
                    <div class="sample_size_block">
                      <div class="sub-items-names">{{ list }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--<div class="sampleSizeContainer" *ngIf="SampleSizeContainer">
          <div class="data-block"
               [ngClass]="{ not_active: isData == false }"
               (click)="toggleSampleSizeBlock('Data')">
            <div>Data</div>
          </div>
          <div class="samplesize-block" *ngIf="isSampleSizeBlock"
               [ngClass]="{ active: isSampleSize }"
               (click)="toggleSampleSizeBlock('SampleSize')">
            <div>Sample Size</div>
          </div>
          <div class="weighted-label-block" *ngIf="isWeightedSampleBlock"
               [ngClass]="{ active: isWeightedLabel }"
               (click)="toggleSampleSizeBlock('WeightedLabel')">
            <div>Weighted Sample</div>
          </div>
        </div>-->

          <div class="ChartMainContainer">
            <ng-container *ngIf="is_chart_visualization">
              <div class="iconsContainerMain" (mouseover)="showMainTooltip = true"
                (mouseleave)="showMainTooltip = false">
                <div class="iconsContainer" [ngClass]="{ itemDisable: !isChartView }">
                  <div class="tablePreview" [ngClass]="{ bg: displayTable }" (click)="onTableIconClick()">
                    <div class="iconContainer">
                      <div class="output-icon" [ngClass]="displayTable ? 'table-icon-white' : 'table-icon'"></div>
                    </div>
                  </div>

                  <div class="tooltipMain" (mouseover)="showStackTooltip = true"
                    (mouseleave)="showStackTooltip = false">


                    <div class="columnChartPreview" #preview
                      [ngClass]="{ bg: isVerticalStackChartClick && !disableStackChart,itemDisable: !disableStackChart && !isVerticalStackChartClick}">
                      <div class="iconContainer">
                        <div class="output-icon"
                          [ngClass]="isVerticalStackChartClick ? 'horizontal-bar-icon-white': 'horizontal-bar-icon'">
                        </div>
                      </div>
                    </div>
                    <div class="tooltip" *ngIf="showStackTooltip && (!disableStackChart && !isVerticalStackChartClick)">
                      <ul>
                        <ng-container *ngFor="let item of StackChartToolTip">
                          <li [innerHTML]="item"></li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>

                  <div class="tooltipMain" (mouseover)="showLineTooltip = true" (mouseleave)="showLineTooltip = false">
                    <div class="lineChartPreview" [ngClass]="{
                  bg: isLineChartClick && !disableLineChart,
                  itemDisable: !disableLineChart && !isLineChartClick
                }" (click)="onTrendChartIconClick()">
                      <div class="iconContainer">
                        <div class="output-icon" [ngClass]="
                      isLineChartClick
                        ? 'trend-chart-icon-white'
                        : 'trend-chart-icon'
                    "></div>
                      </div>
                    </div>
                    <div class="tooltip" *ngIf="showLineTooltip && (!disableLineChart && !isLineChartClick)">
                      <ul>
                        <ng-container *ngFor="let item of LineChartToolTip">
                          <li [innerHTML]="item"></li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                  <div class="tooltipMain" (mouseover)="showBarTooltip = true" (mouseleave)="showBarTooltip = false">
                    <div class="GrpVertical" [ngClass]="{
                  bg: isVerticalGroupBarChartClick || isVerticalBarChartClick
                }" (click)="VerticalChartClick('column')">
                      <div class="iconContainer">
                        <div class="output-icon grp-bar-icon" [ngClass]="
                      isVerticalGroupBarChartClick || isVerticalBarChartClick
                        ? 'grp-bar-icon-white'
                        : 'grp-bar-icon'
                    "></div>
                      </div>
                    </div>
                    <div class="tooltip" *ngIf="showBarTooltip && (!isChartView)">
                      <ul>
                        <ng-container *ngFor="let item of BarChartToolTip">
                          <li [innerHTML]="item"></li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="tooltip" *ngIf="showMainTooltip && (!isChartView)">
                  <ul>
                    <ng-container *ngFor="let item of GeneralToolTip">

                      <li [innerHTML]="item"></li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </ng-container>
          </div>

          <!-- <div class="TransposeContainer">
          <div class="tranpose-block" [ngClass]="{ 'itemDisable': isLineChartClick}"
               (click)="OnTransposeClick()"
               *ngIf="is_TransposeBtn">
            <div class="checkbox-icon"
                 [ngClass]="{ 'checkbox-icon-active': isTranspose }"></div>
            <div class="btn-text">Transpose</div>
          </div>
        </div> -->
          <div class="TotalTransposeContainer">
            <div class="Totaltranpose-block total-block" [ngClass]="{ itemDisable: isTranspose }"
              (click)="OnTotalClick()" *ngIf="
               dataObject !=null &&
               this.dataObject['RowNesting'] !=null &&
               this.dataObject['RowNesting'].length>
            0
            ">
              <div class="checkbox-icon" [ngClass]="{ 'checkbox-icon-active': isTotal }"></div>
              <div class="btn-text">Total</div>
            </div>
            <div class="Totaltranpose-block transpose-block" [ngClass]="{
              itemDisable: isLineChartClick || isTotal || isPairWise || isIndex
            }" (click)="OnTransposeClick()" *ngIf="is_TransposeBtn">
              <div class="checkbox-icon" [ngClass]="{ 'checkbox-icon-active': isTranspose }"></div>
              <div class="btn-text">Transpose</div>
            </div>
          </div>
          <!-- <div class="editTableContainer">
          <div class="config-btn config-footer-btn editTranpose"
               (click)="EditClick()">
            <div class="btn-icon edit-icon"></div>
            <div class="btn-text">Edit Table</div>
          </div>
        </div> -->
          <div class="exports-download-container" *ngIf="isCreateBtnClick">
            <div class="export-blocks" title="Export to Excel" [ngClass]="{'disabled':!isCreateBtnClick}"
              (click)="handleExportClick()">
              <div class="excel-download-icon excel-download-inactive"></div>
            </div>
            <div class="export-blocks" title="Export to PPT" [ngClass]="{ 'disabled':
          !(pptExport &&
          (isVerticalGroupBarChartClick ||
            isVerticalBarChartClick ||
            isVerticalStackChartClick ||
            isLineChartClick))
         }" (click)="handlePPTExportClick()">
              <div class="ppt-download-icon ppt-download-inactive"></div>
            </div>
            <!-- <app-header-settings (excelExportClick)="handleExportClick()"
                               (pptExportClick)="handlePPTExportClick()"
                               [docClick]="false"
                               [showExcel]="isCreateBtnClick"
                               [showPPT]="
              pptExport &&
              (isVerticalGroupBarChartClick ||
                isVerticalBarChartClick ||
                isVerticalStackChartClick ||
                isLineChartClick)
            "
                               [excelExport]="excelExport"
                               [logout]="false"
                               [Home]="false"
                               [userManagementShown]="false"
                               [isHeader]="false"
                               [pptExport]="pptExport"></app-header-settings> -->
          </div>
        </div>
        <div class="table-output-wrapper">
          <div class="table-output">
            <app-table-ouput *ngIf="displayTable" [data]="OutputdataObject" [isTranspose]="isTranspose"
              [isTotalBase]="isTotalBase" [isAnswerBase]="isAnswerBase" [isSampleSize]="isSampleSize"
              [selectedDataFormat]="selectedDataFormat" [isWeightedLabel]="isWeightedLabel"
              [DefaultConfidenceLevel]="DefaultConfidenceLevel" [isWeightedNumerator]="isWeightedNumerator"
              [isUnWeightedNumerator]="isUnWeightedNumerator" [insufficientBase]="insufficientBase" [LowBase]="LowBase"
              [DefaultSampleSize]="DefaultSampleSize" [isSampleSizeBlock]="isSampleSizeBlock"
              [isWeightedSampleBlock]="isWeightedSampleBlock" [isPairWise]="isPairWise" [threshold]="threshold"
              [selectedIndex]="selectedIndex" [isIndex]="isIndex" [selectedWeight]="selectedWeight"></app-table-ouput>

            <!-- <app-table-ouput *ngIf="displayTable"
            [data]="OutputdataObject"
            [isTranspose]="isTranspose"
            [isTotalBase]="isTotalBase"
            [isAnswerBase]="isAnswerBase"
            [isSampleSize]="isSampleSize"
            [isWeightedLabel]="isWeightedLabel"
            [isWeightedNumerator]="isWeightedNumerator"
            [isUnWeightedNumerator]="isUnWeightedNumerator"
            [DefaultConfidenceLevel]="DefaultConfidenceLevel"
            [insufficientBase]="insufficientBase"
            [LowBase]="LowBase"
            [DefaultSampleSize]="DefaultSampleSize"
            [selectedDataFormat]="selectedDataFormat"
            [isSampleSizeBlock]="isSampleSizeBlock"
            [isWeightedSampleBlock]="isWeightedSampleBlock"
            [isPairWise]="isPairWise"
            [threshold]="threshold"
            [selectedIndex]="selectedIndex"
            [isIndex]="isIndex"
            [selectedWeight]="selectedWeight"></app-table-ouput> -->

            <!-- newly added -->
            <app-vertical-group-bar-chart *ngIf="isVerticalGroupBarChartClick" [insufficientBase]="insufficientBase"
              [LowBase]="LowBase"></app-vertical-group-bar-chart>
            <app-vertical-bar-chart *ngIf="isVerticalBarChartClick" [insufficientBase]="insufficientBase"
              [LowBase]="LowBase"></app-vertical-bar-chart>
            <app-stackChart *ngIf="isVerticalStackChartClick" [insufficientBase]="insufficientBase"
              [LowBase]="LowBase"></app-stackChart>
            <app-lineChart *ngIf="isLineChartClick" [insufficientBase]="insufficientBase"
              [LowBase]="LowBase"></app-lineChart>
            <!-- <app-shared-popup *ngIf="isSaveSelectionClick"
                          [isSaveSelectionClick]="isSaveSelectionClick"
                          [showLoaderPopUp]="showLoaderPopUp"
                          (isSavePopUpVisible)="handleSavePopUP($event)"
                          (SaveSelectionClick)="SaveSelection($event)"></app-shared-popup> -->
            <!-- newly added end -->

          </div>
          <div *ngIf="isInsightspopupOpen" class="insights">
            <!-- <p>{{ insightsVal }}</p> -->
            <ul>
              <li *ngFor="let detail of insightsVal">{{ detail }}</li>
            </ul>
          </div>
        </div>

      </div>
      <div class="insight-input-section" style="display: none;">
        <div class="insight-input-section-box">
          <button (click)="openInsights()" class="insight-input-icon">
            <img src="assets/imageChatbot/Icon_Add your insights.svg" />
          </button>
          <input type="text" placeholder="Insights" />
        </div>
        <div class="insight-icons">
          <button class="insight-icon">
            <img src="assets/imageChatbot/Share.svg" />
          </button>
          <button class="insight-icon">
            <img src="assets/imageChatbot/Download.svg" />
          </button>
          <button class="insight-icon">
            <img src="assets/imageChatbot/More.svg" />
          </button>
        </div>
      </div>
      <div class="footer" style="display: none;">
        <div class="footer-button">Do you want to know about another brand?</div>
        <div class="footer-button" style="display: none;">Another period?</div>
        <div class="footer-button" style="display: none;">Why something went up or down?</div>
        <div class="footer-button" style="display: none;">Do you want to know about another brand?</div>
      </div>
      <div class="input-section">
        <input [(ngModel)]="inputValue" (keyup.enter)="sendInputMessage()" type="text" placeholder="Ask something..."
          style="height:80px" />
        <button (click)="sendInputMessage()" class="send-button">
          <img src="assets/imageChatbot/send_icon.svg" class="insight-icon" />
        </button>
      </div>
    </div>

    <!-- <app-insights-popup *ngIf="isInsightspopupOpen"></app-insights-popup> -->
  </div>
  <div class="loader-container" *ngIf="arktabaccess">
    <div class="loaderARK"></div>
  </div>