<div class="OverLay" *ngIf="showLoaderPopUp">
  <div class="popup-container">
    <div class="popup-header">
      <div class="header-text">Select Client</div>
      <div class="close-icon" (click)="closeClick()"></div>
    </div>
    <div class="popup-inner-container">
      <div class="popup-content">
        <div class="search-box">
          <input type="text" class="text-box-search" placeholder="Search for Clients" (input)="filterData()"
            [(ngModel)]="searchText" name="searchText" />
          <div class="search-icon-image"></div>
        </div>
      </div>
      <div class="popup-content-parent">
        <div *ngIf="filteredData">
          <div *ngFor="let client of filteredData">
            <div class="clientList">
              <div class="clientName" [ngClass]="{ selected: client.showStudies }" (click)="toggleStudies(client)">
                {{ client.ClientName }}
              </div>
              <div class="arrowToggle" [ngClass]="{
                  selected: client.showStudies,
                  'arrow-collapsed': client.showStudies
                }" (click)="toggleStudies(client)"></div>
            </div>

            <div *ngIf="client.showStudies">
              <div class="studyDetails">
                <div *ngFor="let study of client.UniqueStudies">
                  <div class="studyList" (click)="
                      selectStudy(client.ClientName, study, client.ClientCode)
                    ">
                    <div class="radio-icon" [ngClass]="{
                        'radio-icon-active': isSelected(
                          client.ClientName,
                          study
                        )
                      }"></div>
                    <div class="radio-text">{{ study }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





      <!-- <div class="popup-footer">
        <div class="footer-button navigate" [class.disabled]="isNavigateButtonDisabled" (click)="navigate()">
          <div class="naviagateIcon"></div>
          <div class="footer-text">Navigate to Page</div>
        </div>
        <div class="footer-button configuration" *ngIf="!hideConfiguration" [class.disabled]="isConfigurationButtonDisabled" (click)="configuration()">
          <div class="configIcon"></div>
          <div class="footer-text">Configuration</div>
        </div>
        <div class="footer-button addclient" *ngIf="!hideConfiguration" [class.disabled]="isAddClientButtonDisabled" (click)="addClientStudy()">
          <div class="addIcon"></div>
          <div class="footer-text">Add Client/Study</div>
        </div>
        <div class="footer-button usermanagement" *ngIf="!hideConfiguration" (click)="OnUserManagementClick()">
          <div class="usermanagement-icon"></div>
          <div class="footer-text">User Management</div>
        </div>
      </div> -->




      <div class="popup-footer">
        <div class="config-btn config-footer-btn" [class.disabled]="isNavigateButtonDisabled" (click)="navigate()">
          <div class="btn-icon Icon_Navigate"></div>
          <div class="btn-text">Navigate to Page</div>
        </div>

        <div class="config-btn config-footer-btn" *ngIf="isConfigurationShown"
          [class.disabled]="isConfigurationButtonDisabled" (click)="configuration()">
          <div class="btn-icon Icon_Config"></div>
          <div class="btn-text">Configuration</div>
        </div>

        <!--<div class="config-btn config-footer-btn"
             *ngIf="isAddClientStudyShown"
             [class.disabled]="isAddClientButtonDisabled"
             (click)="addClientStudy()">
          <div class="btn-icon Icon_Add"></div>
          <div class="btn-text">Add Client/Study</div>
        </div>-->
        <!--<div class="config-btn config-footer-btn"
             *ngIf="isUserManagementShown"
             (click)="OnUserManagementClick()">
          <div class="btn-icon usermanagement-icon"></div>
          <div class="btn-text">User Management</div>
        </div>-->
      </div>
    </div>
  </div>
</div>

<div class="OverLay" *ngIf="showLoaderSnapShotPopup">
  <div class="popup-container">
    <div class="popup-header">
      <div class="header-text">Select Client</div>
      <div class="close-icon" (click)="closeClick()"></div>
    </div>
    <div class="popup-inner-container">
      <div class="popup-content">
        <div class="search-box">
          <input type="text" class="text-box-search" placeholder="Search for Clients" (input)="filterData()"
            [(ngModel)]="searchText" name="searchText" />
          <div class="search-icon-image"></div>
        </div>
      </div>
      <div class="popup-content-parent">
        <div *ngIf="filteredData">
          <div *ngFor="let client of filteredData">
            <div class="clientList">
              <div class="clientName" [ngClass]="{ selected: client.showStudies }" (click)="toggleStudies(client)">
                {{ client.ClientName }}
              </div>
              <div class="arrowToggle" [ngClass]="{
                  selected: client.showStudies,
                  'arrow-collapsed': client.showStudies
                }" (click)="toggleStudies(client)"></div>
            </div>

            <div *ngIf="client.showStudies">
              <div class="studyDetails">
                <div class="addnewSnapshotdiv" [ngClass]="{DNN:!isSuperAdmin}"
                  [class.disabled]="client.UniqueSnapshots!=undefined?(client.UniqueSnapshots.length>=6?true:false):(client.UniqueStudies.length>=6?true:false)"
                  (click)="addSnapshot( client.ClientName)"><img src="../../../../assets/images/common/Add.svg"
                    style="width:12px"><span class="addnewSnapshot">Add New Snapshot</span></div>
                <div *ngFor="let study of client.UniqueStudies">
                  <div class="studyList" (click)="
                      selectStudy(client.ClientName,study, client.ClientCode)
                    ">
                    <div class="radio-icon" [ngClass]="{
                        'radio-icon-active': isSelected(
                          client.client_name,
                          study
                        )
                      }"></div>
                    <div class="radio-text">{{ study }}</div>
                  </div>
                </div>
                <div *ngFor="let study of client.UniqueSnapshots">
                  <div class="studyList" (click)="
                      selectStudy(client.ClientName,study, client.ClientCode)
                    ">
                    <div class="radio-icon" [ngClass]="{
                        'radio-icon-active': isSelected(
                          client.client_name,
                          study
                        )
                      }"></div>
                    <div class="radio-text">{{ study }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="popup-footer">
        <div class="config-btn config-footer-btn" [class.disabled]="isNavigateButtonDisabled"
          (click)="navigateSnapshot()">
          <div class="btn-icon Icon_Navigate"></div>
          <div class="btn-text">Navigate to Page</div>
        </div>

        <div class="config-btn config-footer-btn" *ngIf="isConfigurationShown"
          [class.disabled]="isConfigurationButtonDisabled" (click)="configurationSnapshot()">
          <div class="btn-icon Icon_Config"></div>
          <div class="btn-text">Configuration</div>
        </div>

        <div class="config-btn config-footer-btn" *ngIf="isAddClientStudyShown" style="display:none;"
          [class.disabled]="isAddClientButtonDisabled" (click)="addClientStudy()">
          <div class="btn-icon Icon_Add"></div>
          <div class="btn-text">Add Client/Study</div>
        </div>

        <div class="config-btn config-footer-btn" *ngIf="isUserManagementShown" style="display:none;"
          (click)="OnUserManagementClick()">
          <div class="btn-icon usermanagement-icon"></div>
          <div class="btn-text">User Management</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="popup-container-save" *ngIf="isSaveSelectionClick">
  <div class="popup-model">
    <div class="popup-header-save">
      <div class="popup-header-text">{{ headerTitle }}</div>
      <div class="popup-close" (click)="closeSavePopUp()" title="Close"></div>
    </div>
    <div class="popup-body">
      <div class="message-block">
        {{ StaticText }}
      </div>
      <div class="input-box">
        <input type="text" placeholder="Selection name.." [(ngModel)]="SaveSelection" maxlength="24" autocomplete="off"
          name="SaveSelection" (keydown)="onKeyDown($event)" />
      </div>
    </div>
    <div class="popup-footer-save">
      <div class="popup-btn" (click)="onOkBtnClick()">
        <div class="btn-icon check-circle-icon"></div>
        <div class="btn-text-save">OK</div>
      </div>
    </div>
  </div>
</div>

<div class="popup-container-save" *ngIf="isSaveCustomCalculationSelectionClick">
  <div class="popup-model">
    <div class="popup-header-save">
      <div class="popup-header-text">{{ headerTitle }}</div>
      <div class="popup-close" (click)="closeSavePopUp()" title="Close"></div>
    </div>
    <div class="popup-body">
      <div class="message-block">
        {{ StaticText }}
      </div>
      <div class="input-box">
        <input type="text" placeholder="Mean Variable name.." [(ngModel)]="SaveSelection" maxlength="50"
          autocomplete="off" name="SaveSelection" (keydown)="onKeyDown($event)" />
      </div>
    </div>
    <div class="popup-footer-save">
      <div class="popup-btn" (click)="onCustomCalculationOkBtnClick()">
        <div class="btn-icon check-circle-icon"></div>
        <div class="btn-text-save">OK</div>
      </div>
    </div>
  </div>
</div>

<div class="popup-container-save" *ngIf="isSaveCustomGroupSelectionClick">
  <div class="popup-model">
    <div class="popup-header-save">
      <div class="popup-header-text">{{ headerTitle }}</div>
      <div class="popup-close" (click)="closeSavePopUp()" title="Close"></div>
    </div>
    <div class="popup-body">
      <div class="message-block">
        {{ StaticText }}
      </div>
      <div class="input-box">
        <input type="text" placeholder="Percentage Variable name.." [(ngModel)]="SaveSelection" maxlength="50"
          autocomplete="off" name="SaveSelection" (keydown)="onKeyDown($event)" />
      </div>
    </div>
    <div class="popup-footer-save">
      <div class="popup-btn" (click)="onCustomOkBtnClick()">
        <div class="btn-icon check-circle-icon"></div>
        <div class="btn-text-save">OK</div>
      </div>
    </div>
  </div>
</div>

<div class="popup-container-load" *ngIf="isLoadClick" id="loadSelectionPopup">
  <div class="popup-model-load popup-model">
    <div class="popup-header-load">
      <div class="popup-header-text-load">Load Selection</div>
      <div class="popup-close-load" (click)="closeLoadPopUp()" title="Close"></div>
    </div>
    <div class="popup-body">
      <div class="infoMessage" *ngIf="!displayTable">
        <div class="backbutton config-btn CP" (click)="bindBackClickEvent()">
          Back
        </div>
        <div class="infoText" [innerHTML]="infoMessage"></div>
      </div>

      <div *ngIf="displayTable">
        <div class="header-block list-container-options">
          <div class="header-arrow-block">
            <div class="list-container-text" (click)="toggleSaveSelectionList('userDefined')"> User Defined</div>
            <div class="search-block" title="Search" *ngIf="isUserDefinedListVisible && loadUserDefinedSavedScenarioData.length>0">
              <input type="text" [(ngModel)]="userDefinedSelectionQuery" class="search-input" placeholder="Search">
              <div class="search-icon"></div>
            </div>
            <div class="arrow" (click)="toggleSaveSelectionList('userDefined')">
              <div class="arrowToggle arrowToggleIcon" [ngClass]="{'arrowCollapsedIcon': isUserDefinedListVisible}">
              </div>
            </div>
          </div>

        </div>
        <div class="list-container-options" *ngIf="isUserDefinedListVisible">
          <div class="custom-table-container">

            <!-- <table class="custom-table" *ngIf="displayTable"> -->
            <table class="custom-table">
              <thead *ngIf="loadUserDefinedSavedScenarioData.length>0">
                <tr>
                  <ng-container *ngFor="let col of columnConfig">
                    <ng-container *ngIf="isChangeOfEntryPointConfigured">
                      <th [style.width]="col.userDefined_width">
                        {{ col.name }}
                      </th>
                    </ng-container>
                    <ng-container *ngIf="col.name !== 'Change Entry Point' && (!isChangeOfEntryPointConfigured)">
                      <th [style.width]="col.userDefined_width_change">
                        {{ col.name }}
                      </th>
                    </ng-container>
                  </ng-container>

                </tr>
              </thead>
              <tbody class="custom-load-table-body">
                <tr
                  *ngFor="let row of loadUserDefinedSavedScenarioData| search: userDefinedSelectionQuery : ['selection_name']">
                  <td [style.width]="isChangeOfEntryPointConfigured? '62%':'80%'"
                    [title]="row.is_shared ? row.selection_name + '_Shared' : row.selection_name"
                    [ngClass]="{'ellipsis-cell': isChangeOfEntryPointConfigured,'ellipsis-cell-noentry': !isChangeOfEntryPointConfigured }">
                    <div class="scenarioName">
                      {{ row.is_shared ? row.selection_name + '_Shared' : row.selection_name }}
                    </div>
                  </td>

                  <ng-container *ngIf="isChangeOfEntryPointConfigured">

                    <td [style.width]="'6%'" class="imageContainer CP LoadImage"
                      (click)="handleLoadOptionsClick('load', row,'userDefined')"></td>
                    <td [style.width]="'6%'" class="imageContainer CP InfoImage"
                      (click)="handleLoadOptionsClick('info', row,'userDefined')"></td>
                    <td [style.width]="'6%'" class="imageContainer CP DeleteImage"
                      (click)="handleLoadOptionsClick('delete', row,'userDefined')"></td>

                    <td [style.width]="'14%'" class="imageContainer CP changeEntryPointImage"
                      (click)="handleLoadOptionsClick('changeentrypoint', row,'userDefined')"></td>

                    <td [style.width]="'6%'" class="imageContainer CP ShareImage"
                      (click)="handleLoadOptionsClick('Share', row,'userDefined')"></td>

                  </ng-container>

                  <ng-container *ngIf="!isChangeOfEntryPointConfigured ">
                    <td [style.width]="'5%'" class="imageContainer CP LoadImage"
                      (click)="handleLoadOptionsClick('load', row,'userDefined')"></td>
                    <td [style.width]="'5%'" class="imageContainer CP InfoImage"
                      (click)="handleLoadOptionsClick('info', row,'userDefined')"></td>
                    <td [style.width]="'5%'" class="imageContainer CP DeleteImage"
                      (click)="handleLoadOptionsClick('delete', row,'userDefined')"></td>
                    <td [style.width]="'5%'" class="imageContainer CP ShareImage"
                      (click)="handleLoadOptionsClick('Share', row,'userDefined')"></td>
                  </ng-container>



                </tr>
                <tr class="no-data" *ngIf="loadUserDefinedSavedScenarioData.length==0">
                  You have not created any saved selections.
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="header-block list-container-options">
          <div class="header-arrow-block">
            <div class="list-container-text" (click)="toggleSaveSelectionList('Shared With Me')"> Shared With Me</div>
            <div class="search-block" title="Search" *ngIf="isSharedWithMeListVisible && loadSharedWithMeSavedScenarioData.length>0">
              <input type="text" [(ngModel)]="sharedWithMeSelectionQuery" class="search-input" placeholder="Search">
              <div class="search-icon"></div>
            </div>
            <div class="arrow" (click)="toggleSaveSelectionList('Shared With Me')">
              <div class="arrowToggle arrowToggleIcon" [ngClass]="{'arrowCollapsedIcon': isSharedWithMeListVisible}">
              </div>
            </div>
          </div>

        </div>
        <div class="list-container-options" *ngIf="isSharedWithMeListVisible">
          <div class="custom-table-container">

            <!-- <table class="custom-table" *ngIf="displayTable"> -->
            <table class="custom-table">
              <thead *ngIf="loadSharedWithMeSavedScenarioData.length>0">
                <tr>
                  <!--<th *ngFor="let col of columnConfig.slice(0, -1)" [style.width]="col.sharedWithMe_width">
                    {{ col.name }}
                  </th>-->

                  <ng-container *ngFor="let col of columnConfig.slice(0, -1)">
                    <ng-container *ngIf="isChangeOfEntryPointConfigured">
                      <th [style.width]="col.sharedWithMe_width">
                        {{ col.name }}
                      </th>
                    </ng-container>
                    <ng-container *ngIf="col.name !== 'Change Entry Point' && (!isChangeOfEntryPointConfigured)">
                      <th [style.width]="col.sharedWithMe_width_change">
                        {{ col.name }}
                      </th>
                    </ng-container>
                  </ng-container>
                  <th style="width: 30%;">
                    Email
                  </th>




                </tr>
              </thead>
              <tbody class="custom-load-table-body">
                <tr
                  *ngFor="let row of loadSharedWithMeSavedScenarioData | search: sharedWithMeSelectionQuery : ['selection_name','user_name']">
                  <td [style.width]="isChangeOfEntryPointConfigured? '38%' : '55%'" [attr.title]="row.selection_name"
                    [ngClass]="{'ellipsis-cell-shared': isChangeOfEntryPointConfigured,'ellipsis-cell-shared-noentry': !isChangeOfEntryPointConfigured }">
                    <div class="scenarioName">{{ row.selection_name }}</div>
                  </td>
                  <ng-container *ngIf="isChangeOfEntryPointConfigured">
                    <td [style.width]="'6%'" class="imageContainer CP LoadImage"
                      (click)="handleLoadOptionsClick('load', row,'Shared With Me')"></td>
                    <td [style.width]="'6%'" class="imageContainer CP InfoImage"
                      (click)="handleLoadOptionsClick('info', row,'Shared With Me')"></td>

                    <td [style.width]="'6%'" class="imageContainer CP DeleteImage"
                      (click)="handleLoadOptionsClick('delete', row,'Shared With Me')"></td>

                    <td [style.width]="'14%'" class="imageContainer CP changeEntryPointImage"
                      (click)="handleLoadOptionsClick('changeentrypoint', row,'Shared With Me')"></td>

                  </ng-container>

                  <ng-container *ngIf="!isChangeOfEntryPointConfigured">
                    <td [style.width]="'5%'" class="imageContainer CP LoadImage"
                      (click)="handleLoadOptionsClick('load', row,'Shared With Me')"></td>
                    <td [style.width]="'5%'" class="imageContainer CP InfoImage"
                      (click)="handleLoadOptionsClick('info', row,'Shared With Me')"></td>

                    <td [style.width]="'5%'" class="imageContainer CP DeleteImage"
                      (click)="handleLoadOptionsClick('delete', row,'Shared With Me')"></td>



                  </ng-container>
                  <td style="width: 30%;" title="{{row.user_name}}">
                    <div class="scenarioName">{{ row.user_name }}</div>
                  </td>

                </tr>
                <tr class="no-data" *ngIf="loadSharedWithMeSavedScenarioData.length==0">
                  No saved selections have been shared with you.
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>



<div class="popup-container-load" *ngIf="isDocumentRepositoryClick" id="loadSelectionPopup">
  <div class="popup-model-document popup-model">
    <div class="popup-header-save">
      <div class="popup-header-text">Document Repository</div>
      <div class="popup-close" (click)="closeDocumentRepo()" title="Close"></div>
    </div>


    <div class="popup-body">
      <div class="popup-content-parent">
        <div class="folderList-main" *ngIf="docStorageData.length > 0">
          <div *ngFor="let folder of docStorageData">
            <div class="folderList" title="{{folder.foldername}}" (click)="fileExpand(folder)">
              <div class="folderName">
                {{ folder.foldername.toUpperCase() }}
              </div>
              <div class="ExpandIcon">
                <div class="expand expand-icon" [ngClass]="{'minimize-icon':folder.showFiles}"></div>
              </div>
            </div>
            <div class="fileNameDetails" *ngIf="folder.showFiles">
              <div class="fileList" *ngFor="let file of folder.filename" title="{{file.fileName}}"
                (click)="downloadFile(folder.foldername,file.fileName)">
                <div class="fileName"> {{file.fileName}}</div>
                <div class="download downloadFile"></div>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="NoDocument" *ngIf="docStorageData.length == 0">
          No Document Found
        </div>-->
      </div>
    </div>
  </div>
</div>



<!-- <div class="popup-container-load" *ngIf="isCustomCalculationClick" id="customCalculation">
  <div class="popup-model-load popup-model">
    <div class="popup-header-load">
      <div class="popup-header-text-load">Custom Calculation</div>
      <div class="popup-close-load" (click)="closeCustomCalculationPopUp()" title="Close"></div>
    </div>
    <div class="popup-body">
      <div class="custom-group-parent-container">
        <div class="group-list-container mcustom">
          <div class="group-header"> Custom Calculation List </div>

          <div class="list-container">

            <div class="header-block list-container-options" >
              <div class="header-arrow-block" (click)="toggleCustomCalculationList('userDefined')">
              <div class="list-container-text"> User Defined</div>
              <div class="arrow">
                <div class="arrowToggle arrowToggleIcon"
                     [ngClass]="{'arrowCollapsedIcon': isUserDefinedListVisible}"></div>

              </div>
            </div>
            <div class="search-block" title="Search" *ngIf="isUserDefinedListVisible">
              <input type="text" [(ngModel)]="userDefinedSelectionQuery" class="search-input" placeholder="Search">
              <div class="search-icon"></div>
          </div>
            </div>
            <div class="list-container-options" >
             <div class="list-container-options" *ngIf="isUserDefinedListVisible">
              <ng-container *ngFor="let client of loadCustomCalculationUserDefinedData| search: userDefinedSelectionQuery : ['selection_name']">
                  <!-- <ng-container *ngFor="let client of loadCustomCalculationUserDefinedData"> -->
<!-- <div class="custom-group-options">
          <div class="custom-group-item text-ellipsis" *ngIf="client.is_active" [ngClass]="client.selection_name === selectedCustomCalculationName ? 'side-panel-items-active' : ''" title="{{ client.is_shared? client.selection_name+'_Shared':client.selection_name}}" (click)="selectCustomCalculation(client.selection_name,'userDefined',client.id)">{{client.is_shared? client.selection_name+'_Shared':client.selection_name}}</div>
          <div *ngIf="client.shared_with.length>0" class="infoIcon infoIconImage" (click)="bindUserName(client.shared_with)"></div>
        </div>
      </ng-container>
    </div>
    <div class="header-block list-container-options" >
      <div class="header-arrow-block" (click)="toggleCustomCalculationList('Shared With Me')">
      <div class="list-container-text"> Shared With Me</div>
      <div class="arrow">
        <div class="arrowToggle arrowToggleIcon"
             [ngClass]="{'arrowCollapsedIcon': isSharedWithMeListVisible}"></div>

      </div>
    </div>
    <div class="search-block" title="Search" *ngIf="isSharedWithMeListVisible">
      <input type="text" [(ngModel)]="sharedWithMeSelectionQuery" class="search-input" placeholder="Search">
      <div class="search-icon"></div>
  </div>
    </div>
    <div class="list-container-options shared-List" *ngIf="isSharedWithMeListVisible">
      <ng-container *ngFor="let client of loadCustomCalculationSharedWithMeData | search: sharedWithMeSelectionQuery : ['selection_name','user_name']">
        <div class="custom-group-name-block" *ngIf="client.is_active" [ngClass]="client.selection_name === selectedCustomCalculationName ? 'side-panel-items-active' : ''" title="{{ client.selection_name}}" (click)="selectCustomCalculation(client.selection_name,'Shared With Me',client.id)">
        <div class="custom-group-name-text text-ellipsis" >{{client.selection_name}}</div>
        <div class="share-email-text text-ellipsis" title="{{client.user_name}}">{{client.user_name}}</div>
      </div>
      </ng-container>
    </div>


  </div>
</div>

<div class="summary-container">
  <div class="group-header">{{customCalculationHeaderText}}</div>
  <div class="summary-container-body-main">
    <div class="summary-container-body">

      <div class="custom-peer-tableElement" *ngFor="let list of updateSummary">
        <div class="custom-peer-tableElement-span" title="{{list.displayString}}">   {{list.displayString}}</div>
        <!--<div class="closeTag">
          <div class="close-btn close-metric-icon" (click)="removeCustomGroup(list.rowOrder)"></div>
        </div>-->
<!-- </div>

          </div>
        </div>
      </div>
    </div>
    <div class="custom-group-button-container popup-footer-save">

      <div class="popup-btn" (click)="onCreateCustomCalcBtn()">
        <div class="btn-icon create-icon"></div>
        <div class="btn-text-save">Create</div>
      </div>
       // shared with me custom group cannnot be shared or updated
      <div class="popup-btn" (click)="onUpdateCustomCalcBtn()" [ngClass]="(selectedCustomCalculationName!=='' && (this.selectedCustomCalculationName_Bucket=='userDefined')) ?'':'disabled'">
        <div class="btn-icon transpose-icon"></div>
        <div class="btn-text-save">Update</div>
      </div>
      <div class="popup-btn delete-btn-width" (click)="onDeleteCustomCalcBtn()" [ngClass]="selectedCustomCalculationName!==''?'':'disabled'">
        <div class="btn-icon DeleteImage"></div>
        <div class="btn-text-save">Delete</div>
      </div>
      <div class="popup-btn" (click)="onDuplicateCustomCalcBtn()" [ngClass]="selectedCustomCalculationName !== ''?'':'disabled'">
        <div class="btn-icon duplicate-icon"></div>
        <div class="btn-text-save">Duplicate</div>
      </div>
      <// shared with me custom group cannnot be shared or updated
      <div class="popup-btn" (click)="onShareCustomCalcBtn()" [ngClass]="(selectedCustomCalculationName!=='' && (this.selectedCustomCalculationName_Bucket=='userDefined')) ?'':'disabled'">
        <div class="btn-icon ShareImage"></div>
        <div class="btn-text-save">Share</div>
      </div>

    </div>
  </div>
</div> -->
<!-- </div> -->

<div class="popup-container-load" *ngIf="isCustomGroupClick" id="customGroup">
  <div class="popup-model-load popup-model">
    <div class="popup-header-load">
      <div class="popup-header-load-btns">
        <div class="popup-header-load-btns-cg" *ngIf="isCustomGroups" [ngClass]="{ not_active: isShareMetric == false }"
          (click)="customMetrics('shareMetric')">
          Percentage Variable
        </div>
        <div class="popup-header-load-btns-mean" *ngIf="isCustomCalculation" [ngClass]="{ active: isMeanMetric }"
          (click)="customMetrics('MeanMetric')">
          Mean Variable
        </div>

      </div>
      <div class="popup-close-load" (click)="closeCustomGroupPopUp()" title="Close"></div>
    </div>
    <div class="popup-body" *ngIf="isShareMetric">
      <div class="custom-group-parent-container">
        <div class="group-list-container mcustom">
          <div class="group-header"> Variable List </div>

          <div class="list-container">

            <div class="header-block list-container-options">
              <div class="header-arrow-block">
                <div class="list-container-text" (click)="toggleCustomGroupList('userDefined')"> User Defined</div>
                <div class="search-block" title="Search" *ngIf="isUserDefinedListVisible">
                  <input type="text" [(ngModel)]="userDefinedSelectionQuery" class="search-input" placeholder="Search">
                  <div class="search-icon"></div>
                </div>
                <div class="arrow" (click)="toggleCustomGroupList('userDefined')">
                  <div class="arrowToggle arrowToggleIcon" [ngClass]="{'arrowCollapsedIcon': isUserDefinedListVisible}">
                  </div>

                </div>
              </div>

            </div>
            <div class="list-container-options" *ngIf="isUserDefinedListVisible">
              <ng-container
                *ngFor="let client of loadCustomGroupUserDefinedData| search: userDefinedSelectionQuery : ['selection_name']">
                <div class="custom-group-options">
                  <div class="custom-group-item text-ellipsis" *ngIf="client.is_active"
                    [ngClass]="client.selection_name === selectedCustomGroupName ? 'side-panel-items-active' : ''"
                    title="{{ client.is_shared? client.selection_name+'_Shared':client.selection_name}}"
                    (click)="selectCustomGroup(client.selection_name,'userDefined',client.id)">{{client.is_shared?
                    client.selection_name+'_Shared':client.selection_name}}</div>
                  <div *ngIf="client.shared_with.length>0 && client.is_active" class="infoIcon infoIconImage"
                    (click)="bindUserName(client.shared_with)"></div>
                </div>
              </ng-container>
            </div>
            <div class="header-block list-container-options">
              <div class="header-arrow-block">
                <div class="list-container-text" (click)="toggleCustomGroupList('Shared With Me')"> Shared With Me</div>
                <div class="search-block" title="Search" *ngIf="isSharedWithMeListVisible">
                  <input type="text" [(ngModel)]="sharedWithMeSelectionQuery" class="search-input" placeholder="Search">
                  <div class="search-icon"></div>
                </div>
                <div class="arrow" (click)="toggleCustomGroupList('Shared With Me')">
                  <div class="arrowToggle arrowToggleIcon"
                    [ngClass]="{'arrowCollapsedIcon': isSharedWithMeListVisible}"></div>

                </div>
              </div>

            </div>
            <div class="list-container-options shared-List" *ngIf="isSharedWithMeListVisible">
              <ng-container
                *ngFor="let client of loadCustomGroupSharedWithMeData | search: sharedWithMeSelectionQuery : ['selection_name','user_name']">
                <div class="custom-group-name-block" *ngIf="client.is_active"
                  [ngClass]="client.selection_name === selectedCustomGroupName ? 'side-panel-items-active' : ''"
                  title="{{ client.selection_name}}"
                  (click)="selectCustomGroup(client.selection_name,'Shared With Me',client.id)">
                  <div class="custom-group-name-text text-ellipsis">{{client.selection_name}}</div>
                  <div class="share-email-text text-ellipsis" title="{{client.user_name}}">{{client.user_name}}</div>
                </div>
              </ng-container>
            </div>


          </div>
        </div>

        <div class="summary-container">
          <div class="group-header">{{customGroupHeaderText}}</div>
          <div class="summary-container-body-main">
            <div class="summary-container-body">

              <div class="custom-peer-tableElement" *ngFor="let list of updateSummary">
                <div class="custom-peer-tableElement-span" title="{{list.displayString}}"> {{list.displayString}}</div>
                <!--<div class="closeTag">
                <div class="close-btn close-metric-icon" (click)="removeCustomGroup(list.rowOrder)"></div>
              </div>-->
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="custom-group-button-container popup-footer-save">

        <div class="popup-btn" (click)="onCreateBtn()">
          <div class="btn-icon create-icon"></div>
          <div class="btn-text-save">Create</div>
        </div>
        <!-- // shared with me custom group cannnot be shared or updated -->
        <div class="popup-btn" (click)="onUpdateBtn()"
          [ngClass]="(selectedCustomGroupName!=='' && (this.selectedCustomGroupName_Bucket=='userDefined')) ?'':'disabled'">
          <div class="btn-icon transpose-icon"></div>
          <div class="btn-text-save">Edit</div>
        </div>
        <div class="popup-btn delete-btn-width" (click)="onDeleteBtn()"
          [ngClass]="selectedCustomGroupName!==''?'':'disabled'">
          <div class="btn-icon DeleteImage"></div>
          <div class="btn-text-save">Delete</div>
        </div>
        <div class="popup-btn" (click)="onDuplicateBtn()" [ngClass]="selectedCustomGroupName !== ''?'':'disabled'">
          <div class="btn-icon duplicate-icon"></div>
          <div class="btn-text-save">Duplicate</div>
        </div>
        <!-- // shared with me custom group cannnot be shared or updated -->
        <div class="popup-btn" (click)="onShareBtn()"
          [ngClass]="(selectedCustomGroupName!=='' && (this.selectedCustomGroupName_Bucket=='userDefined')) ?'':'disabled'">
          <div class="btn-icon ShareImage"></div>
          <div class="btn-text-save">Share</div>
        </div>

      </div>
    </div>

    <div class="popup-body" *ngIf="isMeanMetric">
      <div class="custom-group-parent-container">
        <div class="group-list-container mcustom">
          <div class="group-header"> Variable List </div>

          <div class="list-container">

            <div class="header-block list-container-options">
              <div class="header-arrow-block" (click)="toggleCustomCalculationList('userDefined')">
                <div class="list-container-text"> User Defined</div>
                <div class="arrow">
                  <div class="arrowToggle arrowToggleIcon" [ngClass]="{'arrowCollapsedIcon': isUserDefinedListVisible}">
                  </div>

                </div>
              </div>
              <div class="search-block" title="Search" *ngIf="isUserDefinedListVisible">
                <input type="text" [(ngModel)]="userDefinedSelectionQuery" class="search-input" placeholder="Search">
                <div class="search-icon"></div>
              </div>
            </div>
            <div class="list-container-options" *ngIf="isUserDefinedListVisible">
              <ng-container
                *ngFor="let client of loadCustomCalculationUserDefinedData| search: userDefinedSelectionQuery : ['selection_name']">
                <div class="custom-group-options">
                  <div class="custom-group-item text-ellipsis" *ngIf="client.is_active"
                    [ngClass]="client.selection_name === selectedCustomCalculationName ? 'side-panel-items-active' : ''"
                    title="{{ client.is_shared? client.selection_name+'_Shared':client.selection_name}}"
                    (click)="selectCustomCalculation(client.selection_name,'userDefined',client.id)">{{client.is_shared?
                    client.selection_name+'_Shared':client.selection_name}}</div>
                  <div *ngIf="client.shared_with.length>0 && client.is_active" class="infoIcon infoIconImage"
                    (click)="bindUserName(client.shared_with)"></div>
                </div>
              </ng-container>
            </div>
            <div class="header-block list-container-options">
              <div class="header-arrow-block" (click)="toggleCustomCalculationList('Shared With Me')">
                <div class="list-container-text"> Shared With Me</div>
                <div class="arrow">
                  <div class="arrowToggle arrowToggleIcon"
                    [ngClass]="{'arrowCollapsedIcon': isSharedWithMeListVisible}"></div>

                </div>
              </div>
              <div class="search-block" title="Search" *ngIf="isSharedWithMeListVisible">
                <input type="text" [(ngModel)]="sharedWithMeSelectionQuery" class="search-input" placeholder="Search">
                <div class="search-icon"></div>
              </div>
            </div>
            <div class="list-container-options shared-List" *ngIf="isSharedWithMeListVisible">
              <ng-container
                *ngFor="let client of loadCustomCalculationSharedWithMeData | search: sharedWithMeSelectionQuery : ['selection_name','user_name']">
                <div class="custom-group-name-block" *ngIf="client.is_active"
                  [ngClass]="client.selection_name === selectedCustomCalculationName ? 'side-panel-items-active' : ''"
                  title="{{ client.selection_name}}"
                  (click)="selectCustomCalculation(client.selection_name,'Shared With Me',client.id)">
                  <div class="custom-group-name-text text-ellipsis">{{client.selection_name}}</div>
                  <div class="share-email-text text-ellipsis" title="{{client.user_name}}">{{client.user_name}}</div>
                </div>
              </ng-container>
            </div>


          </div>
        </div>

        <div class="summary-container">
          <div class="group-header">{{customCalculationHeaderText}}</div>
          <div class="summary-container-body-main">
            <div class="summary-container-body">

              <div class="custom-peer-tableElement" *ngFor="let list of updateSummary">
                <div class="custom-peer-tableElement-span" title="{{list.displayString}}"> {{list.displayString}}</div>
                <!--<div class="closeTag">
                <div class="close-btn close-metric-icon" (click)="removeCustomGroup(list.rowOrder)"></div>
              </div>-->
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="custom-group-button-container popup-footer-save">

        <div class="popup-btn" (click)="onCreateCustomCalcBtn()">
          <div class="btn-icon create-icon"></div>
          <div class="btn-text-save">Create</div>
        </div>
        <!-- // shared with me custom group cannnot be shared or updated -->
        <div class="popup-btn" (click)="onUpdateCustomCalcBtn()"
          [ngClass]="(selectedCustomCalculationName!=='' && (this.selectedCustomCalculationName_Bucket=='userDefined')) ?'':'disabled'">
          <div class="btn-icon transpose-icon"></div>
          <div class="btn-text-save">Edit</div>
        </div>
        <div class="popup-btn delete-btn-width" (click)="onDeleteCustomCalcBtn()"
          [ngClass]="selectedCustomCalculationName!==''?'':'disabled'">
          <div class="btn-icon DeleteImage"></div>
          <div class="btn-text-save">Delete</div>
        </div>
        <div class="popup-btn" (click)="onDuplicateCustomCalcBtn()"
          [ngClass]="selectedCustomCalculationName !== ''?'':'disabled'">
          <div class="btn-icon duplicate-icon"></div>
          <div class="btn-text-save">Duplicate</div>
        </div>
        <!-- // shared with me custom group cannnot be shared or updated -->
        <div class="popup-btn" (click)="onShareCustomCalcBtn()"
          [ngClass]="(selectedCustomCalculationName!=='' && (this.selectedCustomCalculationName_Bucket=='userDefined')) ?'':'disabled'">
          <div class="btn-icon ShareImage"></div>
          <div class="btn-text-save">Share</div>
        </div>

      </div>
    </div>
  </div>
</div>

<!--<div class="popup-container-load" *ngIf="isCustomCalculationClick" id="customCalculation">
  <div class="popup-model-load popup-model">
    <div class="popup-header-load">
      <div class="popup-header-text-load">Mean Variable</div>
      <div class="popup-close-load" (click)="closeCustomCalculationPopUp()" title="Close"></div>
    </div>
    <div class="popup-body">
      <div class="custom-group-parent-container">
        <div class="group-list-container mcustom">
          <div class="group-header"> Mean Variable List </div>

          <div class="list-container">

            <div class="header-block list-container-options">
              <div class="header-arrow-block" (click)="toggleCustomCalculationList('userDefined')">
                <div class="list-container-text"> User Defined</div>
                <div class="arrow">
                  <div class="arrowToggle arrowToggleIcon"
                       [ngClass]="{'arrowCollapsedIcon': isUserDefinedListVisible}"></div>

                </div>
              </div>
              <div class="search-block" title="Search" *ngIf="isUserDefinedListVisible">
                <input type="text" [(ngModel)]="userDefinedSelectionQuery" class="search-input" placeholder="Search">
                <div class="search-icon"></div>
              </div>
            </div>
            <div class="list-container-options" *ngIf="isUserDefinedListVisible">
              <ng-container *ngFor="let client of loadCustomCalculationUserDefinedData| search: userDefinedSelectionQuery : ['selection_name']">
                <div class="custom-group-options">
                  <div class="custom-group-item text-ellipsis" *ngIf="client.is_active" [ngClass]="client.selection_name === selectedCustomCalculationName ? 'side-panel-items-active' : ''" title="{{ client.is_shared? client.selection_name+'_Shared':client.selection_name}}" (click)="selectCustomCalculation(client.selection_name,'userDefined',client.id)">{{client.is_shared? client.selection_name+'_Shared':client.selection_name}}</div>
                  <div *ngIf="client.shared_with.length>0 && client.is_active" class="infoIcon infoIconImage" (click)="bindUserName(client.shared_with)"></div>
                </div>
              </ng-container>
            </div>
            <div class="header-block list-container-options">
              <div class="header-arrow-block" (click)="toggleCustomCalculationList('Shared With Me')">
                <div class="list-container-text"> Shared With Me</div>
                <div class="arrow">
                  <div class="arrowToggle arrowToggleIcon"
                       [ngClass]="{'arrowCollapsedIcon': isSharedWithMeListVisible}"></div>

                </div>
              </div>
              <div class="search-block" title="Search" *ngIf="isSharedWithMeListVisible">
                <input type="text" [(ngModel)]="sharedWithMeSelectionQuery" class="search-input" placeholder="Search">
                <div class="search-icon"></div>
              </div>
            </div>
            <div class="list-container-options shared-List" *ngIf="isSharedWithMeListVisible">
              <ng-container *ngFor="let client of loadCustomCalculationSharedWithMeData | search: sharedWithMeSelectionQuery : ['selection_name','user_name']">
                <div class="custom-group-name-block" *ngIf="client.is_active" [ngClass]="client.selection_name === selectedCustomCalculationName ? 'side-panel-items-active' : ''" title="{{ client.selection_name}}" (click)="selectCustomCalculation(client.selection_name,'Shared With Me',client.id)">
                  <div class="custom-group-name-text text-ellipsis">{{client.selection_name}}</div>
                  <div class="share-email-text text-ellipsis" title="{{client.user_name}}">{{client.user_name}}</div>
                </div>
              </ng-container>
            </div>


          </div>
        </div>

        <div class="summary-container">
          <div class="group-header">{{customCalculationHeaderText}}</div>
          <div class="summary-container-body-main">
            <div class="summary-container-body">

              <div class="custom-peer-tableElement" *ngFor="let list of updateSummary">
                <div class="custom-peer-tableElement-span" title="{{list.displayString}}">   {{list.displayString}}</div>-->
<!--<div class="closeTag">
                  <div class="close-btn close-metric-icon" (click)="removeCustomGroup(list.rowOrder)"></div>
                </div>-->
<!--</div>

            </div>
          </div>
        </div>
      </div>
      <div class="custom-group-button-container popup-footer-save">

        <div class="popup-btn" (click)="onCreateCustomCalcBtn()">
          <div class="btn-icon create-icon"></div>
          <div class="btn-text-save">Create</div>
        </div>-->
<!-- // shared with me custom group cannnot be shared or updated -->
<!--<div class="popup-btn" (click)="onUpdateCustomCalcBtn()" [ngClass]="(selectedCustomCalculationName!=='' && (this.selectedCustomCalculationName_Bucket=='userDefined')) ?'':'disabled'">
          <div class="btn-icon transpose-icon"></div>
          <div class="btn-text-save">Update</div>
        </div>
        <div class="popup-btn delete-btn-width" (click)="onDeleteCustomCalcBtn()" [ngClass]="selectedCustomCalculationName!==''?'':'disabled'">
          <div class="btn-icon DeleteImage"></div>
          <div class="btn-text-save">Delete</div>
        </div>
        <div class="popup-btn" (click)="onDuplicateCustomCalcBtn()" [ngClass]="selectedCustomCalculationName !== ''?'':'disabled'">
          <div class="btn-icon duplicate-icon"></div>
          <div class="btn-text-save">Duplicate</div>
        </div>-->
<!-- // shared with me custom group cannnot be shared or updated -->
<!--<div class="popup-btn" (click)="onShareCustomCalcBtn()" [ngClass]="(selectedCustomCalculationName!=='' && (this.selectedCustomCalculationName_Bucket=='userDefined')) ?'':'disabled'">
          <div class="btn-icon ShareImage"></div>
          <div class="btn-text-save">Share</div>
        </div>

      </div>
    </div>
  </div>
</div>-->

<div class="popup-container-load" *ngIf="isShareCustomGroupPopupClick" id="shareCustomGroupPopup-container">
  <div class="popup-model-load popup-model" id="shareCustomGroupPopup">
    <div class="popup-header-save">
      <div class="popup-header-text ">Percentage Variable</div>
      <div class="popup-close" (click)="closeCustomGroupSharePopUp()" title="Close"></div>
    </div>
    <div class="popup-body">
      <div class="custom-group-parent-container">
        <div class="group-list-container mcustom">
          <div class="group-header">User List</div>
          <div class="share-custom-popup-inner-container">
            <div class="share-custom-popup-user-container">
              <div class="share-input-container">
                <input calss="share-email-input" type="text" (input)="showMatchingEmails($event)"
                  placeholder="Enter Email">

              </div>
              <div class="email-dropdown" *ngIf="isUserListToShareDropdownOpen">
                <div *ngIf="filteredEmailsToShare.length > 0">
                  <div *ngFor="let email of filteredEmailsToShare" class="email-list-container">
                    <div class="checkbox checkbox-icon" [ngClass]="{'checkbox-icon-active':isSharedAlready(email) }"
                      (click)="toggleSelectedEmail(email)">

                    </div>
                    <!-- <input type="checkbox" [value]="email" [checked]="isSharedAlready(email)" (change)="toggleSelectedEmail(email)">
                    <label for="myCheckbox" class="custom-checkbox" [class.checked-image]="isSharedAlready(email)"></label> -->
                    <div class="email-list-content" title="{{email}}">{{ email }}</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="summary-container">
          <div class="group-header">Custom Email List</div>
          <div class="share-custom-popup-right-container">
            <div *ngFor="let userEmail of selectedEmailsToShare" class="email-list-content" title="{{userEmail}}">
              {{userEmail}}</div>
          </div>
        </div>
      </div>
      <div class="custom-group-button-container popup-footer-save">


        <div class="popup-btn" (click)="onShareCustomGroups()">
          <div class="btn-icon ShareImage"></div>
          <div class="btn-text-save">Share</div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="popup-container-load" *ngIf="isShareCustomCalculationPopupClick" id="shareCustomCalculationPopup-container">
  <div class="popup-model-load popup-model" id="shareCustomGroupPopup">
    <div class="popup-header-save">
      <div class="popup-header-text ">Mean Variable</div>
      <div class="popup-close" (click)="closeCustomCalculationSharePopUp()" title="Close"></div>
    </div>
    <div class="popup-body">
      <div class="custom-group-parent-container">
        <div class="group-list-container mcustom">
          <div class="group-header">User List</div>
          <div class="share-custom-popup-inner-container">
            <div class="share-custom-popup-user-container">
              <div class="share-input-container">
                <input calss="share-email-input" type="text" (input)="showMatchingEmails($event)"
                  placeholder="Enter Email">

              </div>
              <div class="email-dropdown" *ngIf="isUserListToShareDropdownOpen">
                <div *ngIf="filteredEmailsToShare.length > 0">
                  <div *ngFor="let email of filteredEmailsToShare" class="email-list-container">
                    <div class="checkbox checkbox-icon" [ngClass]="{'checkbox-icon-active':isSharedAlready(email) }"
                      (click)="toggleSelectedEmail(email)">

                    </div>
                    <!-- <input type="checkbox" [value]="email" [checked]="isSharedAlready(email)" (change)="toggleSelectedEmail(email)">
                    <label for="myCheckbox" class="custom-checkbox" [class.checked-image]="isSharedAlready(email)"></label> -->
                    <div class="email-list-content" title="{{email}}">{{ email }}</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="summary-container">
          <div class="group-header">Custom Email List</div>
          <div class="share-custom-popup-right-container">
            <div *ngFor="let userEmail of selectedEmailsToShare" class="email-list-content" title="{{userEmail}}">
              {{userEmail}}</div>
          </div>
        </div>
      </div>
      <div class="custom-group-button-container popup-footer-save">


        <div class="popup-btn" (click)="onShareCustomCalculation()">
          <div class="btn-icon ShareImage"></div>
          <div class="btn-text-save">Share</div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="popup-container-share" *ngIf="isShareSelectionClick">
  <div class="popup-model-load popup-model" id="shareSelectionPopup">
    <div class="popup-header-save">
      <div class="popup-header-text ">Share Selection</div>
      <div class="popup-close" (click)="closeShareSelectionPopUp()" title="Close"></div>
    </div>
    <div class="popup-body">
      <div class="custom-group-parent-container">
        <div class="group-list-container mcustom">
          <div class="group-header">User List</div>
          <div class="share-custom-popup-inner-container">
            <div class="share-custom-popup-user-container">
              <div class="share-input-container">
                <input type="text" (input)="showMatchingEmails($event)" placeholder="Enter Email">

              </div>
              <div class="email-dropdown" *ngIf="isUserListToShareDropdownOpen">
                <div *ngIf="filteredEmailsToShare.length > 0">
                  <div *ngFor="let email of filteredEmailsToShare" class="email-list-container">
                    <div class="checkbox checkbox-icon" [ngClass]="{'checkbox-icon-active':isSharedAlready(email) }"
                      (click)="toggleSelectedEmail(email)">

                    </div>
                    <!-- <input type="checkbox" [value]="email" [checked]="isSharedAlready(email)" (change)="toggleSelectedEmail(email)">
                    <label for="myCheckbox" class="custom-checkbox" [class.checked-image]="isSharedAlready(email)"></label> -->
                    <div class="email-list-content" title="{{email}}">{{ email }}</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="summary-container">
          <div class="group-header">Custom Email List</div>
          <div class="share-custom-popup-right-container">
            <div *ngFor="let userEmail of selectedEmailsToShare" class="email-list-content" title="{{userEmail}}">
              {{userEmail}}</div>
          </div>
        </div>
      </div>
      <div class="custom-group-button-container popup-footer-save">


        <div class="popup-btn" (click)="onShareSelectionClick()" [ngClass]="{'disabled': !isSelectionChanged()}">
          <div class="btn-icon ShareImage"></div>
          <div class="btn-text-save">Share</div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="popup-container-save " *ngIf="isNumeral" id="numeralPopup">
  <div class="popup-model">
    <div class="popup-header-save">
      <div class="popup-header-text">Numeral value</div>
      <div class="popup-close" (click)="closeSavePopUp()" title="Close"></div>
    </div>
    <div class="popup-body">
      <div class="message-block">
        Please enter the numeral value
      </div>
      <div class="input-box">
        <input type="number" placeholder="Please enter numeral value.." [(ngModel)]="numeralValue" maxlength="50"
          autocomplete="off" name="numeralValue" (keydown)="onKeyDown($event)" />
      </div>
    </div>
    <div class="popup-footer-save">
      <div class="popup-btn" (click)="onNumeralSubmit()">
        <div class="btn-icon check-circle-icon"></div>
        <div class="btn-text-save">OK</div>
      </div>
      <div class="popup-btn" (click)="closeSavePopUp()">
        <div class="btn-icon check-circle-icon"></div>
        <div class="btn-text-save">Cancel</div>
      </div>
    </div>
  </div>
</div>