import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { HttpClient } from '@angular/common/http';
import { BarServiceService } from './bar-service.service';
import { CrosstabService } from 'src/app/common/services/crosstab.service';
import { COMMON_CONSTANTS } from '../../constants/common-constants';
import { forEach } from 'lodash';
import Stock from 'highcharts/modules/stock';

Stock(Highcharts);


@Component({
  selector: 'app-vertical-group-bar-chart',
  templateUrl: './vertical-cluster-bar.component.html',
  styleUrls: ['./vertical-cluster-bar.component.scss']
})
export class Barchart18Component implements OnInit {
  LowBaseSample = COMMON_CONSTANTS.LowBase_Sample;
  InsufficientBaseSample = COMMON_CONSTANTS.InsufficientBase_Sample;
  @Input() insufficientBase: string = '';
  @Input() LowBase: string = '';
  DefaultConfidenceLevel: string = '95%';
  Sig_LowerLimt = COMMON_CONSTANTS.SigLowerLimit_95;
  Sig_UpperLimt = COMMON_CONSTANTS.SigUpperLimit_95;
  chart: any;
  event: any;
  datalabel: any = false;
  Highcharts: typeof Highcharts = Highcharts;
  seriesData: any = [];
  changeValue: any;
  properties: any;
  changeValueSeries: any;
  significance: any;
  changeValueSeriesUSA: any;
  significanceUSA: any;
  data: any;
  barChartData: any;
  jsonData: any;
  sampleSize: any;
  min: any;
  max: any;
  significance2: any;
  chartproperties = [
    {
      "showLegend": true,
      "gridlines": false,
      "shadow": false,
      "darkTheme": false,
      "legendPosition": "bottom",
      "axisLabelOrientation": "horizontal",
      "chartTitlePosition": "center",
      "showDataLabel": true,
      "datalabelStyle": "significance + changeVs",
      "axisImage": false,
      "dataLabelPosition": "center",
      "dataLabelColor": "black",
      "marker": "disable",
      "showRemaining": false,
      "gradientBarColor": false,
      "dataLabelPositionPPT": "Center",
      "datalabelFormat": "percentage",
      "markerStyle": "circle",
      "showPattern": false,
      "toolTipStyle": "default"
    }
  ]
  constructor(private http: HttpClient, private barService: BarServiceService, private dataSharingService: CrosstabService) { }
  ngOnInit(): void {
    this.LowBaseSample = this.LowBase == "" ? 0 : Number(this.LowBase);
    this.InsufficientBaseSample = this.insufficientBase == "" ? 0 : Number(this.insufficientBase);

    // this.http.get<any>('../../../../../../assets/Data/barData.json').subscribe((data: any) => {
    //this.http.get<any>('assets/Data/barData_Group.json').subscribe((data: any) => {
    //  this.jsonData = data;
    //  this.data = data[0];
    //  this.barChartData = data[0]["data"];
    //  this.properties = data[0]["properties"][0];

    //  this.getBarChart('chart-container', this.seriesData, this.properties);
    //});

    this.dataSharingService.GrpChartDataSubject$.subscribe(({ data, listOfCategory, Significance, ConfidenceLevel, numberType, chartType }) => {
      this.properties = this.chartproperties[0];
      if (Significance == "None") {
        this.properties.datalabelStyle = '';
      }
      else {
        this.properties.datalabelStyle = 'change';
        this.DefaultConfidenceLevel = ConfidenceLevel;
      }
      if (numberType == "mean") {
        this.properties.datalabelFormat = '';
      }


      this.jsonData = data;
      this.data = data;//data[0];
      this.barChartData = data.Data;
      this.properties = this.chartproperties[0];

      this.getBarChart('chart-container', this.seriesData, this.properties, chartType, listOfCategory);
    });

  }
  customization() {
    return this.barService.customizationService2();
  }
  accessSeriesValues(seriesData: any, lowSample1: any, listOfCategory: any, chartType: any) {
    const seriesNames: string[] = Array.from(new Set(this.jsonData.Data.map((item: any) => item['Series Name'])));
    let IB = this.InsufficientBaseSample;
    let i = 0;

    const highchartsSeriesData: any[] = [];


    for (const seriesName of seriesNames) {

      /* const data = this.barChartData.filter((a: any) => a["Category Name"] == catName);*/
      const currentSeriesData: any = {
        name: seriesName,
        data: [],
        label: [],
        changeValue: [],
        Significance: [],
        samplesize: [],
        /*  pointPadding: 0.1,*/
        groupPadding: 0.2,
        zIndex: 2,
        visible: true,

        // pointWidth: this.getValueforBarsize(chartType,seriesNames),

        color: this.barService.color1[i],

      };

      if (this.properties.pattern) {
        currentSeriesData.color = `url(#pattern${i})`;
      }
      for (const catName of listOfCategory) {
        const data = this.barChartData.filter((a: any) => a["Category Name"] == catName && a["Series Name"] == seriesName);

        const uniqueKeys = ["Category Name", "Series Name"];
        const uniqueData = this.getUnique(data, uniqueKeys);
        //for (const series of data) {
        for (const series of uniqueData) {
          if (series["Series Name"] === seriesName) {

            const value = parseFloat(series['Rounded Value']) === 0 ? 0 : (series["Sample size"] != 0 && parseFloat(series["Sample size"]) < IB) ? 0.000001 : parseFloat(series['Rounded Value']) || 0.000001;
            const numericValue = value;
            currentSeriesData.data.push(numericValue);
            currentSeriesData.label.push(parseFloat(series.Label));
            currentSeriesData.changeValue.push(parseFloat(series["Change value vs PY"]));
            currentSeriesData.Significance.push(parseFloat(series["Significance"]));
            currentSeriesData.samplesize.push(parseFloat(series["Sample size"]));
          }
        }
        // }
      }
      highchartsSeriesData.push(currentSeriesData);

      i++; // Increment i for the next iteration

    }

    const allData = highchartsSeriesData.flatMap(series => series.data);
    const maxValue = Math.max(...allData);
    const minValue = Math.min(...allData);

    this.max = maxValue + Math.ceil(maxValue * 0.2);
    this.min = 0;// minValue + Math.ceil(minValue * 0.2);
    this.seriesData = highchartsSeriesData;
  }

  //accessSeriesValues(seriesData: any, lowSample1: any) {
  //  const firstItem = this.jsonData[0].data[0]['Series Name'];


  //  // Find another item that is not equal to the first one based on a condition
  //  let anotherItem = this.jsonData[0].data.find((item: { [x: string]: any; }) => item['Series Name'] !== firstItem);

  //  // Extract the "Series Name" property from the found item
  //  let secondItem = anotherItem ? anotherItem['Series Name'] : 'No match found';
  //  let i = 0;
  //  const highchartsSeriesData: any = {
  //    name: firstItem,
  //    data: [],
  //    label: [],

  //    changeValue: [],
  //    pointPadding: 0.1, // Adjust this value to increase distance between bars within a cluster
  //    groupPadding: 0.2, // Keep this value to maintain distance between clusters
  //    zIndex: 2,
  //    visible: true,
  //    pointWidth: 15,
  //    color: this.properties.gradientBarColor ? this.barService.linearGradients[i++] : this.barService.color1[i++],
  //    // color:'red',
  //  };

  //  const highchartsSeriesDataUSA: any = {
  //    name: secondItem,
  //    data: [],
  //    label: [],
  //    pointPadding: 0.1, // Adjust this value to increase distance between bars within a cluster
  //    groupPadding: 0.2, // Keep this value to maintain distance between clusters
  //    changeValue: [],
  //    zIndex: 2,
  //    visible: true,
  //    pointWidth: 15,
  //    color: this.properties.gradientBarColor ? this.barService.linearGradients[i++] : this.barService.color1[i++],
  //    // color: 'yellow',
  //  };

  //  this.changeValueSeries = {
  //    name: firstItem,
  //    data: []
  //  };
  //  this.changeValueSeriesUSA = {
  //    name: secondItem,
  //    data: [],
  //  };

  //  this.significance = {
  //    name: firstItem,
  //    data: []
  //  };
  //  this.significance2 = {

  //    data: []
  //  };
  //  this.significanceUSA = {
  //    name: secondItem,
  //    data: [],
  //  };
  //  this.sampleSize = {
  //    data: [],
  //  }
  //  let valuesAddedUSA = 0;
  //  let valuesAddedIndia = 0;
  //  if (this.properties.pattern) {
  //    highchartsSeriesData.color = 'url(#pattern3)';
  //    highchartsSeriesDataUSA.color = 'url(#pattern2)';
  //  }
  //  for (const series of this.barChartData) {
  //    const value = parseFloat(series['Rounded Value']) == 0 || parseFloat(series['Rounded Value']) == null ? 0 : parseFloat(series['Rounded Value']);
  //    const numericValue = !lowSample1 ? value : (parseFloat(series['Sample size']) > 9000 ? value : 0);
  //    const label = parseFloat(series.Label);
  //    const change = parseFloat(series["Change value vs PY"]);
  //    const sample = parseFloat(series["Sample size"]);
  //    const significance1 = parseFloat(series["Significance"]);
  //    const significance2 = parseFloat(series["Significance2"]);
  //    if (series["Series Name"] === firstItem && valuesAddedIndia < 5) {

  //      highchartsSeriesData.data.push(numericValue);
  //      highchartsSeriesData.label.push(label);
  //      highchartsSeriesData.changeValue.push(change);

  //      valuesAddedIndia++;
  //    }
  //    else if (series["Series Name"] === secondItem && valuesAddedUSA < 5) {
  //      highchartsSeriesDataUSA.data.push(numericValue);
  //      highchartsSeriesDataUSA.label.push(label);
  //      highchartsSeriesDataUSA.changeValue.push(change);

  //      valuesAddedUSA++;

  //    }
  //    this.sampleSize.data.push(sample);
  //    this.significance2.data.push(significance2)
  //    this.significance.data.push(significance1);
  //    this.changeValueSeries.data.push(change);
  //  }
  //  let allData = [...highchartsSeriesData.data, ...highchartsSeriesDataUSA.data];

  //  let maxValue = Math.max(...allData)
  //  let minValue = Math.min(...allData);
  //  this.max = maxValue + Math.ceil(maxValue * 0.2);
  //  this.min = minValue + Math.ceil(minValue * 0.2);
  //  this.seriesData = [highchartsSeriesData, highchartsSeriesDataUSA];
  //}
  // set the bar width based on series count for horizontal group chart
  getValueforBarsize(chartType: any, seriesNames: any) {
    if (chartType == "bar") {
      if (seriesNames.length > 6) {
        return 8;
      }
      else {
        return 15;
      }
    }
    return 15;
  }
  patternselect(ispattern: boolean) {
    this.properties.pattern = ispattern
    this.accessSeriesValues(this.jsonData, this.properties.datalabelStyle == "ls", "", "");
    this.getBarChart('chart-container', this.seriesData, this.properties, "", "");
    ;
  }
  commonfunc(event: any, customization: string, options: any) {
    this.event = event;
    this.properties = this.barService.commonFunc(customization, event, this.properties)
    //this.accessSeriesValues(this.properties.lowSample);
    this.getBarChart('chart-container', this.seriesData, this.properties, "", "");
  }
  getBarChart(containerid: string, seriesData: any, properties: any, chartType: string, listOfCategory: any) {
    localStorage.setItem("properties", JSON.stringify(this.properties));
    this.accessSeriesValues(this.seriesData, this.properties.datalabelStyle == "ls", listOfCategory, chartType);
    let serviceBar = this.barService;
    const plot = this.barService.plot2;
    //let change = this.changeValueSeries.data;
    //let signi = this.significance.data;
    //let signi2 = this.significance2.data;
    let c = 0;
    // let samplesize = this.sampleSize.data;
    const updatedCategories = this.barService.updatedCategories;
    const plotDark = this.barService.plot2Dark;
    const defaultCategories = listOfCategory;
    // scroll enable and maximum category show in screen based on condition
    //const maxCatrgories = chartType == "bar" ? this.seriesData.length > 6 ? 1 : listOfCategory.length - 1 : listOfCategory.length > 5 ? 4 : listOfCategory.length - 1;
    //const isScrollEnabled = chartType == "bar" ? this.seriesData.length > 6 ? true : listOfCategory.length > 5 ? true : false : listOfCategory.length > 5 ? true : false;

    const maxCatrgories = chartType == "bar" ? (this.seriesData.length >= 6 && listOfCategory.length >= 3) ? 1 : (this.seriesData.length >= 3 && listOfCategory.length >= 3) ? 1 : (listOfCategory.length > 5 ? 4 : listOfCategory.length - 1) : (listOfCategory.length > 5 ? 4 : listOfCategory.length - 1);
    const isScrollEnabled = chartType == "bar" ? (this.seriesData.length >= 6 && listOfCategory.length >= 3) ? true : (this.seriesData.length >= 3 && listOfCategory.length >= 3) ? true : (listOfCategory.length > 5 ? true : false) : (listOfCategory.length > 5 ? true : false);

    let InsufficientBase = this.InsufficientBaseSample;
    let lowSample = this.LowBaseSample;
    this.chart = Highcharts.chart(containerid, {
      chart: {
        type: chartType,
        backgroundColor: this.properties.darkTheme ? this.barService.darkThemeBackgroundColor : this.barService.defaultThemeBackgroundColor,
        style: {
          color: this.properties.darkTheme ? this.barService.darkThemeTextColor : this.barService.defaultThemeTextColor,
        },
      },
      title: {
        text: '',//this.data.title,
        align: this.properties.chartTitlePosition,
        style: {
          color: this.properties.darkTheme ? this.barService.darkThemeTextColor : this.barService.defaultThemeTextColor,
        },
      },
      subtitle: {

        align: 'left',
        style: {
          color: 'grey',
        },
      },
      exporting: {
        enabled: false,
      },
      legend: this.barService.customizeLegend(this.chart, this.properties.showLegend, this.properties.legendPosition, this.properties),
      xAxis: {

        categories: defaultCategories,
        min: 0,
        max: maxCatrgories,
        scrollbar: {
          enabled: isScrollEnabled,
          height: 6, // Adjust the height of the scrollbar as needed
          barBackgroundColor: '#802ab7',
          barBorderRadius: 5,
          barBorderWidth: 0,
          barBorderColor: '#c9c9c9',
          buttonBackgroundColor: '#c9c9c9',
          buttonBorderWidth: 0,
          buttonArrowColor: '#c9c9c9',
          buttonBorderRadius: 0,
          rifleColor: 'none',
          trackBackgroundColor: '#c9c9c9',
          trackBorderWidth: 0,
          trackBorderColor: 'black',
          trackBorderRadius: 5
        },
        plotBands: this.properties.darkTheme ? plotDark : plot,
        accessibility: {
          description: 'Countries'
        },
        labels: {
          //useHTML: true,
          //style: {
          //  color: this.properties.darkTheme ? this.barService.darkThemeTextColor : this.barService.defaultThemeTextColor,
          //},
          //formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
          //  if (properties.axisImage) {
          //    const index = defaultCategories.indexOf(String(this.value));
          //    const category = updatedCategories[index];

          //    if (category && category.image) {
          //      return '<span style="display: inline-block; width: 20px; height: 20px; background: url(' + category.image + ') no-repeat center center; background-size: cover;"></span>';
          //    } else {
          //      return '<span>No Image</span>';
          //    }
          //  } else {
          //    return String(this.value);
          //  }
          //},

          rotation: this.barService.axisLabelOrientation(this.properties.axisLabelOrientation), // Rotate labels by -90 degrees (or any other desired angle)
        }
      },
      yAxis: {
        scrollbar: {
          enabled: true,
          showFull: false, // Set to false if you want to see only part of the scrollbar initially
        },
        visible: this.properties.gridlines,
        max: this.max,
        min: this.min,
        margin: 1,
        tickInterval: 20, // Set the interval between ticks to 10 units
        accessibility: {
          rangeDescription: 'Range: 0 to 100' // Update the accessibility range description
        },
        labels: {
          style: {
            color: this.properties.darkTheme ? this.barService.darkThemeTextColor : this.barService.defaultThemeTextColor,
          },
        },
        title: {
          text: '', // Set an empty string to hide the yAxis title
        },
      },
      tooltip: {
        enabled: true,
        borderWidth: 0,
        useHTML: true,
        shape: properties.toolTipStyle == "style1" ? 'rect' : 'callout',
        shadow: false,
        backgroundColor: 'rgba(0,0,0,0)',

        formatter: function (this: any) {
          if (this.series.name === 'Marker1') return false;
          let isDefaultTooltip = properties.toolTipStyle;

          const dotColor = this.series.color; // Get the series color

          const content =
            `<div style="background-color: #f5f5f5; padding: 5px; align-items: center; border: 1px solid ${dotColor}; ">` +
            ` ${this.series.name}` +
            `<div style="display: block; margin-top: 5px;"><strong>Value:</strong>` +
            ` ${this.point.series.userOptions.samplesize[this.point.x] != 0 && this.point.series.userOptions.samplesize[this.point.x] < InsufficientBase ? 'I/S' :
              this.point.y == 0.000001 ? 'NA' :
                (this.point.series.userOptions.samplesize[this.point.x] != 0 && (this.point.series.userOptions.samplesize[this.point.x] < lowSample && this.series.userOptions.samplesize[this.point.x] > InsufficientBase)) ?
                  (this.point.y + (properties.datalabelFormat == "percentage" ? '%' : '') + ('*')) :
                  (this.point.y + (properties.datalabelFormat == "percentage" ? '%' : ''))}` +
            `</div>`;

          return content + '</div>';
        },
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        column: {

          stacking: undefined,
          shadow: this.properties.shadow ? {
            color: this.barService.shadowColor,
            offsetX: 1,
            offsetY: 1,
            opacity: 0.5,
            width: 6,
          } : false,
          //pointWidth: 15,

          dataLabels:

          {
            style: {

              backgroundColor: this.barService.transparentBackground,

              textOutline: 'none',

              color: 'black',

            },
            enabled: true,
            inside: false,
            align: this.properties.datalabelOrientation == 90 && this.properties.dataLabelPosition == 'center' ? 'left' : this.properties.dataLabelPosition,
            padding: 9,
            rotation: this.properties.datalabelOrientation,

            formatter: function (this: any) {
              if (properties.datalabelStyle == "change") {
                this.Sig_LowerLimt =
                  this.DefaultConfidenceLevel == '95%'
                    ? COMMON_CONSTANTS.SigLowerLimit_95
                    : this.DefaultConfidenceLevel == '90%'
                      ? COMMON_CONSTANTS.SigLowerLimit_90
                      : this.DefaultConfidenceLevel == '98%'
                        ? COMMON_CONSTANTS.SigLowerLimit_98
                        : COMMON_CONSTANTS.SigLowerLimit_95;
                this.Sig_UpperLimt =
                  this.DefaultConfidenceLevel == '95%'
                    ? COMMON_CONSTANTS.SigUpperLimit_95
                    : this.DefaultConfidenceLevel == '90%'
                      ? COMMON_CONSTANTS.SigUpperLimit_90
                      : this.DefaultConfidenceLevel == '98%'
                        ? COMMON_CONSTANTS.SigUpperLimit_98
                        : COMMON_CONSTANTS.SigUpperLimit_95;

                let text = this.y == 0.000001 ? 'NA' : (this.y + (properties.datalabelFormat == "percentage" ? '%' : ''));
                let changevspy = isNaN(this.series.userOptions.changeValue[this.point.x]) ? "(NA)" : '(' + this.series.userOptions.changeValue[this.point.x] + (properties.datalabelFormat == "percentage" ? '%' : '') + ')';
                let sig = this.series.userOptions.Significance[this.point.x];

                if (text == 'NA') {
                  return (
                    '<span >' + text + '</span>'
                  );
                }
                if (changevspy == "(NA)") {
                  return (
                    '<span>' + text + '<span>' + changevspy + '</span></span>'
                  );
                }
                else if (sig < this.Sig_LowerLimt) {
                  return (
                    '<span>' + text + '<span  style="color: red;">' + changevspy + '</span></span>'
                  )
                } else if (sig > this.Sig_UpperLimt) {
                  return (
                    '<span>' + text + '<span  style="color: green;">' + changevspy + '</span></span>'
                  )
                }
                else {
                  return (
                    '<span>' + text + '<span>' + changevspy + '</span></span>'
                  );
                }


                //let text = this.y != 0 ? this.y + (properties.datalabelFormat == "percentage" ? '%' : '') + '(' + change[c++] + ')' : 'NA';
                //return (
                //  '<span >' + text + '</span>'
                //);

              }
              //return this.y == 0.000001 ? 'NA' : (this.y + (properties.datalabelFormat == "percentage" ? '%' : ''));


              return ((this.series.userOptions.samplesize[this.point.x] != 0 && this.series.userOptions.samplesize[this.point.x] < InsufficientBase) ? 'I/S' : this.y == 0.000001 ? 'NA' : (this.series.userOptions.samplesize[this.point.x] != 0 && (this.series.userOptions.samplesize[this.point.x] < lowSample && this.series.userOptions.samplesize[this.point.x] > InsufficientBase)) ? (this.y + (properties.datalabelFormat == "percentage" ? '%' : '') + ('*')) : (this.y + (properties.datalabelFormat == "percentage" ? '%' : '')));



            },

          }
        },
        bar: {

          stacking: undefined,
          shadow: this.properties.shadow ? {
            color: this.barService.shadowColor,
            offsetX: 1,
            offsetY: 1,
            opacity: 0.5,
            width: 6,
          } : false,
          //pointWidth: 15,

          dataLabels:

          {
            style: {

              backgroundColor: this.barService.transparentBackground,

              textOutline: 'none',

              color: 'black',

            },
            enabled: true,
            inside: false,
            align: 'left',
            padding: 9,
            rotation: this.properties.datalabelOrientation,

            formatter: function (this: any) {
              if (properties.datalabelStyle == "change") {
                this.Sig_LowerLimt =
                  this.DefaultConfidenceLevel == '95%'
                    ? COMMON_CONSTANTS.SigLowerLimit_95
                    : this.DefaultConfidenceLevel == '90%'
                      ? COMMON_CONSTANTS.SigLowerLimit_90
                      : this.DefaultConfidenceLevel == '98%'
                        ? COMMON_CONSTANTS.SigLowerLimit_98
                        : COMMON_CONSTANTS.SigLowerLimit_95;
                this.Sig_UpperLimt =
                  this.DefaultConfidenceLevel == '95%'
                    ? COMMON_CONSTANTS.SigUpperLimit_95
                    : this.DefaultConfidenceLevel == '90%'
                      ? COMMON_CONSTANTS.SigUpperLimit_90
                      : this.DefaultConfidenceLevel == '98%'
                        ? COMMON_CONSTANTS.SigUpperLimit_98
                        : COMMON_CONSTANTS.SigUpperLimit_95;

                let text = this.y == 0.000001 ? 'NA' : (this.y + (properties.datalabelFormat == "percentage" ? '%' : ''));
                let changevspy = isNaN(this.series.userOptions.changeValue[this.point.x]) ? "(NA)" : '(' + this.series.userOptions.changeValue[this.point.x] + (properties.datalabelFormat == "percentage" ? '%' : '') + ')';
                let sig = this.series.userOptions.Significance[this.point.x];

                if (text == 'NA') {
                  return (
                    '<span >' + text + '</span>'
                  );
                }

                if (changevspy == "(NA)") {
                  return (
                    '<span>' + text + '<span>' + changevspy + '</span></span>'
                  );
                }

                if (sig < this.Sig_LowerLimt) {
                  return (
                    '<span>' + text + '<span  style="color: red;">' + changevspy + '</span></span>'
                  )
                } else if (sig > this.Sig_UpperLimt) {
                  return (
                    '<span>' + text + '<span  style="color: green;">' + changevspy + '</span></span>'
                  )
                }
                else {
                  return (
                    '<span>' + text + '<span>' + changevspy + '</span></span>'
                  );
                }


                //let text = this.y != 0 ? this.y + (properties.datalabelFormat == "percentage" ? '%' : '') + '(' + change[c++] + ')' : 'NA';
                //return (
                //  '<span >' + text + '</span>'
                //);

              }
              return ((this.series.userOptions.samplesize[this.point.x] != 0 && this.series.userOptions.samplesize[this.point.x] < InsufficientBase) ? 'I/S' : this.y == 0.000001 ? 'NA' : (this.series.userOptions.samplesize[this.point.x] != 0 && (this.series.userOptions.samplesize[this.point.x] < lowSample && this.series.userOptions.samplesize[this.point.x] > InsufficientBase)) ? (this.y + (properties.datalabelFormat == "percentage" ? '%' : '') + ('*')) : (this.y + (properties.datalabelFormat == "percentage" ? '%' : '')));

            },

          }
        }
      },
      series: this.seriesData,
    });

  }
  userselectedproperties() {
    this.data["properties"][0] = this.properties;
    return JSON.stringify(this.data).toString();
  }
  getUnique(arr: any[], keys: string[]): any[] {
    const map = new Map<string, any>();
    arr.forEach(item => {
      const compositeKey = keys.map(key => item[key]).join('|');
      if (!map.has(compositeKey)) {
        map.set(compositeKey, item);
      }
    });
    return Array.from(map.values());
  }
}
