import { CalenderModel } from "./calendar.model";

export interface UserManagementDetails {
    usersList: Users[];
    clientStudyList: ClientStudyNames[];
    clientStudyARKList: ClientStudyNames[];
    clientStudyBrandList: ClientStudyNames[];
    moduleList:modules[]; 
    adminAccessList:access[];
    ClientSnapshotNameList:ClientSnapshotNames[];
  }
export interface access {
    module_name: string;
    client_name: string;
    study_name: string;
    snapshot_name:string;
    role_name?: string | null;
    is_active: boolean;
    entrypoints:EntryPointDetails[];
    selectedtype:number
  }
  export interface entryPoints {
    entrypoint_name: string;
    level1_name: string;
    metric_option: string;
    variableCode:string;
  }
  
  export interface Users {
    first_name: string;
    last_name: string;
    email: string;
    user_organization?: string | null;
    is_active: boolean;
    user_created_datetime?: Date | null;
    user_last_login_datetime?: Date | null;
    user_modified_datetime?: Date | null;
    access?: access[] | null;
    moduleName?:string|null;
    clientStudyName?:string|null;
    super_admin:boolean;

  }

  export interface ClientStudyNames {
    client_name: string;
    study_name: string;
    selectedtype:number;
    isSelected:boolean
    client_study_name:string;
    is_disabled:boolean;
    isEntryPointPresent:boolean
  }
  export interface ClientSnapshotNames{
    client_name:string;
    snapshot_name:string;
    isSelected:boolean;
    client_snapshot_name:string;
    is_disabled:boolean;
    study_name:string;
    isEntryPointPresent:boolean
    selectedtype:number;
  }
  export interface modules{
    module_name?:string|null;
    module_id:string;
    is_active:boolean;
    is_selected:boolean;
  }
  export interface ProjectManagement{
    clientStudyList: ClientStudyNames[];
    clientStudyARKList: ClientStudyNames[];
    clientStudyBrandList: ClientStudyNames[];
    moduleList:modules[];
    roleList:Roles[];
    ClientSnapshotNamesList:ClientSnapshotNames[];
  }
  export interface ModuleClientStudy{
    client_name: string;
    study_name: string;
    client_study_name:string;
    module_name:string;
    module_id:string;
    snapshot_name:string;
   // client_snapshot_name:string;
  }
  export interface userDetails{
    first_name: string;
    last_name: string;
    email:string;
    access:access;
    super_admin:boolean;
    is_kantar_user:boolean;
  }
  export interface ActiveFlag{
    name: string;
    is_selected: boolean;
  }
  export interface Roles{
    role_id: string;
    role_name: string;
    is_active: boolean;
    is_selected:boolean;
  }
  export interface ExportUserDataSelection{
    clientStudyNames: ClientStudyNames[];
    monthRange:CalenderModel;
    selectedAllClients:boolean;
  }
  export interface ExportSnapshotUserDataSelection{
    clientSnapshotNames: ClientSnapshotNames[];
    monthRange:CalenderModel;
    selectedAllClients:boolean;
  }
  export interface IUsageLogs {
    module_id: string;
    client_name: string;
    study_name: string;
    user_name: string;
    role_name: string;
    features: string;
    datetime:string;
    selection_summary: string;
    snapshotName:string;
}

export interface EntryPointDetails {
  headerName: string;
  level1: string;
  level2: string;
  metricOption: string;
  responseCode: string;
  variableCode: string;
  e1_variable_code:string;
  e2_variable_code: string;
  e3_variable_code:string;
  study:string;
  client:string;
  sortid:number;
  is_selected:boolean
}
export interface EntryPointNames {
  name: string; 
  isSelected:boolean;
}
export interface EntryPointLevel {
  name: string; 
  isSelected:boolean;
}
export interface EntryPointMetricOption {
  name: string; 
  isSelected:boolean;
}


export enum Feature {
  CrosstabOutput = 'Crosstab Output',
  Significance = 'Significance',
  ConfidenceLevel = 'Confidence Level',
  Index = 'Index',
  SaveQuery = 'Save Query',
  LoadQuery = 'Load Query',
  ExportExcel = 'Export Excel',
  ExportPPT = 'Export PPT',
  DeleteCustomGroup = 'Delete Custom Group',
  DeleteCustomCalcultion = 'Delete Custom Calculation',
  SaveCustomCalculation='Save Custom Calculation',
  ConfigurationClientInsertDetails = 'Inserted Client details',
  ConfigurationClientUpdateDetails = 'Updated Client details',
  ConfigurationStudyInsertDetails = 'Inserted Study details',
  ConfigurationStudyUpdateDetails = 'Updated Study details',
}



  