interface ValidationRule {
  latestSelection: string;
  itemsToBeEnabled: string[];
  itemsToBeDisabled: string[];
}

export const customGroupsvalidationRules: ValidationRule[] = [
  {
    latestSelection: 'METRIC',
    itemsToBeEnabled: [')', 'AND', 'OR'],
    itemsToBeDisabled: ['(', 'NOT', 'METRIC'],
  },
  {
    latestSelection: '(',
    itemsToBeEnabled: ['(', 'NOT', 'METRIC'],
    itemsToBeDisabled: [')', 'AND', 'OR'],
  },
  {
    latestSelection: ')',
    itemsToBeEnabled: [')', 'AND', 'OR'],
    itemsToBeDisabled: ['(', 'NOT', 'METRIC'],
  },
  {
    latestSelection: 'AND',
    itemsToBeEnabled: ['(', 'NOT', 'METRIC'],
    itemsToBeDisabled: [')', 'AND', 'OR'],
  },
  {
    latestSelection: 'OR',
    itemsToBeEnabled: ['(', 'NOT', 'METRIC'],
    itemsToBeDisabled: [')', 'AND', 'OR'],
  },
  {
    latestSelection: 'NOT',
    itemsToBeEnabled: ['(', 'METRIC'],
    itemsToBeDisabled: [')', 'AND', 'OR', 'NOT'],
  },
];

export const customCalculationvalidationRules: ValidationRule[] = [
  {
    latestSelection: 'METRIC',
    itemsToBeEnabled: [')', '+', '-', '*', '/'],
    itemsToBeDisabled: ['(', 'NUMERAL'],
  },
  {
    latestSelection: '(',
    itemsToBeEnabled: ['(', 'NUMERAL', 'METRIC'],
    itemsToBeDisabled: [')', '+', '-', '*', '/'],
  },
  {
    latestSelection: ')',
    itemsToBeEnabled: [')', '+', '-', '*', '/'],
    itemsToBeDisabled: ['(', 'NUMERAL', 'METRIC'],
  },
  {
    latestSelection: '+',
    itemsToBeEnabled: ['(', 'NUMERAL', 'METRIC'],
    itemsToBeDisabled: [')', '-', '*', '/'],
  },
  {
    latestSelection: '-',
    itemsToBeEnabled: ['(', 'NUMERAL', 'METRIC'],
    itemsToBeDisabled: [')', '+', '*', '/'],
  },
  {
    latestSelection: '*',
    itemsToBeEnabled: ['(', 'NUMERAL', 'METRIC'],
    itemsToBeDisabled: [')', '-', '+', '/'],
  },
  {
    latestSelection: '/',
    itemsToBeEnabled: [ 'NUMERAL'],
    itemsToBeDisabled: ['(',')', '+', '*', '-','/','METRIC'],
  },
  {
    latestSelection: 'NUMERAL',
    itemsToBeEnabled: [')', '+', '-', '*', '/'],
    itemsToBeDisabled: ['(', 'METRIC', 'NUMERAL'],
  },
];
