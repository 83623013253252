import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/app/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class SharedPopupService {

  private setPopUpDataSubject = new BehaviorSubject<any>(null);
  setPopUpTabData$ = this.setPopUpDataSubject.asObservable();

  apiBaseUrl = environment.baseApiUrl;
  constructor(private http: HttpClient) { }


  getClientList(): Observable<any> {
    return this.http.get<any>(this.apiBaseUrl + "api/LandingPage/getAllData").pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError(error);
      })
    );
  }

  getAllClientList(): Observable<any> {
    return this.http.get<any>(this.apiBaseUrl + "api/LandingPage/getAllClientData").pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError(error);
      })
    );
  }

  getlandingClientList(moduleName:string): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('moduleName', moduleName);
    return this.http.post<any>(this.apiBaseUrl + "api/LandingPage/getlandingData",formData).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError((error:any) => new Error(error));
      })
    );
    // return this.http.get<any>(this.apiBaseUrl + "api/LandingPage/getlandingData").pipe(
    //   catchError((error: HttpErrorResponse) => {
    //     console.error('Request error:', error);
    //     // You can handle the error here and throw a custom error if needed
    //     return throwError(error);
    //   })
    // );
  }

  //api/UserProjectManagement/getUsers
  // getSnapshotClientList(): Observable<any> {
  //   this.http.get<any>("https://localhost:44376/api/UserProjectManagement/getUsers").subscribe(
  //     (data) => {
  //     },
  //     (error) => {
  //     }
  //   );
  //   return this.http.get<any>("https://localhost:44376/api/UserProjectManagement/getUsers").pipe(
  //     catchError((error: HttpErrorResponse) => {
  //       console.error('Request error:', error);
  //       // You can handle the error here and throw a custom error if needed
  //       return throwError(error);
  //     })
  //   );
  //   // return this.http.get<any>(this.apiBaseUrl + "api/LandingPage/getAllData").pipe(
  //   //   catchError((error: HttpErrorResponse) => {
  //   //     console.error('Request error:', error);
  //   //     // You can handle the error here and throw a custom error if needed
  //   //     return throwError(error);
  //   //   })
  //   // );
  // }


  //private popupVisible = new BehaviorSubject<boolean>(false);
  //popupVisible$ = this.popupVisible.asObservable();
  //showPopup(clientData: any) {
  //  this.popupVisible.next(true);
  //}

  setPopUpData(data: any,moduleName:string) {
    const newData = { data:data,moduleName: moduleName};
    this.setPopUpDataSubject.next(newData);
  }


  //hidePopup() {
  //  this.popupVisible.next(false);
  //}

  //api/UserProjectManagement/getUsers
  // getSnapshotClientList(): Observable<any> {
  //   // this.http.get<any>("https://localhost:44376/api/UserProjectManagement/getUsers").subscribe(
  //   //   (data) => {
  //   //   },
  //   //   (error) => {
  //   //   }
  //   // );
  //   // return this.http.get<any>(this.apiBaseUrl + "Snapshot/getSnapshotConfiguration/getSnapshotClientdetails").pipe(
  //   //   catchError((error: HttpErrorResponse) => {
  //   //     console.error('Request error:', error);
  //   //     // You can handle the error here and throw a custom error if needed
  //   //     return throwError(error);
  //   //   })
  //   // );
  //   return this.http.get<any>(this.apiBaseUrl + "api/LandingPage/getAllData").pipe(
  //     catchError((error: HttpErrorResponse) => {
  //       console.error('Request error:', error);
  //       // You can handle the error here and throw a custom error if needed
  //       return throwError(error);
  //     })
  //   );
  // }

  getSnapshotlandingClientList(): Observable<any> {
    return this.http.get<any>(this.apiBaseUrl + "api/LandingPage/getSnapshotlandingData").pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Request error:', error);
        // You can handle the error here and throw a custom error if needed
        return throwError(error);
      })
    );
  }


}
