import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LineOrTrendedService {

  constructor() { }
  properties: any;

  updatedCategories = [
    { name: "Oct'2023", image: 'https://th.bing.com/th/id/OIP.PJgT1Dp3KX4BbKBrfvGoFAAAAA?rs=1&pid=ImgDetMain' },
    { name: "Nov'2023", image: 'https://d2gg9evh47fn9z.cloudfront.net/800px_COLOURBOX7783047.jpg' },
    { name: "Dec'2023", image: 'https://th.bing.com/th/id/OIP.gxQmRFM-PSHbekk72mukxwHaH_?rs=1&pid=ImgDetMain' },
    { name: "Jan'2024", image: 'https://th.bing.com/th/id/R.4abe7f8a1272b46cb5d1e67d4341b6ec?rik=lW0lyg9V5L%2b7UQ&riu=http%3a%2f%2fwww.mapsof.net%2fuploads%2fstatic-maps%2feuropecontour.png&ehk=aDTomnr7%2bgplizpCBegO2ztJrXsGolh7sWqvGkqOQAI%3d&risl=&pid=ImgRaw&r=0' },
    { name: "Feb'2024", image: 'https://media.istockphoto.com/vectors/black-silhouette-country-borders-map-of-india-on-white-background-of-vector-id955286158?k=6&m=955286158&s=170667a&w=0&h=zeBvYe1ttcA8HTslBhAfLRfzS0q2D3o7LQ9Jtp8ift4=' },
  ];
  commonFunc(customization: any, event: any, property: any) {
    this.properties = property;
    switch (customization) {
      case 'Gridline':
        this.properties.gridlines = event.target.checked ? 1 : 0;
        break;
      case 'Stacked Chart : ':
        this.properties.stacking = event.target.value;
        break;
      case 'Dark':
        this.properties.darkTheme = event.target.checked;
        break;
      case 'Legend : ':
        this.properties.showLegend = event.target.value == "enabled" ? true : false;
        break;
      case 'Legend Position : ':
        this.properties.legendPosition = event.target.value
        break;

      case 'Data Label Format : ':
        this.properties.datalabelFormat = event.target.value;
        break;
      case 'Axis Label Orientation : ': this.properties.axisLabelOrientation = event.target.value;
        break;
      case 'Chart Title Position : ': this.properties.chartTitlePosition = event.target.value;
        break;


      case 'DataLabel : ': this.properties.showDataLabel = event.target.value == "enabled" ? true : false;
        break;
      case 'Data Label Property : ': this.properties.datalabelStyle = event.target.value;
        break;
      case 'Data Label Position : ': this.properties.dataLabelPositionPPT = event.target.value;
        break;
      case 'Data Label Orientation : ':
        this.properties.datalabelOrientation = event.target.value;
        break;
      case 'Data label color': this.properties.dataLabelColor = event.target.checked;
        break;
      case 'Axis Image': this.properties.axisImage = event.target.checked;
        break;

      case 'Chart Style : ': this.properties.chartStyle = event.target.value;
        break;
      case "Line Style : ":
        this.properties.gridLineDashStyle = event.target.value;
        break;
      case 'Marker Style : ':
        this.properties.markerStyle = event.target.value;
        break;
      default:

        break;
    }

    return this.properties
  }

  axisLabelOrientation(axisLabelOrientation: any) {
    let rotationValue = 0;
    switch (axisLabelOrientation) {
      case 'right':
        rotationValue = -90; // or 90 depending on your preference
        break;
      case 'left':
        rotationValue = 90; // or -90 depending on your preference
        break;
      case 'center':
        rotationValue = 0;
        break;
      case 'diagonalR':
        rotationValue = 60;
        break;
      case 'diagonalL':
        rotationValue = 340;
        break;
      default:
        rotationValue = 0;
        break;
    }
    return rotationValue
  }

  customizationService() {
    let custom: any = [
      { customization: 'Dark', func: 'commonfunc', inputType: 'checkbox' },
      { customization: 'Gridline', func: 'commonfunc', inputType: 'checkbox' },
      { customization: 'Axis Image', func: 'commonfunc', inputType: 'checkbox' },
      {
        customization: 'Axis Label Orientation : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'default', label: 'Position' },
          { value: 'right', label: 'Vertical Right' },
          { value: 'left', label: 'Vertical Left' },
          { value: 'diagonalR', label: 'Diagonal Right' },
          { value: 'diagonalL', label: 'Diagonal Left' },
          { value: 'center', label: 'Horizontal' }
        ]
      },
      {
        customization: 'Stacked Chart : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 0, label: 'Without Stack' },
          { value: 1, label: 'Stacking' },
          { value: 2, label: '100% Stacking' }]
      },
      {
        customization: 'Legend : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'enabled', label: 'Enable' },
          { value: 'disabled', label: 'Disable' },

        ],
      },
      {
        customization: 'Legend Position : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'default', label: 'Position' },
          { value: 'bottom', label: 'Bottom' },
          { value: 'left', label: 'Left' },
          { value: 'right', label: 'Right' },  // Add this option for 'Right'
        ]
      },
      {
        customization: 'DataLabel : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'enabled', label: 'Enable' },
          { value: 'disabled', label: 'Disable' },
        ]
      },
      {
        customization: 'Data Label Format : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'number', label: 'number' },
          { value: 'percentage', label: 'percentage' }
        ]
      },
      {
        customization: 'Data Label Property : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: '', label: 'Select' },
          { value: 'change', label: 'With Change Value' },
          { value: 'significance', label: 'With Significance' },
          { value: 'sigchan', label: 'With Significance and Change Value' },
          { value: 'lowSample', label: 'Low Sample' }
        ]
      },

      {
        customization: 'Data Label Position : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'default', label: 'Position' },
          { value: 'right', label: 'Left' },
          { value: 'left', label: 'Right' },
          { value: 'center', label: 'Center' },
        ]
      },
      {
        customization: 'Data Label Orientation : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'default', label: 'Position' },
          { value: 90, label: 'Vertical Right' },
          { value: -90, label: 'Vertical Left' },
          { value: 0, label: 'Horizontal' }
        ]
      },
      {
        customization: 'Line Style : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'Dash', label: 'Dash' },
          { value: 'DashDot', label: 'DashDot' },
          { value: 'Dot', label: 'Dot' },
          { value: 'LongDash', label: 'LongDash' },
          { value: 'LongDashDot', label: 'LongDashDot' },
          { value: 'LongDashDotDot', label: 'LongDashDotDot' },
          { value: 'Solid', label: 'Solid' }
        ]
      },
      {
        customization: 'Marker Style : ', func: 'commonfunc', inputType: 'dropdown', options: [
          { value: 'circle', label: 'Circle' },
          { value: 'diamond', label: 'Diamond' },
          { value: 'square', label: 'Square' },
          { value: 'triangle', label: 'Triangle' },
        ]
      },
    ];
    return custom;
  }


  customizeLegend(showLegend: any, legendPosition: any, theme: any, stacking: any): Highcharts.LegendOptions {
    let alignLegend: Highcharts.VerticalAlignValue = 'bottom'; // Default value
    let alignx: Highcharts.AlignValue | undefined = 'left';
    let layout1: Highcharts.OptionsLayoutValue | undefined = 'horizontal';
    if (showLegend) {
      switch (legendPosition) {
        case "top":
          alignLegend = 'top';
          alignx = 'center';
          layout1 = 'horizontal';
          break;
        case "bottom":
          alignLegend = 'bottom';
          alignx = 'center';
          layout1 = 'horizontal';
          break;
        case "right":
          alignLegend = 'middle';
          alignx = 'right';
          layout1 = 'vertical';
          break;
        case "left":
          alignLegend = 'middle';
          alignx = 'left';
          layout1 = 'vertical';
          break;
        default:
          // No need to change anything for the default case
          break;
      }
    }

    return {
      enabled: showLegend,
      reversed: (stacking == 1 || stacking == 2) ? true : false,
      align: alignx,
      verticalAlign: alignLegend,
      layout: layout1,
      itemStyle: {
        color: theme ? "white" : "Black"
      },
      itemHoverStyle: {
        color: "#FF0000"
      }
    };
  }
}
