import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, throwError } from "rxjs";
import { environment } from "src/app/environments/environment";

@Injectable({
    providedIn: 'root',
})
export class CustomVariableService {

    apiBaseUrl = environment.baseApiUrl;

    constructor(private http: HttpClient) { }

    retriveUserDefinedCustomGroupSelectionData(
        client_name: string,
        study: string,
        is_active: boolean,
        module_id: number
    ): Observable<any> {
        const url =
            this.apiBaseUrl + 'api/CustomVariables/retriveUserDefinedCustomGroupData';

        // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
        const body = {
            client_name: client_name,
            study: study,
            is_active: is_active,
            module_id: module_id,
        };

        return this.http.post<any>(url, body).pipe(
            catchError((error: HttpErrorResponse) => {
                console.error('Request error:', error);
                return throwError(error);
            })
        );
    }

    retriveSharedWithMeCustomGroupData(
        client_name: string,
        study: string,
        is_active: boolean,
        module_id: number
    ): Observable<any> {
        const url =
            this.apiBaseUrl + 'api/CustomVariables/retriveSharedWithMeCustomGroupData';

        // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
        const body = {
            client_name: client_name,
            study: study,
            is_active: is_active,
            module_id: module_id,
        };

        return this.http.post<any>(url, body).pipe(
            catchError((error: HttpErrorResponse) => {
                console.error('Request error:', error);
                return throwError(error);
            })
        );
    }

    deleteCustomGroupSelectionData(
        client_name: string,
        study: string,
        selectedCustomGroupName: string,
        customGroupBucket: string,
        sharedWith: string,
        module_id: number
      ): Observable<any> {
        const url =
          this.apiBaseUrl + 'api/CustomVariables/deleteSelectedCustomGroup';
    
        // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
        const body = {
          client_name: client_name,
          study: study,
          selection_name: selectedCustomGroupName,
          customGroupBucket: customGroupBucket,
          shareWith: sharedWith,
          module_id: module_id,
        };
    
        return this.http.post<any>(url, body).pipe(
          catchError((error: HttpErrorResponse) => {
            console.error('Request error:', error);
            return throwError(error);
          })
        );
      }

      saveCustomGroupSelectionData(
        module_id: number,
        selection_name: string,
        client_name: string,
        study: string,
        selection_query: string,
        selection_object: string,
        modified_by: string,
        shared_with: string,
        is_active: boolean,
        is_shared: boolean,
        sortid: number,
        isUpdate: boolean, //true to update , false to save
        isDuplicate: boolean
      ): Observable<any> {
        const url = this.apiBaseUrl + 'api/CustomVariables/saveCustomGroupSelection';
    
        // You can append the additional parameter to the URL or include it in the request body, depending on your API requirements
        const body = {
          module_id: module_id,
          selection_name: selection_name,
          client_name: client_name,
          study: study,
          selection_query: selection_query,
          selection_object: selection_object,
          modified_by: modified_by,
          shared_with: shared_with,
          is_active: is_active,
          is_shared: is_shared,
          sortid: sortid,
          isUpdate: isUpdate,
          isDuplicate: isDuplicate,
          id: '',
        };
    
        return this.http.post<any>(url, body).pipe(
          catchError((error: HttpErrorResponse) => {
            console.error('Request error:', error);
            return throwError(error);
          })
        );
      }
} 